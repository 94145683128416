import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Michigan Medicine Faces Another Cyberattack: A Wake-Up Call for Healthcare Security',
  description: 'Michigan Medicine suffers its second cyberattack in four months, exposing patient data. Learn how PathEye’s real-time encryption and insider threat solutions can prevent healthcare data breaches.',
  keywords: 'Michigan Medicine data breach, healthcare cyberattacks, real-time data encryption for businesses, custom secure storage for sensitive business data, employee cyber awareness training, insider threats in healthcare, PathEye cybersecurity solutions',
  icon: faStethoscope,
  date: '2024-09-29',
  author: 'PathEye Security',
};

const PostMichiganMedicineCyberattack = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faStethoscope} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Michigan Medicine Faces Another Cyberattack: A Wake-Up Call for Healthcare Security</h2>
      <p>
        Michigan Medicine, the healthcare arm of the University of Michigan, recently experienced a significant data breach that compromised the personal health information of nearly 58,000 patients. This marks the second breach within four months, raising urgent questions about cybersecurity in healthcare.
      </p>
      <p>
        The latest incident occurred when a Michigan Medicine employee mistakenly accepted an unsolicited multifactor authentication (MFA) prompt, allowing cybercriminals to access the employee’s email account. The breach exposed medical records, treatment details, and diagnostic information. While Social Security numbers and financial data were not included in the breach, the exposure of sensitive medical data underscores the critical need for robust cybersecurity in healthcare settings.
      </p>

      <p>
        <strong>In response, Michigan Medicine disabled the compromised account, launched an internal investigation, and implemented new safeguards to mitigate future risks, including reducing the time emails are retained and strengthening MFA protocols.</strong>
      </p>

      <h2>Previous Breach and Growing Concerns</h2>
      <p>
        This is not the first time Michigan Medicine has faced a cyberattack. In May 2024, another incident compromised the information of more than 56,000 individuals. In that attack, hackers accessed employee email accounts containing patient names, medical record numbers, and billing details. Although no evidence indicated that the breach targeted patient data, the information was deemed compromised due to unauthorized access.
      </p>
      <p>
        The latest attack follows a broader trend of rising cyber threats in the healthcare sector. In August 2024, McLaren Health Care and Ascension Health also faced disruptive cyberattacks, further emphasizing that hospitals and medical institutions are frequent targets for cybercriminals.
      </p>

      <h2>A Broader Industry Issue: Insider Breaches and Employee Errors</h2>
      <p>
        While external threats are always a concern, insider breaches caused by human error—such as employees unintentionally granting access to attackers—remain a significant risk. In both Michigan Medicine breaches, unauthorized access was granted through employee email accounts, reinforcing the need for comprehensive staff training and robust internal security measures.
      </p>
      <p>
        This issue isn’t unique to Michigan Medicine. Across industries, major companies have suffered from similar insider breaches. Disney and EA are two prominent examples, where social engineering and phishing attacks led to unauthorized access to internal systems. These cases highlight the growing vulnerability in managing employee access and communication.
      </p>

      <h2>How PathEye Can Help Prevent Future Breaches</h2>
      <p>
        These breaches demonstrate that even with safeguards like multifactor authentication, insider threats and employee errors continue to pose a significant risk. PathEye offers comprehensive solutions designed to protect sensitive healthcare information from both external attackers and internal mistakes.
      </p>
      <ul>
        <li><strong>Employee Awareness Training:</strong> Educating employees on social engineering tactics and MFA vulnerabilities, ensuring they stay alert to potential threats.</li>
        <li><strong><Link to="/dlp">Real-Time Data Encryption for Businesses</Link>:</strong> Securing emails and files with real-time encryption, rendering data unusable even if accessed by unauthorized individuals.</li>
        <li><strong><Link to="/cloud-security">Custom Secure Storage for Sensitive Business Data</Link>:</strong> Implementing secure storage solutions that reduce reliance on external platforms.</li>
        <li><strong>Segmentation of Access:</strong> Limiting employee access to only the information necessary for their roles, ensuring tighter control and monitoring of sensitive data.</li>
      </ul>

      <h2>Insider Threats and External Attacks: A Growing Concern</h2>
      <p>
        Michigan Medicine’s breaches reflect a broader issue within healthcare cybersecurity. As digital records become integral to medical operations, breaches don’t just affect data—they can compromise patient care and safety. The healthcare industry, more than many others, must be prepared for the dual threat of insider risks and external cyberattacks.
      </p>
      <p>
        Organizations like Disney, EA, and others have shown that the risks extend beyond healthcare. With sensitive data at risk, these incidents underscore the need for comprehensive security protocols, including real-time encryption and employee training, to prevent future breaches.
      </p>

      <h2>A Call to Action for Healthcare Security</h2>
      <p>
        As healthcare providers rely more heavily on digital systems for patient care, the risk of cyberattacks grows. The breaches at Michigan Medicine serve as a powerful reminder of the importance of safeguarding patient data and healthcare operations. With lives at stake, the need for robust, proactive cybersecurity measures is more urgent than ever.
      </p>
      <p>
        PathEye offers the security solutions healthcare institutions need to protect patient information from both insider threats and external attacks. <strong>Contact us today to learn more about how we can help protect your organization from cyber threats.</strong>
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostMichiganMedicineCyberattack;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Corporate Espionage in the Digital Age: Real-World Examples and Prevention Strategies',
  description: 'Explore how corporate espionage has evolved in the digital age, and how PathEye can help protect businesses from insider threats and cyber intrusions.',
  icon: faBriefcase,
  date: '2024-09-13',
  author: 'PathEye Security',
};

const PostCorporateEspionage = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>{postMeta.title}</h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Introduction</h2>
      <p>
        In today’s digital landscape, corporate espionage has evolved beyond the cloak-and-dagger scenarios of the past. From cyber intrusions to insider threats, companies face increasingly sophisticated attacks aimed at stealing intellectual property, customer data, and trade secrets. As a result, businesses must rethink their security strategies to prevent espionage. PathEye, with its focus on human-engineered cybersecurity solutions, offers a unique defense by addressing these challenges head-on.
      </p>

      <h2>Real-World Examples of Corporate Espionage</h2>
      <p><strong>Coca-Cola vs. Pepsi:</strong> In 2006, a Coca-Cola employee attempted to sell company secrets to Pepsi. Instead of exploiting the stolen data, Pepsi reported the incident to the FBI, leading to the employee’s arrest. This case demonstrates that espionage is not always executed by high-level insiders—often, it’s those with lower-level access who pose significant risks.</p>
      <p><strong>Waymo vs. Uber:</strong> One of the most infamous cases in the tech sector involves Waymo (Google’s autonomous vehicle subsidiary) accusing Uber of acquiring trade secrets. A former Waymo employee downloaded thousands of confidential documents before joining Uber, which triggered a legal battle and eventually cost Uber hundreds of millions of dollars in settlement fees.</p>
      <p><strong>Volkswagen and GM:</strong> In the 1990s, a high-ranking executive defected from GM’s subsidiary, Opel, to Volkswagen, bringing with him a treasure trove of confidential documents. This espionage attempt helped Volkswagen streamline operations but resulted in a costly legal defeat. Volkswagen paid GM $100 million in damages and agreed to buy $1 billion in GM parts.</p>
      <p><strong>Insider Threats:</strong> One example involved an IT leader who copied sensitive R&D data before leaving his company to join a competitor. In another case, an IT manager created a backdoor into the company’s email system, allowing him to spy on internal communications from his new position at a competing firm. Both cases highlight how insider threats remain one of the most difficult forms of espionage to detect.</p>

      <h2>Common Espionage Techniques</h2>
      <p><strong>Social Engineering:</strong> In some cases, corporate espionage is as simple as posing as a potential customer to gain inside information. Companies have hired “freelance analysts” who pose as buyers, interview employees, and gather details about competitor products, clientele, and operations.</p>
      <p><strong>Insider Threats:</strong> Disgruntled employees, contractors, or those offered lucrative incentives by competitors can steal intellectual property and company data. Insider threats account for a significant portion of corporate espionage cases, as employees often have direct access to proprietary information.</p>
      <p><strong>State-Sponsored Espionage:</strong> Countries like China and Russia have been implicated in state-sponsored espionage efforts, where agents infiltrate companies to steal valuable data and intellectual property. For example, the SolarWinds breach in 2020 exposed vulnerabilities in companies and government agencies alike, highlighting the severity of state-sponsored threats.</p>

      <h2>How PathEye Can Prevent Breaches Like This</h2>
      <p>At PathEye, we focus on real engineering, human intelligence, and the reverse-engineering of threats. Our approach to cybersecurity goes beyond automated scans, offering a higher level of protection.</p>
      <ul>
        <li><strong>Proactive Threat Detection:</strong> PathEye uses real-time monitoring to detect and mitigate threats before they escalate. Many breaches, such as those involving insider threats, go unnoticed for months or even years. With PathEye’s 24/7/365 monitoring, incidents are detected as they occur, preventing long-term damage.</li>
        <li><strong>Data Encryption and Protection:</strong> We ensure all sensitive data is encrypted, both at rest and in transit. In the event of a breach, encrypted data is rendered unusable to hackers. Our Data Loss Prevention (DLP) services further safeguard against unauthorized data exfiltration.</li>
        <li><strong>Tailored Security Solutions:</strong> PathEye offers customized security solutions that address your business’s unique vulnerabilities, whether you’re in retail, healthcare, or finance.</li>
        <li><strong>Fourth-Party Risk Management:</strong> Beyond securing your company, PathEye evaluates your vendors and subcontractors to ensure they meet strict security standards.</li>
      </ul>

      <h2>How to Detect and Prevent Corporate Espionage</h2>
      <p><strong>Vigilance and Monitoring:</strong> Regular audits and monitoring of data access are essential to detect espionage attempts. Unusual patterns, such as accessing sensitive data outside normal working hours or from unfamiliar locations, are red flags that companies should investigate.</p>
      <p><strong>Technology and Security Solutions:</strong></p>
      <ul>
        <li>Data Loss Prevention (DLP) systems are critical to monitor and block the unauthorized transfer of sensitive data.</li>
        <li>Zero Trust Architecture ensures that every access request is authenticated and verified, regardless of its source.</li>
        <li>USB Port Restrictions can prevent employees from exfiltrating sensitive data onto external drives.</li>
        <li>VPNs and end-to-end encryption ensure that all communications are secure, while multi-factor authentication adds an additional layer of protection.</li>
      </ul>

      <h2>Why Automation Isn’t Enough</h2>
      <p>In the cybersecurity world, there’s a dangerous trend of over-relying on automated tools and scans. While automation plays an essential role in detecting and responding to threats, it isn’t foolproof. Corporate espionage often involves human manipulation and insider threats that bypass purely automated systems. PathEye’s human-engineered approach ensures that even the most subtle forms of espionage—those that blend social engineering with cyber techniques—are identified and mitigated.</p>

      <p>If your business is ready to take cybersecurity seriously and prevent breaches like these from happening, contact PathEye today. Our real-time monitoring, encryption, and tailored security solutions are designed to protect your company’s most valuable assets from even the most sophisticated threats. </p>
      

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostCorporateEspionage;

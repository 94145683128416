import React, { useRef, useEffect } from 'react';
import './CloudSecurityPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCloud, faLock, faExclamationTriangle, faClock, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function CloudSecurityPage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on load
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="cloud-sec-services-page">
      <div className="cloud-sec-services-hero">
        <div className="cloud-sec-services-hero-content">
          <h1 className="cloud-sec-services-title">Fortify Your Cloud</h1>
          <p className="cloud-sec-services-subtitle">Protect against breaches, unauthorized access, and more with PathEye’s advanced cloud security solutions.</p>
          <button className="cloud-sec-services-cta-button" onClick={scrollToScheduleSection}>
            Schedule Consultation
          </button>
        </div>
        <div className="cloud-sec-services-hero-icon">
          <FontAwesomeIcon icon={faCloud} className="cloud-sec-services-cloud-icon" />
          <FontAwesomeIcon icon={faShieldAlt} className="cloud-sec-services-shield-icon" />
        </div>
        <div className="cloud-sec-services-down-arrow">▼</div> {/* Animated down arrow */}
      </div>

      <div className="cloud-sec-services-additional-sections">
        <section className="cloud-sec-services-section">
          <h2 className="cloud-sec-services-section-title">Why Choose PathEye Cloud Security?</h2>
          <div className="cloud-sec-services-section-content">
            <p>Choosing the right cloud security provider is critical to ensuring the safety and integrity of your digital assets. With PathEye Cloud Security, you can rest assured knowing that your cloud environment is under constant vigilance and protection.</p>
          </div>
        </section>
        <section className="cloud-sec-services-section">
          <h2 className="cloud-sec-services-section-title">
            <FontAwesomeIcon icon={faClock} className="cloud-sec-services-section-icon" /> Rapid Response
          </h2>
          <div className="cloud-sec-services-section-content">
            <p>Time is of the essence when dealing with security incidents. Our rapid response services ensure that any threats are neutralized swiftly and efficiently.</p>
            <ul>
              <li>Immediate threat containment.</li>
              <li>Quick recovery to normal operations.</li>
              <li>Minimized downtime and costs.</li>
            </ul>
          </div>
        </section>
        <section className="cloud-sec-services-section">
          <h2 className="cloud-sec-services-section-title">
            <FontAwesomeIcon icon={faExclamationTriangle} className="cloud-sec-services-section-icon" /> Comprehensive Forensic Analysis
          </h2>
          <div className="cloud-sec-services-section-content">
            <p>Understanding the cause of a breach is crucial to preventing future incidents. Our forensic analysis services provide in-depth insights into security events.</p>
            <ul>
              <li>Detailed breach reports.</li>
              <li>Identification of vulnerabilities.</li>
              <li>Recommendations for security enhancements.</li>
            </ul>
          </div>
        </section>
        <section className="cloud-sec-services-section">
          <h2 className="cloud-sec-services-section-title">
            <FontAwesomeIcon icon={faMoneyBillWave} className="cloud-sec-services-section-icon" /> Cost Management
          </h2>
          <div className="cloud-sec-services-section-content">
            <p>Security breaches can be costly. We help you manage and minimize these costs through effective incident response and prevention strategies.</p>
            <ul>
              <li>Reduction of breach-related expenses.</li>
              <li>Efficient use of resources.</li>
              <li>Improved ROI on security investments.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default CloudSecurityPage;

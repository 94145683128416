import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use security-related icon
import './Posts.css';

export const postMeta = {
  title: 'Overlooked Security Vulnerabilities in Application Development: Why You Need an App Assessment from PathEye',
  description: 'Learn about commonly overlooked security vulnerabilities in app development and how PathEye’s assessment can help safeguard your business.',
  keywords: 'custom secure storage for sensitive business data, real-time data encryption for businesses, application security vulnerabilities, app security audit, app assessment service',
  icon: faShieldAlt, // Security-related icon
  date: '2024-09-26',
  author: 'PathEye Security',
};

const PostApplicationSecurityVulnerabilities = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faShieldAlt} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Overlooked Security Vulnerabilities in Application Development</h2>
      <p>
        Building secure applications is essential, yet many businesses still overlook key vulnerabilities. Whether it's a web or mobile app, the gaps between different tech stacks and teams often lead to serious security flaws. Here are some of the most commonly overlooked vulnerabilities that PathEye can help you address:
      </p>

      <h2>1. Injection Flaws</h2>
      <p>
        Despite advancements, injection flaws such as SQL Injection remain prevalent. These occur when user inputs are improperly handled, allowing attackers to manipulate queries or execute commands. Developers may correctly use parameterized SQL queries, but DBAs sometimes concatenate them into stored procedures, negating security efforts. Using prepared statements and enforcing proper input validation is key.
      </p>

      <h2>2. Path Traversal and API Vulnerabilities</h2>
      <p>
        Web applications with multiple technologies in play (load balancers, reverse proxies, and different OSs) can be vulnerable to path traversal attacks. This happens when elements in a file path are treated differently by each component, allowing unauthorized access. Proper path sanitization and <Link to="/application-assessment">API security checks</Link> are necessary to avoid these issues.
      </p>

      <h2>3. Weak Authentication & Authorization</h2>
      <p>
        Authentication flaws often arise when devs mistakenly assume that internal components are safe from external access. However, authorization (authZ) checks are frequently missed, leaving certain areas unprotected. PathEye’s <Link to="/application-assessment">assessment</Link> will pinpoint where you're missing these critical security checks.
      </p>

      <h2>4. Ciphertext Mismanagement</h2>
      <p>
        Encryption may seem like a fix-all solution, but many fail to authenticate ciphertext. Just because it’s encrypted doesn’t mean it's safe! Failing to ensure that ciphertext hasn’t been altered leaves your data vulnerable to exploitation. <Link to="/application-assessment">Real-time encryption</Link> can fix this by verifying integrity in transit.
      </p>

      <h2>5. Security Misconfigurations</h2>
      <p>
        Far too many applications leave sensitive data exposed because of misconfigurations. Default settings, exposed APIs, and weak encryption protocols all add risk. Regular <Link to="/application-assessment">assessments</Link> are essential for uncovering these hidden weaknesses.
      </p>

      <h2>6. Insecure Design</h2>
      <p>
        Insecure design occurs when applications are built without a "Security by Design" approach. It’s easy to overlook input sanitization, leaving the application open to logical errors or injection attacks. From day one, security should be integrated into the development process to ensure these vulnerabilities are addressed.
      </p>

      <h2>7. Insecure Data Storage</h2>
      <p>
        Many applications store passwords or sensitive information in plain text, both in code and in database configurations. This also includes insecure transmission of data from desktop clients to cloud servers. PathEye’s <Link to="/application-assessment">security assessment</Link> will audit your app to ensure no sensitive data is stored or transmitted insecurely.
      </p>

      <h2>8. Human Errors and Weak Spots</h2>
      <p>
        Sometimes, even simple human errors like default credentials or poor password management leave systems vulnerable. Testing and routine audits can help ensure your app is secure.
      </p>

      <h2>Why PathEye's Application Assessment Is Essential</h2>
      <p>
        It’s not just a one-off assessment. PathEye dives deep into your app's architecture, scanning for vulnerabilities you may never have considered. Our app security assessments cover:
      </p>
      <ul>
        <li>Comprehensive Code Review: Pinpoint injection flaws, insecure data storage, and overlooked security issues.</li>
        <li>Real-Time Data Encryption for Businesses: PathEye ensures sensitive data is encrypted both at rest and in transit.</li>
        <li>API and Path Traversal Security: Validate API security, eliminate path traversal vulnerabilities, and secure inter-tech communication layers.</li>
        <li>Authentication & Authorization Review: We assess your current implementation and help enforce multi-factor authentication (MFA) and better authN/authZ strategies.</li>
      </ul>
      <p>
        Don’t leave your application vulnerable. Schedule an <Link to="/application-assessment">App Assessment</Link> with PathEye to ensure your business data remains secure in real time.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostApplicationSecurityVulnerabilities;

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faBullseye, faChartLine, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import './PartnersPage.css';

const PartnersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="partners-container">
      <h2 className="partners-title">
        Partner with Us <FontAwesomeIcon icon={faHandshake} className="partners-icon-gray" />
      </h2>
      <p className="partners-intro">
        Cyber resilience is a collaborative effort. Patheye works exclusively with industry-leading resellers and agents.
      </p>
      <div className="partners-section">
        <div className="partners-card">
          <FontAwesomeIcon icon={faBullseye} className="partners-icon" />
          <h3>100% Channel Driven</h3>
          <p>
            Patheye offers our products and services exclusively through channel partners. We don't sell direct. Our partner program offers excellent sales margins and incentives, technical support, training, and certification — all simply and centrally managed through our dedicated partner portal.
          </p>
        </div>
        <div className="partners-card">
          <FontAwesomeIcon icon={faChartLine} className="partners-icon" />
          <h3>Partner Commitment</h3>
          <p>
            With generous margins and incentives, we'll help you grow your revenue with Patheye, the leading managed security platform for the cyber resilient enterprise. Build your business, while helping your customers secure theirs.
          </p>
        </div>
        <div className="partners-card">
          <FontAwesomeIcon icon={faDollarSign} className="partners-icon" />
          <h3>Partner Benefits</h3>
          <p>
            Sales tactics, incentive programs, and sales tools to drive growth and close deals. The more our partners sell, the more they earn.
          </p>
        </div>
      </div>
      <form className="partners-form">
        <h3 className="partners-form-title">Join Our Partner Program</h3>
        <div className="form-group">
          <label>Company Name*</label>
          <input type="text" required />
        </div>
        <div className="form-group">
          <label>First Name</label>
          <input type="text" />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" />
        </div>
        <div className="form-group">
          <label>Business Email*</label>
          <input type="email" required />
        </div>
        <div className="form-group">
          <label>Job Title</label>
          <input type="text" />
        </div>
        <div className="form-group">
          <label>State/Region</label>
          <input type="text" />
        </div>
        <div className="form-group">
          <label>Number of Employees</label>
          <input type="number" />
        </div>
        <div className="form-group">
          <label># of Sales People</label>
          <input type="number" />
        </div>
        <div className="form-group">
          <label>Sales Office Locations</label>
          <input type="text" />
        </div>
        <div className="form-group button-container">
          <button type="submit" className="partners-button">Submit</button>
        </div>
        <p className="privacy-text">
          By clicking submit, you agree to our privacy policy and consent to be contacted by PathEye. PathEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time.
        </p>
      </form>
    </div>
  );
};

export default PartnersPage;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; 
import './Posts.css';

export const postMeta = {
  title: 'Columbus Ransomware Attack Garners Global Attention: A Wake-Up Call for Municipal Cybersecurity',
  description: 'The recent ransomware attack on Columbus underscores the cybersecurity challenges for municipalities. Learn how PathEye can help public institutions secure their systems.',
  keywords: 'Columbus ransomware, municipal cybersecurity, public institution data security, PathEye solutions, ransomware prevention',
  icon: faLock, 
  date: '2024-09-16',
  author: 'PathEye Security',
};

const PostColumbusRansomwareAttack = ({ handleScrollToArticles, handleScrollToConsultation }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); 
  const [isListening, setIsListening] = useState(false); 
  const [isMobile, setIsMobile] = useState(false); 
  const contentRef = useRef(null); 

  useEffect(() => {
    window.scrollTo(0, 0); 

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); 
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; 
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); 
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>{postMeta.title}</h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>A New Frontline in Cybersecurity for Municipalities</h2>
      <p>
        The City of Columbus recently experienced a ransomware attack that disrupted city services, drawing global attention to the cybersecurity vulnerabilities in public institutions. This attack highlights how local governments, often with limited budgets and outdated infrastructure, are increasingly targeted by cybercriminals, and the importance of modernizing their defenses.
      </p>

      <h2>Unraveling the Columbus Ransomware Attack</h2>
      <p>
        Initially, the attack was thought to be a result of phishing, but it was later revealed that an employee downloaded a malicious file, leading to the breach. The simplicity of this error underscores how even well-trained staff can inadvertently allow cyber threats into municipal systems.
      </p>

      <h2>Rapid Response and Containment</h2>
      <p>
        Though Columbus managed to contain the attack before hackers could fully encrypt its infrastructure, the real challenge lies in identifying and closing backdoors. Without thorough forensic analysis, hidden vulnerabilities may allow attackers to re-enter the system in the future, making containment only the first step in recovery.
      </p>

      <h2>Transparency and Communication Failures</h2>
      <p>
        Public frustration escalated as city officials downplayed the attack’s severity. Some employees reported bank accounts being hacked, yet the initial public statements suggested no significant data had been compromised. Balancing transparency with investigative integrity is crucial in these situations, as it affects public trust.
      </p>

      <h2>Post-Attack Challenges</h2>
      <p>
        Even after successfully preventing full execution of the attack, it can take significant time to fully analyze and secure the system. In this case, Columbus is working with federal authorities, including the FBI, to assess the full scope of the data breach, demonstrating the complex and ongoing nature of such attacks.
      </p>

      <h2>How PathEye Can Help Protect Against Ransomware</h2>
      <p>
        PathEye offers tailored cybersecurity solutions for municipalities to prevent and mitigate the impacts of ransomware attacks. Here’s how we can help:
      </p>
      <ul>
        <li><strong>24/7 Threat Detection and Response:</strong> We continuously monitor systems to detect threats early and neutralize them before they escalate. Columbus could have minimized the breach had it employed proactive threat detection.</li>
        <li><strong>Data Encryption:</strong> PathEye ensures that all sensitive data is encrypted at rest and in transit. In the event of a breach, encrypted data is inaccessible, preventing attackers from exploiting it.</li>
        <li><strong>Human Error Mitigation:</strong> Since human error played a role in the Columbus attack, our comprehensive <span className="link" onClick={handleScrollToConsultation}>User Training programs</span> are designed to reduce these risks by educating employees on safe cyber practices and how to recognize potential threats.</li>
        <li><strong>Post-Attack Forensics:</strong> PathEye’s forensics team specializes in identifying any backdoors left by attackers and securing systems to prevent future breaches.</li>
        <li><strong>Custom Security Solutions:</strong> Every municipality has unique security needs. PathEye provides customized security strategies to protect public services, data, and infrastructure.</li>
      </ul>

      <h2>Global Attention and the Growing Ransomware Crisis</h2>
      <p>
        The Columbus attack is part of a broader global trend of ransomware attacks targeting public institutions. With increasing reliance on digital systems for managing everything from traffic lights to public utilities, the consequences of an attack can be severe. Municipalities need to adopt comprehensive cybersecurity measures to protect critical services and avoid becoming the next ransomware victim.
      </p>

      <h2>Protect Your Municipality Today</h2>
      <p>
        At PathEye, we understand the specific challenges that public institutions face. Our solutions are designed to secure critical infrastructure and prevent costly disruptions. Don’t let your municipality fall victim to ransomware. <span className="link" onClick={handleScrollToConsultation}>Contact PathEye today</span> to fortify your systems and ensure future protection.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostColumbusRansomwareAttack;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "The New Frontier in Cyber Attacks: How Hackers Exploit Common Searches to Target Users",
  description: "Learn how hackers use common search terms to lure users into traps, exploiting SEO tactics, zero-day vulnerabilities, and malware-laden downloads. PathEye's recommendations can help protect your business.",
  keywords: "cyber attacks, SEO attacks, phishing, zero-day vulnerabilities, endpoint protection, PathEye",
  icon: faMagnifyingGlass,
  date: '2024-11-12',
  author: 'PathEye Security',
};

const PostExploitingCommonSearches = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faMagnifyingGlass} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>
  {/* Post Image */}
  <img src="/images/glass.jpg" alt="Data Breach Cost Illustration" className="post-cost-image" />

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>How Hackers Are Exploiting Common Searches</h2>
      <p>
        Hackers understand that search engines are a starting point for millions of users trying to solve problems, download software, or follow tutorials. By optimizing fake pages with targeted keywords—often related to troubleshooting, updates, or installation—attackers can manipulate search results to place their sites at the top. When users click these links, they’re often prompted to download “helpful” software or follow steps that unknowingly compromise their devices.
      </p>
      <h2>Targeted SEO Tactics</h2>
      <p>
        Hackers use common terms and phrases that they know people are actively searching for, like “update,” “download,” “how to fix,” or “troubleshoot.” With these high-ranking keywords, malicious pages blend seamlessly into search results.
      </p>
      <p>
        Even worse, attackers often pair this tactic with knowledge of known software vulnerabilities. If they know of an exploit in software, they can create a “how-to” page specifically to target those users. When users download or follow the guide, they become victims of zero-day attacks, where hackers take advantage of vulnerabilities before they’re patched.
      </p>

      <h2>Why This Attack Is So Effective</h2>
      <p>
        The most alarming part of this tactic is its ability to fly under the radar. By using legitimate search engine optimization (SEO) practices, hackers can make their malicious websites look credible, even trusted. Users who are typically cautious of phishing links or suspicious emails might not hesitate to click a link in their search results, especially when it’s addressing a problem they actively want to solve.
      </p>
      <p>
        Furthermore, these attacks play on the urgency many users feel when encountering technical issues. The “quick fix” or “urgent download” makes it easy for people to bypass their usual caution, leaving them vulnerable to these cleverly disguised traps.
      </p>

      <h2>Protecting Yourself: PathEye’s Recommendations</h2>
      <p>
        As this tactic continues to gain traction, it’s essential to stay vigilant. Here’s how PathEye recommends protecting your organization and users:
      </p>
      <ul>
        <li><strong>Educate Employees on Safe Browsing Habits:</strong> Awareness is the first line of defense. Ensure that all employees know to verify URLs, avoid downloading files from unverified sources, and be cautious with any site that asks for permissions or downloads unexpectedly. Emphasize that a quick search might not always yield a safe result.</li>
        <li><strong>Be Wary of “How-To” Searches:</strong> For businesses with specialized software, this tactic is particularly dangerous. PathEye recommends creating clear, accessible support resources for your users and employees, so they don’t need to search the web for instructions or troubleshooting guides.</li>
        <li><strong>Monitor for Zero-Day Vulnerabilities:</strong> Ensure that your software and devices are regularly updated, but also stay informed of any zero-day vulnerabilities associated with critical applications. PathEye offers vulnerability monitoring services, alerting your team to emerging threats and providing actionable insights to stay ahead of attackers.</li>
        <li><strong>Implement Endpoint Protection:</strong> Protecting individual devices is crucial, as these attacks often target personal computers and mobile devices. Endpoint protection can detect and block malicious downloads, stop malware, and prevent unauthorized access—adding a critical layer of security.</li>
        <li><strong>Regularly Audit Search Engine Results for Your Brand:</strong> PathEye assists businesses in monitoring search engine results, identifying and flagging malicious sites attempting to impersonate your brand or product.</li>
      </ul>

      <h2>Building a Safer Digital Ecosystem</h2>
      <p>
        This trend serves as a reminder that security is about more than just protecting against known threats; it’s about anticipating attackers’ next moves. As hackers look for new ways to manipulate legitimate platforms, businesses must stay proactive to protect users and employees alike.
      </p>
      <p>
        At PathEye, we’re committed to helping organizations stay one step ahead. By understanding these evolving threats and adopting a proactive approach, you can protect your business and its users from falling victim to sophisticated, search-based attacks.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostExploitingCommonSearches;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for service links
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use DNA icon
import './Posts.css';

export const postMeta = {
  title: '23andMe Settles $30 Million Data Breach Lawsuit: What This Means for Genetic Data Privacy',
  description: 'Learn how the $30M 23andMe data breach settlement raises concerns about genetic data privacy and security. Discover what companies and consumers need to know about protecting sensitive genetic information.',
  keywords: '23andMe data breach, genetic privacy, cybersecurity, data breach settlement, PathEye solutions, genetic data security',
  icon: faDna, // DNA icon
  date: '2024-09-14',
  author: 'PathEye Security',
};

const Post23andMeSettlement = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faDna} className="post-icon" /> {/* DNA icon shown here */}
        {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Unlocking the Cost of Compromise</h2>
      <p>
        The recent settlement of 23andMe's $30 million data breach lawsuit underscores the growing challenges of protecting sensitive genetic data. As one of the largest consumer DNA testing companies, 23andMe provides a valuable service to millions. However, this breach raises serious questions about how such companies handle and secure genetic information, which could be used for anything from identity theft to medical research exploitation.
      </p>

      <h2>What Happened?</h2>
      <p>
        Earlier this year, 23andMe faced a class-action lawsuit following a massive data breach that exposed sensitive genetic information of millions of users. Hackers infiltrated the company’s systems, gaining unauthorized access to customer data, which was later sold on dark web marketplaces. Despite the large settlement, the breach sparks ongoing concerns about how genetic data is handled and how such companies protect this highly sensitive information.
      </p>

      <h2>Security Practices and Weaknesses</h2>
      <p>
        Users criticized 23andMe for not enforcing stronger security measures such as multi-factor authentication (MFA) or improved password complexity requirements. Some comments highlighted the company’s failure to prevent brute-force attacks or enforce password checks against known breached passwords, making it easier for attackers to exploit weak points.
      </p>
      <p>
        In light of this, PathEye offers <Link to="/application-assessment">Application Assessment</Link> and <Link to="/dlp">Data Loss Prevention (DLP)</Link> solutions to prevent unauthorized access and safeguard sensitive data like genetic information.
      </p>

      <h2>The Risks of Genetic Data Breaches</h2>
      <p>
        Unlike a typical data breach where financial or personal identification information is stolen, a breach of genetic data has far-reaching implications. Genetic information is unique and cannot be changed, which makes it more susceptible to misuse, including discrimination in employment or insurance and even blackmail. With the rise of direct-to-consumer DNA services, the risks of mishandled or sold data are growing, leading some to question whether genetic data should even be shared with for-profit companies.
      </p>

      <h2>Consumer Trust and Data Monetization Concerns</h2>
      <p>
        There is growing distrust among consumers about how companies like 23andMe might monetize their genetic data. Some commenters pointed out that in an era where "information is king," companies are incentivized to profit from the data they collect. This breach reignites fears that even if genetic data isn’t compromised in a direct attack, it could be mishandled or sold under the radar.
      </p>
      <p>
        PathEye emphasizes the importance of <Link to="/grc-compliance">GRC & Compliance</Link> audits to ensure that companies maintain the highest levels of transparency and accountability when handling sensitive data.
      </p>

      <h2>23andMe’s Response and Settlement</h2>
      <p>
        Following the breach, 23andMe strengthened its cybersecurity measures and improved privacy controls. The company settled the lawsuit for $30 million without admitting wrongdoing. However, while the financial settlement may provide relief for some users, it doesn’t erase the long-term implications of compromised genetic data.
      </p>

      <h2>The Future of Genetic Data Security</h2>
      <p>
        This case highlights the urgent need for stricter regulations, improved security practices, and more responsible handling of genetic data. Companies that manage such sensitive information must go beyond basic encryption and implement advanced threat detection, secure access controls, and privacy safeguards. The 23andMe breach serves as a stark reminder of the high stakes involved in genetic data privacy. For companies handling critical data, now is the time to reinforce their defenses and protect consumer trust.
      </p>

      <h2>How PathEye Can Prevent Breaches Like This</h2>
      <p>
        At PathEye, we specialize in real engineering, human intelligence, and proactive cybersecurity measures. Our solutions go beyond automated defenses, offering tailored protection for sensitive data, such as genetic information.
      </p>
      <ul>
        <li><strong>Proactive Threat Detection:</strong> PathEye’s <Link to="/managed-services">Managed Services</Link> provide 24/7 monitoring that detects and mitigates threats before they escalate, ensuring incidents like the 23andMe breach are addressed immediately.</li>
        <li><strong>Data Encryption and Protection:</strong> We ensure all sensitive data is encrypted both at rest and in transit. In a breach scenario, encrypted data remains inaccessible to attackers.</li>
        <li><strong>Tailored Security Solutions:</strong> PathEye customizes a security plan for your organization’s unique needs, ensuring the highest level of protection.</li>
        <li><strong>Fourth-Party Risk Management:</strong> We evaluate all vendors and third-party partners to ensure they meet strict security standards, minimizing the risk of vulnerabilities introduced through external partnerships.</li>
      </ul>
      <p>
        Don’t let your business fall victim to the next high-profile breach. Contact PathEye today to secure your data and protect your customers’ trust.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default Post23andMeSettlement;

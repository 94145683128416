import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing, faSearch } from '@fortawesome/free-solid-svg-icons';
import './SupportPage.css';

const SupportPage = () => {
  useEffect(() => {
    document.title = "PathEye Support";
    document.querySelector('meta[name="description"]').setAttribute("content", "PathEye Support: Available 24/7 to help you with any issues or challenges. Find answers, contact support, and access dedicated support information.");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="support-container">
      <h2 className="support-title">Contact Support</h2>
      <p className="support-intro">
        We are here when you need us. We’re available 24/7 to help you with any issues or challenges that arise. <br />
        <a href="/login" className="support-login-button">Login to access your dedicated support information</a>.
      </p>
      <div className="support-section">
        <h3 className="support-subtitle">Need help? Contact us now!</h3>
        <p className="support-text">
          Are you an existing customer? You can 
          <a href="mailto:support@patheye.com" className="support-link"> email support</a> or login to open a support case.
        </p>
      </div>
      <div className="support-features">
        <h3 className="support-subtitle">Find Answers, Faster</h3>
        <div className="support-card">
          <FontAwesomeIcon icon={faSearch} className="support-icon" />
          <h3>Knowledge Base</h3>
          <p>
            Browse and search for articles, rate or submit feedback.
          </p>
        </div>
        <div className="support-card">
          <FontAwesomeIcon icon={faLifeRing} className="support-icon" />
          <h3>Get Help</h3>
          <p>
            Contact support to make a request, or report a problem.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;

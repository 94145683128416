import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'; // Import the actual faShieldAlt icon object
import './Posts.css'; // Import the shared CSS file

export const postMeta = {
    title: 'Planned Parenthood Cyberattack: The Real Cost of Relying on Automation',
    description: 'Planned Parenthood’s Montana office was targeted by a ransomware attack, highlighting the dangers of relying solely on automation for cybersecurity.',
    icon: faShieldAlt, // Use the actual FontAwesome icon object, not a string
    date: '2024-09-06', // Date of publication
    author: 'Susan Kirsch', // Author name
};

const PostPlannedParenthoodCyberattack = ({ handleScrollToArticles }) => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const handleClose = () => {
    // Navigate to the home page and scroll to the articles section
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles(); // Trigger scroll to articles after navigation
    }, 300); // Delay the scroll slightly to ensure the page loads
  };

  return (
    <div className="post-content">
      <h1>{postMeta.title}</h1>
      <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>

      <div className="icon-container">
        <FontAwesomeIcon icon={postMeta.icon} className="post-icon" />
      </div>

      <p>
        Recently, Planned Parenthood confirmed it was the victim of a cyberattack orchestrated by the RansomHub ransomware group. The attack targeted its Montana office, threatening to leak 93 GB of sensitive data unless a ransom is paid. This incident highlights the vulnerabilities even large organizations face in today's threat landscape, especially when attackers exploit the gaps in automated defenses.
      </p>
      <p>
        For businesses worried about these kinds of sophisticated attacks, it's clear that relying solely on automated scans and off-the-shelf tools is not enough. Cybercriminals are becoming more advanced, and their methods now involve intricate human engineering tactics that automated systems often can't detect or mitigate. That’s where PathEye comes in.
      </p>
      <p>
        PathEye offers a solution beyond the automated, hype-driven tools that fail to catch these sophisticated breaches. We use real engineering and human expertise to stay ahead of these threats. Our tailored strategies focus on personalized protection, and our 24/7 monitoring ensures your business is never left vulnerable. Unlike many security vendors, we don’t just patch the holes after an attack; we prevent them in the first place by understanding the human elements involved.
      </p>
      <p>
        With PathEye, businesses can gain peace of mind, knowing that we focus on what truly matters—your specific security needs. It’s time for companies to ditch the hype and invest in cybersecurity that works.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostPlannedParenthoodCyberattack;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Leveling Up Cybersecurity Awareness: Creative Initiatives PathEye Can Bring to Your Team',
  description: 'Explore PathEye’s engaging cybersecurity initiatives designed to boost awareness, foster engagement, and create a security-first culture within your organization.',
  keywords: 'cybersecurity awareness, PathEye cybersecurity training, interactive security initiatives, security awareness programs, engaging cybersecurity training',
  icon: faUserShield,
  date: '2024-11-04',
  author: 'PathEye Security',
};

const PostCybersecurityAwarenessInitiatives = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faUserShield} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Cybersecurity Awareness Isn’t Just Training</h2>
      <p>
        Cybersecurity awareness isn’t just about training—it’s about creating a culture where employees are actively engaged in safeguarding company information. At PathEye, we know that memorable, hands-on experiences make the most impact. Below are some engaging cybersecurity initiatives we can help organize to reinforce best practices, boost awareness, and make security an integral part of your company’s culture.
      </p>

      <h2>1. Interactive Security Day: Experience Real-World Threats</h2>
      <p>
        Our signature Security Day is anything but your typical “helmet on, head down” safety talk. This is a hands-on experience where employees see common attack tactics firsthand, so they fully understand what threats look like in action. Here’s what we can set up:
      </p>
      <ul>
        <li><strong>USB Drop Test:</strong> We’ll discreetly place USB drives around the office to see how employees respond. These drives are loaded with a harmless test file that triggers a notification, helping us track engagement. Employees who report suspicious USBs to the security team will be recognized, reinforcing a healthy, cautious mindset.</li>
        <li><strong>Phishing Simulation Booth:</strong> Employees will interact with a live phishing simulation, exploring how cybercriminals use emails to manipulate them. Seeing real (and safe) phishing attempts helps employees recognize and resist them in the wild.</li>
      </ul>

      <h2>2. Be a Hacker for a Day: Live Hacking Demo</h2>
      <p>
        People learn best by doing, which is why we offer a "Hacker for a Day" station. With PathEye’s guidance, employees will use a basic setup to perform safe, simulated hacks on a dummy machine. This setup could include common tools like Rubber Ducky USBs or a controlled phishing email campaign. By experiencing how attacks unfold from the attacker’s perspective, they gain a stronger understanding of defensive strategies.
      </p>

      <h2>3. Cybersecurity Games and Friendly Competitions</h2>
      <p>
        Gamification is an effective way to boost engagement and make learning fun. We can create customized games to help reinforce cybersecurity concepts:
      </p>
      <ul>
        <li><strong>Phishing Email Competition:</strong> Employees craft their own phishing emails, learning how scammers exploit human psychology. We can review each entry and award a prize to the most “convincing” email (created with good intentions, of course!). This activity emphasizes the red flags to watch out for in real emails.</li>
        <li><strong>Report-to-Win Game:</strong> Create a monthly competition where employees report suspicious emails or activities to the security team. All valid reports are entered into a monthly raffle with a prize. This reinforces the importance of speaking up and helps us gather valuable feedback on what employees find concerning.</li>
      </ul>

      <h2>4. Security Champions Program</h2>
      <p>
        Every organization has employees who are naturally interested in cybersecurity. With PathEye’s Security Champions Program, we can help you identify and train these individuals as advocates in each department. Here’s how it works:
      </p>
      <ul>
        <li><strong>Point-Based Recognition:</strong> Champions earn points by completing training, reporting phishing emails, and educating colleagues. As they progress, they can achieve levels such as Security Hero, Champion, and Master, with digital badges and team recognition.</li>
        <li><strong>Department Recognition:</strong> We publicly celebrate top-performing departments in monthly newsletters or team meetings, fostering friendly competition and building cross-departmental awareness.</li>
      </ul>

      <h2>5. Targeted Brown Bag Sessions on Personal Security</h2>
      <p>
        One of the most effective ways to engage employees is by showing them how cybersecurity applies to their personal lives. We offer brown bag lunch sessions covering practical, everyday topics like online banking security, social media privacy, and personal device protection. When employees see how these principles apply at home, they’re more likely to adopt strong security habits at work.
      </p>

      <h2>6. Deepfake and Social Engineering Awareness Demos</h2>
      <p>
        With the rise of social engineering and deepfake technology, it’s critical to keep your team up to date on evolving threats. We can arrange a deepfake demo that shows how attackers could impersonate executives or coworkers, using AI technology. Another option is a live social engineering demo where PathEye consultants role-play as social engineers, demonstrating tactics like pretexting and baiting.
      </p>

      <h2>7. Monthly Incident Reporting Insights</h2>
      <p>
        To keep cybersecurity top of mind, we can help establish a monthly security newsletter that highlights anonymous incident reports, including phishing attempts or USB drops, alongside best practices. This regular communication reinforces the importance of security and recognizes the proactive efforts of employees who report suspicious activities.
      </p>

      <h2>Final Thoughts: Building a Security-First Culture</h2>
      <p>
        These cybersecurity initiatives aren’t just about compliance—they’re about creating a security-first culture where employees understand the stakes and feel empowered to protect your organization. PathEye specializes in tailoring these initiatives to fit your organization’s unique needs, and we’re here to make security engaging, effective, and memorable.
      </p>
      <p>
        Let’s make your next cybersecurity awareness program one your team will remember. Contact us today to learn how we can bring these initiatives to life for your organization.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostCybersecurityAwarenessInitiatives;

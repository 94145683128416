import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ScheduleConsultationSection.css';
import emailjs from 'emailjs-com';

function ScheduleConsultationSection() {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    timeZone: '',
    date: new Date(),
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First Name is required.';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required.';
    if (!formData.email) newErrors.email = 'Work Email is required.';
    if (!formData.timeZone) newErrors.timeZone = 'Time Zone is required.';
    if (!formData.date) newErrors.date = 'Date and Time are required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    emailjs
      .sendForm('service_txvykq3', 'template_xin3oyw', e.target, 'VelC2Zm_hcQa4Ewy8')
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="schedule-consultation-section">
      <h2 className="schedule-consultation-section-title">
        Take Control of Your Business IT & Security: Book Your Free Assessment
      </h2>
      {!submitted ? (
        <form className="schedule-consultation-section-form" onSubmit={handleSubmit}>
          <div className="schedule-consultation-section-form-row">
            <div className="schedule-consultation-section-form-group">
              <label>First Name *</label>
              <input
                type="text"
                name="firstName"
                required
                className="schedule-consultation-section-form-control"
                onChange={handleChange}
                placeholder="Enter your first name"
              />
              {errors.firstName && <p className="error-text">{errors.firstName}</p>}
            </div>
            <div className="schedule-consultation-section-form-group">
              <label>Last Name *</label>
              <input
                type="text"
                name="lastName"
                required
                className="schedule-consultation-section-form-control"
                onChange={handleChange}
                placeholder="Enter your last name"
              />
              {errors.lastName && <p className="error-text">{errors.lastName}</p>}
            </div>
          </div>
          <div className="schedule-consultation-section-form-row">
            <div className="schedule-consultation-section-form-group">
              <label>Work Email *</label>
              <input
                type="email"
                name="email"
                required
                className="schedule-consultation-section-form-control"
                onChange={handleChange}
                placeholder="Enter your email address"
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>
            <div className="schedule-consultation-section-form-group">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                className="schedule-consultation-section-form-control"
                onChange={handleChange}
                placeholder="Enter your phone number (optional)"
              />
            </div>
          </div>
          <div className="schedule-consultation-section-form-row">
            <div className="schedule-consultation-section-form-group">
              <label>Select a Date and Time *</label>
              <DatePicker
                selected={formData.date}
                onChange={(date) => setFormData({ ...formData, date })}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={new Date()}
                className="schedule-consultation-section-form-control"
              />
              {errors.date && <p className="error-text">{errors.date}</p>}
            </div>
            <div className="schedule-consultation-section-form-group">
              <label>Time Zone *</label>
              <select
                name="timeZone"
                value={formData.timeZone}
                onChange={handleChange}
                required
                className="schedule-consultation-section-form-control"
              >
                <option value="" disabled>
                  Select Time Zone
                </option>
                <option value="EST">Eastern Time (EST)</option>
                <option value="CST">Central Time (CST)</option>
                <option value="MST">Mountain Time (MST)</option>
                <option value="PST">Pacific Time (PST)</option>
              </select>
              {errors.timeZone && <p className="error-text">{errors.timeZone}</p>}
            </div>
          </div>
          <input type="hidden" name="date" value={formData.date.toISOString()} />
          <input type="hidden" name="time" value={formData.date.toLocaleTimeString()} />
          <p className="schedule-consultation-section-privacy-text">
            By clicking submit, you agree to our{' '}
            <a href="/privacy-policy" className="schedule-consultation-section-no-underline">
              privacy policy
            </a>{' '} and consent to be contacted by PathEye. PathEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time.
          </p>
          <button type="submit" className="schedule-consultation-section-submit-button">
            Submit <span>&rarr;</span>
          </button>
        </form>
      ) : (
        <p className="schedule-consultation-section-confirmation-message">
          Thank you! Your consultation has been scheduled.
        </p>
      )}
    </section>
  );
}

export default ScheduleConsultationSection;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use money-related icon
import './Posts.css';

export const postMeta = {
  title: 'Rising Cyber Threats Push CFOs to Prioritize Data Security',
  description: 'CFOs are increasingly focusing on cybersecurity and data privacy as cyber threats rise. Learn how PathEye helps finance leaders mitigate risks and embrace emerging technologies like AI.',
  keywords: 'CFOs, data security, cybersecurity, generative AI, financial planning, PathEye services',
  icon: faMoneyBillWave, // Money-related icon
  date: '2024-09-16',
  author: 'PathEye Security',
};

const PostCFODataSecurity = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>{postMeta.title}</h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Unlocking CFOs’ Role in Cybersecurity</h2>
      <p>
        As cyber threats continue to rise, CFOs are no longer just financial stewards—they are now key players in safeguarding organizational data. According to Protiviti’s latest Global Finance Trends Survey, finance leaders are increasingly focusing on cybersecurity and data privacy while embracing emerging technologies like generative AI for financial planning and analysis (FP&A). The convergence of AI and data security is reshaping how businesses approach risk management.
      </p>

      <h2>Data Security: More Than a Checkbox</h2>
      <p>
        Many organizations, especially publicly traded ones, treat cybersecurity as a compliance requirement—a mere checkbox to reduce liability or meet regulatory standards. While compliance is important, viewing cybersecurity purely as an obligation can leave critical vulnerabilities exposed. CFOs need to prioritize cybersecurity as a long-term investment rather than a short-term expense.
      </p>
      <p>
        <Link to="/dlp">PathEye’s Data Protection Solutions</Link> offer proactive defense mechanisms that go beyond regulatory requirements, ensuring that financial data is protected against emerging threats.
      </p>

      <h2>Balancing Risk and Investment</h2>
      <p>
        A recurring theme from industry feedback is that businesses tend to approach cybersecurity as a risk management exercise—spending just enough to meet compliance but not necessarily to mitigate deeper risks. CFOs must shift their mindset to see cybersecurity not just as a cost but as a necessary investment in the longevity and health of their organizations.
      </p>
      <p>
        With <Link to="/grc-compliance">PathEye’s Risk Assessment and Management</Link>, CFOs can identify critical vulnerabilities and address them strategically, preventing costly breaches.
      </p>

      <h2>Leveraging Generative AI for FP&A</h2>
      <p>
        Generative AI is another focus area for CFOs, helping to streamline FP&A processes. However, as businesses integrate AI into financial operations, they must also secure AI models and the sensitive data they process. Implementing cybersecurity measures that protect AI-driven operations will ensure that companies can innovate without compromising data security.
      </p>

      <h2>How PathEye Can Help You Save on Cyber Insurance and Avoid Costly Breach Settlements</h2>
      <p>
        At PathEye, we go beyond traditional cybersecurity to help businesses save on premiums and avoid financial disasters. By providing <Link to="/dlp">Insurance Cybersecurity Attestation</Link>, we help businesses meet attestation requirements, leading to lower insurance premiums and reduced liability in case of a breach. Additionally, our <Link to="/cloud-security">Data Encryption</Link> ensures that sensitive information is encrypted both at rest and in transit. This means that even if a breach occurs, the data remains secure, helping businesses avoid the hefty penalties and settlements that unencrypted data can cause, such as the $30 million settlement faced by 23andMe.
      </p>
      <ul>
        <li><strong>24/7 Threat Detection:</strong> PathEye’s <Link to="/managed-services">Managed Services</Link> monitor and mitigate threats in real-time, ensuring incidents are handled before they escalate.</li>
        <li><strong>Data Encryption and Protection:</strong> We secure all sensitive data both at rest and in transit, protecting your business from costly breaches.</li>
        <li><strong>AI Process Monitoring:</strong> Our AI monitoring ensures confidential data isn’t unintentionally exposed through automated processes.</li>
        <li><strong>Custom Security Plans:</strong> PathEye customizes solutions to meet your organization’s unique security requirements.</li>
      </ul>
      <p>
  Don’t let your business be the next breach headline — Contact PathEye today to secure your financial future and protect your customers’ trust.
</p>


      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostCFODataSecurity;



import React, { useState, useRef, useEffect } from 'react';
import './IncidentResponsePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faClock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function IncidentResponsePage() {
  const [breachSize, setBreachSize] = useState(5000);
  const [responseTime, setResponseTime] = useState(24);
  const [dataSensitivity, setDataSensitivity] = useState(1);
  const [totalCost, setTotalCost] = useState(null); // Initially null to hide before calculation
  const [costWithoutResponse, setCostWithoutResponse] = useState(null); // To hold the calculated cost without PathEye's service
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on load
  }, []);

  const calculateImpact = () => {
    const costPerRecord = dataSensitivity * 200; // Base cost per record based on sensitivity
    const timeFactor = responseTime / 24; // Factor for how long the response takes
    const estimatedBreachCost = breachSize * costPerRecord * timeFactor; // Calculate breach cost without response service
    const pathEyeSavings = estimatedBreachCost * 0.5; // Assume PathEye reduces the impact by 50%
    
    setCostWithoutResponse(estimatedBreachCost.toFixed(2)); // Set the cost without PathEye's service
    setTotalCost((estimatedBreachCost - pathEyeSavings).toFixed(2)); // Calculate and set the cost with PathEye's service
  };

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="incident-response-page">
      <div className="incident-response-impact-calculator-section">
        <h2 className="incident-response-section-title">Breach Impact Calculator</h2>
        <div className="incident-response-calculator-container">
          <div className="incident-response-calculator-inputs">
            <div className="incident-response-input-group">
              <label htmlFor="breach-size">Size of Breach (Number of Records):</label>
              <input
                type="number"
                id="breach-size"
                value={breachSize}
                onChange={(e) => setBreachSize(e.target.value)}
              />
            </div>
            <div className="incident-response-input-group">
              <label htmlFor="response-time">Response Time (Hours):</label>
              <input
                type="number"
                id="response-time"
                value={responseTime}
                onChange={(e) => setResponseTime(e.target.value)}
              />
            </div>
            <div className="incident-response-input-group">
              <label htmlFor="data-sensitivity">Data Sensitivity:</label>
              <select
                id="data-sensitivity"
                value={dataSensitivity}
                onChange={(e) => setDataSensitivity(e.target.value)}
              >
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
              </select>
            </div>
            <button className="incident-response-calculate-button" onClick={calculateImpact}>
              Click to Calculate Impact
            </button>
          </div>
          {totalCost !== null && (
            <div className="incident-response-calculator-results">
              <h3>Potential Financial Impact</h3>
              <p>
                Without PathEye: <span>${costWithoutResponse}</span>
              </p>
              <p>
                With PathEye: <span>${totalCost}</span>
              </p>
              <p className="incident-response-savings-highlight">
                PathEye saves you: <span>${(costWithoutResponse - totalCost).toFixed(2)}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="incident-response-additional-sections">
        <section className="incident-response-why-pick-patheye-section">
          <h2 className="incident-response-section-title">Choose PathEye for Incident Management</h2>
          <p>At PathEye, we specialize in rapid and effective incident response to minimize the impact of cybersecurity breaches on your organization. Our approach includes swift containment of threats, comprehensive forensic analysis, and cost-effective recovery strategies to ensure your business can quickly resume normal operations. With a focus on minimizing downtime, reducing breach-related expenses, and preventing future incidents, PathEye is your trusted partner in safeguarding your business from the devastating effects of cyberattacks.</p>
        </section>
        <section className="incident-response-rapid-response-section">
          <h2 className="incident-response-section-title"><FontAwesomeIcon icon={faClock} className="incident-response-section-icon" /> Rapid Response</h2>
          <p>Our expert team responds swiftly to breaches, containing the damage and minimizing the financial impact.</p>
          <ul>
            <li>Immediate threat containment.</li>
            <li>Quick recovery to normal operations.</li>
            <li>Minimized downtime and costs.</li>
          </ul>
        </section>
        <section className="incident-response-forensic-analysis-section">
          <h2 className="incident-response-section-title"><FontAwesomeIcon icon={faExclamationTriangle} className="incident-response-section-icon" /> Comprehensive Forensic Analysis</h2>
          <p>Our team conducts detailed forensic investigations to identify the source of the breach and prevent future incidents.</p>
          <ul>
            <li>Detailed breach reports.</li>
            <li>Identification of vulnerabilities.</li>
            <li>Recommendations for security enhancements.</li>
          </ul>
        </section>
        <section className="incident-response-cost-management-section">
          <h2 className="incident-response-section-title"><FontAwesomeIcon icon={faMoneyBillWave} className="incident-response-section-icon" /> Cost Management</h2>
          <p>We help you manage and mitigate the costs associated with data breaches through efficient incident response and recovery strategies.</p>
          <ul>
            <li>Reduction of breach-related expenses.</li>
            <li>Efficient use of resources.</li>
            <li>Improved ROI on security investments.</li>
          </ul>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default IncidentResponsePage;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'; // Icon for payment/credit cards
import './Posts.css'; // Import the shared CSS file

export const postMeta = {
  title: 'Slim CD Data Breach and the Case for Real Cybersecurity',
  description: 'Slim CD suffered a massive data breach affecting 1.7 million credit card accounts. Learn what went wrong and how PathEye can help prevent similar breaches.',
  icon: faCreditCard, // Use an icon for credit card/payment
  date: '2024-09-12', // Date of publication
  author: 'PathEye Security', // Author name
};

const PostSlimCDDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleClose = () => {
    // Navigate to the home page and scroll to the articles section
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles(); // Trigger scroll to articles after navigation
    }, 300); // Delay the scroll slightly to ensure the page loads
  };

  return (
    <div className="post-content">
      <h1>{postMeta.title}</h1>
      <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>

      <div className="icon-container">
        <FontAwesomeIcon icon={postMeta.icon} className="post-icon" />
      </div>

      <p>
        Back in June of this year, Slim CD, a payment gateway provider, revealed a major data breach affecting almost 1.7 million individuals. The hackers had access to Slim CD’s network for nearly a year—between August 2023 and June 2024—during which they stole sensitive credit card and personal information.
      </p>

      <p>
        This prolonged access highlights a serious flaw in Slim CD’s cybersecurity strategy. The real question is, why wasn't the sensitive customer data encrypted? Encryption is a basic, essential practice for protecting customer data, and failing to implement it leaves businesses vulnerable to long-term intrusions like this one.
      </p>

      <p>
        For companies handling such sensitive information, whether it’s payment processing or personal customer data, encryption is non-negotiable. Slim CD’s outdated website might be an aesthetic throwback to the early 2010s, but unfortunately, it seems their security practices were stuck in the past too.
      </p>

      <h2>Slim CD: The Risks of Outdated Systems and Security</h2>
      <p>
        Slim CD, a company providing point-of-sale and payment gateway solutions, likely handles transactions for retailers and other businesses. Yet, despite its role in facilitating secure payments, the breach shows that Slim CD didn’t invest in modern cybersecurity practices. When a company that processes credit card transactions fails to update its security infrastructure, the consequences are devastating not only for the business but also for every consumer whose data was stolen.
      </p>

      <p>
        This breach raises critical questions: Why was sensitive data, like credit card information, stored without proper encryption? And why was there no real-time monitoring to detect unauthorized access over nearly a year?
      </p>

      <h2>Common Gaps in Payment Gateway Security</h2>
      <ul>
        <li><strong>Encryption Failure:</strong> Sensitive data must be encrypted both at rest and in transit. Slim CD’s failure to implement this basic measure made millions of customers vulnerable.</li>
        <li><strong>Extended Breach Period:</strong> The fact that hackers had access to Slim CD’s systems for almost a year shows a lack of real-time threat monitoring.</li>
        <li><strong>Lack of Vendor Accountability:</strong> Retailers using Slim CD trusted that customer data would be protected, but Slim CD failed to implement robust security protocols.</li>
      </ul>

      <h2>How PathEye Can Prevent Breaches Like This</h2>
      <p>
        At PathEye, we focus on real engineering, human intelligence, and reverse-engineering of threats. Our approach to cybersecurity goes beyond automated scans, offering a higher level of protection than what Slim CD implemented.
      </p>
      <ul>
        <li><strong>Proactive Threat Detection:</strong> PathEye uses real-time monitoring to detect and mitigate threats before they escalate. Slim CD’s breach went unnoticed for almost a year—this would never happen with PathEye’s 24/7/365 monitoring.</li>
        <li><strong>Data Encryption and Protection:</strong> We ensure all sensitive data is encrypted, both at rest and in transit. If Slim CD had implemented this, hackers would have stolen unusable, encrypted data.</li>
        <li><strong>Tailored Security Solutions:</strong> We don’t offer one-size-fits-all solutions. We work with each business to customize a cybersecurity plan that addresses their unique vulnerabilities, including payment processors, retailers, and financial organizations.</li>
        <li><strong>Fourth-Party Risk Management:</strong> Just as important as securing third-party relationships is ensuring that any subcontractors used by those third parties meet the same strict security standards.</li>
      </ul>

      <p>
        With the increase in breaches like Slim CD’s, it’s clear that relying on outdated systems and minimal monitoring is no longer enough. PathEye offers personalized protection, ensuring that your business is safe from threats that standard automated systems often miss. Don’t let your business be the next breach headline—reach out to PathEye today to get real protection for your sensitive data.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostSlimCDDataBreach;

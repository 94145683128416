import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'T-Mobile Fined $16 Million for Data Breaches: A Cautionary Cybersecurity Tale',
  description: 'Learn about T-Mobile’s recent $16 million fine for data breaches and how PathEye can help businesses avoid similar cybersecurity failures.',
  keywords: 'T-Mobile data breach, cybersecurity solutions, real-time data encryption, insider threat monitoring, manual security audits, PathEye cyber solutions',
  icon: faMobileAlt,
  date: '2024-10-04',
  author: 'PathEye Security',
};

const PostTMobileDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faMobileAlt} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>T-Mobile Fined $16 Million for Data Breaches: A Cautionary Cybersecurity Tale</h2>
      <p>
        T-Mobile has agreed to pay a $15.75 million fine in a settlement with the Federal Communications Commission (FCC) over a series of data breaches that spanned three years and affected tens of millions of customers. The breaches, which occurred in 2021, 2022, and 2023, exposed sensitive customer data such as names, addresses, Social Security numbers, and driver’s license information. These incidents affected both direct T-Mobile customers and those using mobile virtual network operators (MVNOs) on T-Mobile's infrastructure​.
      </p>

      <h2>A Deeper Look at T-Mobile’s Failures</h2>
      <p>
        The FCC’s investigation revealed significant lapses in T-Mobile's ability to safeguard customer data. The breaches exposed flaws in their security practices, including failures to detect and protect against unauthorized access to private information. The breaches also called into question the company's legal responsibilities to safeguard customer confidentiality.
      </p>
      <p>
        The settlement not only includes a $15.75 million fine but also requires T-Mobile to invest an additional $15.75 million into bolstering its cybersecurity practices over the next two years. The company must implement a compliance plan designed to prevent future breaches and adhere to stricter security protocols.
      </p>

      <h2>PathEye’s Solutions for Preventing Similar Breaches</h2>
      <p>
        In light of T-Mobile's breaches, it's clear that a robust, real-world defense strategy is essential. PathEye offers tailored solutions to safeguard businesses against these types of cybersecurity failures:
      </p>
      <ul>
        <li><strong>Real-Time Data Encryption:</strong> Even if attackers manage to breach your systems, PathEye’s encryption methods ensure that the stolen data is inaccessible and useless to cybercriminals.</li>
        <li><strong>Insider Threat Monitoring:</strong> Repeated breaches like T-Mobile’s demonstrate the importance of comprehensive internal monitoring. PathEye’s insider threat detection goes beyond automated systems, catching vulnerabilities before they escalate.</li>
        <li><strong>Manual Engineering Security Audits:</strong> Automated scans often miss critical vulnerabilities. PathEye’s manual security assessments employ real-world tactics, reverse-engineering your systems just like an attacker would. This ensures that no hidden vulnerabilities are overlooked, keeping your business fully protected and compliant with regulations.</li>
      </ul>

      <h2>T-Mobile’s Lessons: A Need for Stronger Cybersecurity</h2>
      <p>
        T-Mobile’s breaches are a stark reminder that cybersecurity cannot be taken lightly. Regulatory compliance is important, but it must be paired with real, actionable defenses that go beyond the scope of automated tools. PathEye’s human-engineered solutions ensure that your business is not just meeting compliance standards, but actively preventing the types of vulnerabilities that lead to costly breaches.
      </p>

      <p>
        For more information, read about another recent costly corporate settlement, the <Link to="/posts/23andMe-settlement">23andMe case</Link>, and visit <a href="https://arstechnica.com/tech-policy/2024/10/t-mobile-pays-16-million-fine-for-three-years-worth-of-data-breaches/" target="_blank" rel="noopener noreferrer">Ars Technica’s coverage of the T-Mobile breaches</a>.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostTMobileDataBreach;

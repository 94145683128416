import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileShield, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Landmark Admin Data Breach Exposes Sensitive Data of 800,000 Insurance Customers: What Went Wrong?',
  description: 'Learn about the Landmark Admin data breach exposing 800,000 insurance customers, and see what steps can prevent similar incidents in the insurance sector.',
  keywords: 'Landmark Admin data breach, insurance data security, cybersecurity solutions, PathEye cybersecurity, insurance industry security',
  icon: faFileShield,
  date: '2024-11-03',
  author: 'PathEye Security',
};

const PostLandmarkAdminDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faFileShield} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>What Happened?</h2>
      <p>
        In a recent and severe cybersecurity breach, Landmark Admin, a prominent insurance administration company, reported that sensitive personal and policy information of around 800,000 customers was exposed. This breach marks yet another example of the critical vulnerabilities within the insurance sector, where vast amounts of personal data are stored and, in this case, compromised. The breach has raised important questions about data security practices, including why sensitive data wasn’t better protected and what more can be done to secure such information against future attacks.
      </p>

      <h2>Scope of the Data Exposed</h2>
      <p>
        The level of detail compromised in this breach is particularly concerning:
      </p>
      <ul>
        <li><strong>Social Security Numbers:</strong> Often used for identity verification, SSNs are critical to prevent identity theft but can be exploited if stolen.</li>
        <li><strong>Addresses and Contact Information:</strong> Address and contact data can facilitate social engineering and phishing attacks, allowing criminals to craft targeted scams.</li>
        <li><strong>Insurance Policy and Health Claims Information:</strong> Sensitive health and policy data can be misused for fraudulent health claims or sold on dark web marketplaces.</li>
        <li><strong>Financial Account Numbers:</strong> Access to financial account details heightens the risk of financial fraud, potentially impacting the financial security of affected individuals.</li>
      </ul>

      <h2>Landmark’s Response and Customer Support</h2>
      <p>
        In response to the breach, Landmark Admin is notifying all affected customers and offering them free credit monitoring and identity theft protection services. This support aims to help individuals detect any suspicious activity tied to their compromised information and mitigate risks. For many affected customers, this breach will likely create a lasting concern over the security of their personal information, particularly with Social Security and financial data in the mix.
      </p>

      <h2>PathEye’s Role in Securing Sensitive Data for the Insurance Industry</h2>
      <p>
        At PathEye, we specialize in protecting sensitive customer information and maintaining compliance with industry regulations. Our services are designed to prevent breaches like this one by focusing on key areas:
      </p>
      <ul>
        <li><strong><Link to="/dlp">End-to-End Data Encryption</Link>:</strong> Our encryption solutions protect sensitive data at every stage, ensuring it remains secure even if unauthorized access occurs.</li>
        <li><strong><Link to="/infrastructure-audit">Comprehensive Security Audits and Testing</Link>:</strong> With PathEye’s thorough audits, companies can identify and resolve security weaknesses in their systems before they become points of exploitation.</li>
        <li><strong><Link to="/grc-compliance">Insider Threat Detection</Link>:</strong> Internal monitoring tools detect and respond to any suspicious activity from within, helping prevent unauthorized access by employees or contractors.</li>
        <li><strong><Link to="/cloud-security">24/7 Threat Detection and Response</Link>:</strong> With real-time monitoring, we detect and neutralize threats as soon as they appear, helping organizations stay one step ahead of cybercriminals.</li>
      </ul>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostLandmarkAdminDataBreach;

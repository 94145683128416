import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './CapabilitiesPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserShield, faChartLine, faNetworkWired, faCloud, faBug, faShieldAlt, 
  faClipboardCheck, faLock, faSatelliteDish, faSearch, faTools, faLifeRing, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import ConsultationModal from './ConsultationModal';

const capabilities = [
  {
    category: 'User Training',
    items: [
      'Employee Phishing Training',
      'CIX Exec Training',
      'Security Awareness Training',
      'Secure Development Training',
      'Compliance Training',
      'Vendor Selection and Integration Training'
    ],
    icon: faUserShield
  },
  {
    category: 'Consulting and Advisory',
    items: [
      'Security Consulting',
      'Risk Assessment and Management',
      'Compliance Consulting (GDPR, HIPAA, etc.)',
      'Vendor Selection and Integration',
      'Due Diligence',
      'Security Policy Development'
    ],
    icon: faChartLine
  },
  {
    category: 'Penetration Testing',
    items: [
      'Web Application Penetration Testing',
      'Mobile Application Penetration Testing',
      'Network Penetration Testing',
      'Cloud Penetration Testing',
      'IoT Penetration Testing',
      'Embedded System Penetration Testing',
      'Database Penetration Testing'
    ],
    icon: faBug
  },
  {
    category: 'Application Security',
    items: [
      'Secure Development',
      'Application Assessment',
      'Database Testing',
      'Web Application Security',
      'Mobile Application Security',
      'Classic Application Security',
      'Embedded System Security',
      'IoT Application Security'
    ],
    icon: faShieldAlt
  },
  {
    category: 'Performance Testing',
    items: [
      'Load Testing',
      'Stress Testing',
      'Performance Tuning',
      'Capacity Planning',
      'Application Performance Monitoring'
    ],
    icon: faNetworkWired
  },
  {
    category: 'Monitoring & Detection',
    items: [
      'Real-time Threat Detection and Response',
      'Continuous Security Monitoring',
      'Intrusion Detection Systems (IDS)',
      'Security Information and Event Management (SIEM)',
      'Endpoint Detection and Response (EDR)'
    ],
    icon: faSatelliteDish
  },
  {
    category: 'Network Security',
    items: [
      'Network Security Assessment',
      'Firewall Configuration and Management',
      'Secure Network Design',
      'VPN Configuration',
      'Wireless Network Security'
    ],
    icon: faLock
  },
  {
    category: 'Cloud Security',
    items: [
      'Cloud Security Assessment',
      'Cloud Configuration Review',
      'Cloud Access Security Broker (CASB)',
      'Secure Cloud Migration',
      'Cloud Threat Monitoring'
    ],
    icon: faCloud
  },
  {
    category: 'Forensics & Response',
    items: [
      'Digital Forensics',
      'Incident Response Planning and Execution',
      'Malware Analysis',
      'Data Breach Investigation',
      'Threat Hunting'
    ],
    icon: faSearch
  },
  {
    category: 'Secure Development',
    items: [
      'Secure Software Development Lifecycle (SDLC)',
      'API Security',
      'DevSecOps Implementation',
      'Custom Security Solutions',
      'Third-party Software Integration'
    ],
    icon: faTools
  },
  {
    category: 'Data Security',
    items: [
      'Data Encryption',
      'Data Loss Prevention (DLP)',
      'Database Security',
      'Backup and Recovery Solutions',
      'Data Classification and Management'
    ],
    icon: faLock
  },
  {
    category: 'ID and Access Management ',
    items: [
      'User Access Review',
      'Multi-Factor Authentication (MFA)',
      'Single Sign-On (SSO) Solutions',
      'Privileged Access Management (PAM)',
      'Identity Governance and Administration (IGA)'
    ],
    icon: faClipboardCheck
  },
  {
    category: 'Compliance',
    items: [
      'Compliance Audits (GDPR, HIPAA, PCI-DSS, etc.)',
      'Governance, Risk, and Compliance (GRC)',
      'Security Policy and Procedure Development',
      'Security Awareness and Compliance Training'
    ],
    icon: faClipboardCheck
  },
  {
    category: 'Business Continuity',
    items: [
      'Business Continuity Planning (BCP)',
      'Disaster Recovery Planning (DRP)',
      'DRP Testing and Maintenance',
      'Crisis Management Planning'
    ],
    icon: faLifeRing
  },
  {
    category: 'Physical Security',
    items: [
      'Physical Security Assessment',
      'Secure Facility Design',
      'Access Control Systems',
      'CCTV and Surveillance Systems'
    ],
    icon: faLifeRing
  },
  {
    category: 'Vendor Management',
    items: [
      'Vendor Risk Assessment',
      'Vendor Compliance Monitoring',
      'Third-party Security Management'
    ],
    icon: faClipboardCheck
  }
];

function CapabilitiesPage() {
  const location = useLocation();
  const [isMonitorOn, setIsMonitorOn] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const categoryRefs = useRef({});

  useEffect(() => {
    if (location.state?.selectedCategory) {
      setIsMonitorOn(true);
      setTimeout(() => {
        if (categoryRefs.current[location.state.selectedCategory]) {
          categoryRefs.current[location.state.selectedCategory].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300); // Added delay to ensure smooth scrolling after monitor is on
    }
  }, [location.state]);

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const toggleMonitor = () => {
    if (isMonitorOn) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setIsMonitorOn(!isMonitorOn);
  };

  return (
    <div className="capabilities-page">
      <div className={`monitor ${isMonitorOn ? '' : 'off'}`}>
        {isMonitorOn ? (
          <>
            <h1 className="title">Capabilities</h1>
            <div className="capabilities-grid">
              {capabilities.map((capability, index) => (
                <div 
                  className="capability-tile" 
                  key={index} 
                  ref={(el) => categoryRefs.current[capability.category] = el}
                >
                  <h2 className="capability-title">
                    <FontAwesomeIcon icon={capability.icon} className="capability-icon" />
                    {capability.category}
                  </h2>
                  <ul className="capability-list">
                    {capability.items.map((item, idx) => (
                      <li 
                        key={idx} 
                        className="capability-item"
                        onClick={() => setSelectedItem(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="monitor-off">
            <div className="logocapabilities">PathEye</div>
            <button className="monitor-button" onClick={toggleMonitor}>Turn On <span>&rarr;</span></button>
          </div>
        )}
      </div>
      <div className="monitor-stand"></div>
      <button className={`power-button ${isMonitorOn ? 'on' : ''}`} onClick={toggleMonitor}>
        <FontAwesomeIcon icon={faPowerOff} />
      </button>

      {selectedItem && (
        <ConsultationModal
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
}

export default CapabilitiesPage;

import React, { useEffect } from 'react';
import './AboutPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faBell, faChartLine, faUsers, faClock, faTools } from '@fortawesome/free-solid-svg-icons';

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <div className="about-container">
        <section className="about-section">
          <h2 className="about-section-title">Navigating the Future of Cybersecurity</h2>
          <p>
            At PathEye, we believe cybersecurity is a journey, and we're here to guide you. Our name symbolizes our commitment to leading you safely through the complex digital landscape. We provide clear, actionable insights to steer your organization away from threats, ensuring your path is secure.
          </p>
        </section>
        <section className="mission-section">
          <h2 className="about-section-title">Tailored Cybersecurity Solutions</h2>
          <p>
            PathEye was built on the foundation that every organization faces unique cybersecurity challenges. Whether dealing with compliance requirements, emerging threats, or internal risks, we customize our approach to meet your specific needs. Our focus is on practical, effective solutions that go beyond certifications and buzzwords.
          </p>
          <p>
            Our name reflects our role as your vigilant guide, ensuring that you stay on the right path while we keep a close eye on your digital security.
          </p>
        </section>
        <section className="origin-section">
          <h2 className="about-section-title">Global Expertise, Midwest Values</h2>
          <p>
            Rooted in Cleveland, Ohio, PathEye combines the tenacity of Midwest values with global cybersecurity expertise. Our team spans across the U.S. and Canada, providing localized service with a global perspective. We take pride in our ability to offer personalized protection, whether through remote support, in-lab analysis, or on-site assistance.
          </p>
        </section>
        <section className="why-choose-us">
          <h2 className="about-section-title">Leading Experts in Cybersecurity</h2>
          <p>
            Our team consists of cybersecurity engineers, compliance specialists, threat analysts, and more. With diverse expertise across multiple disciplines, we are equipped to handle the full spectrum of cybersecurity needs, from threat detection to incident response.
          </p>
        </section>
        <section className="why-choose-us">
          <h2 className="about-section-title">Why Choose PathEye</h2>
          <div className="features-grid">
            <div className="feature-item">
              <FontAwesomeIcon icon={faUserShield} size="3x" />
              <h3>Personalized Protection</h3>
              <p>Customized detection strategy built to meet your unique needs.</p>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faBell} size="3x" />
              <h3>Clear, Actionable Alerts</h3>
              <p>Respond quickly and accurately with detailed threat notifications.</p>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faChartLine} size="3x" />
              <h3>Strengthen Your Security</h3>
              <p>Learn from every incident, improving your defense strategy.</p>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Unified Defense</h3>
              <p>Detection rules applied consistently across client environments.</p>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faClock} size="3x" />
              <h3>Peace of Mind</h3>
              <p>24/7/365 monitoring ensures you're covered every second of every day.</p>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faTools} size="3x" />
              <h3>Maximize Investments</h3>
              <p>Optimize the performance of your existing security tools.</p>
            </div>
          </div>
        </section>
        <section className="cleveland-image-section">
          <h2 className="about-section-title">Our Home: Cleveland, OH</h2>
          <img src="/cleveland-skyline.webp" alt="Cleveland Skyline" className="cleveland-image" />
        </section>
      </div>
    </div>
  );
}

export default AboutPage;

import React, { useEffect, useState, useRef } from 'react';
import './ManagedServicesPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserShield, faBrain, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function ManagedServicesPage() {
  const [logs, setLogs] = useState([]);
  const [monitoringActive, setMonitoringActive] = useState(false);
  const logRef = useRef(null);
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  useEffect(() => {
    const securityEvents = [
      'Initializing SOC Monitoring...',
      'Loading threat intelligence feeds...',
      'User: admin-user',
      'Password: ************************',
      'Login successful.',
      'Monitoring activated...',
      'Detected suspicious login attempt from IP 192.168.1.50',
      'Blocked phishing email targeting finance department.',
      'Scanning for vulnerabilities across all endpoints...',
      'Patch deployed to all servers - Critical vulnerability mitigated.',
      'Firewall blocked port scan from IP 203.0.113.85',
      'Detected and quarantined malware on endpoint HR-Workstation-22',
      'Intrusion attempt detected and blocked on web application.',
      'Network traffic anomaly detected, investigating...',
      'User training reminder: Phishing simulation scheduled for tomorrow.',
      'SOC Alert: Potential data exfiltration attempt detected.',
      'Monitoring incident response - SOC team engaged.',
      'Systems are Secure!'
    ];

    let eventIndex = 0;
    const intervalId = setInterval(() => {
      if (eventIndex < securityEvents.length) {
        setLogs((prevLogs) => [...prevLogs, securityEvents[eventIndex]]);
        eventIndex++;
      } else {
        clearInterval(intervalId);
        setMonitoringActive(true);
      }
    }, 233);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (logRef.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight;
    }
  }, [logs]);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="managed-services-page">
      <div className="monitoring-setup">
        <div className={`monitoring-screen ${monitoringActive ? 'dimmed' : ''}`}>
          <div className="log-screen" ref={logRef}>
            {logs.map((log, index) => (
              <p key={index} className="monitoring-log">{log}</p>
            ))}
            {monitoringActive && (
              <div className="reveal-content">
                <h2 className="secure-content-title">PathEye Managed Services</h2>
                <p className="secure-content-text">
                  <FontAwesomeIcon icon={faShieldAlt} className="shield-icon" />
                  Are you ready to defend your business 24x7?
                </p>
                <button className="schedule-button" onClick={scrollToScheduleSection}>
                  Engage PathEye
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="monitoring-base">
          <div className="floppy-drive"></div>
          <div className="lights">
            <div className="light red"></div>
            <div className="light green"></div>
            <div className="light yellow"></div>
          </div>
        </div>
      </div>
      <div className="additional-sections">
        <section className="why-pick-patheye-section">
          <h2 className="section-title">Why Choose PathEye Managed Services?</h2>
        </section>
        <section className="threat-detection-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faNetworkWired} className="section-icon" /> 24/7 Threat Detection</h2>
          <p>Our SOC provides continuous monitoring, detecting threats before they impact your business.</p>
          <ul>
            <li>Real-time detection of suspicious activities.</li>
            <li>Automated response to mitigate risks.</li>
            <li>Continuous threat intelligence updates.</li>
          </ul>
        </section>
        <section className="incident-response-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faUserShield} className="section-icon" /> Proactive Incident Response</h2>
          <p>Our expert team responds swiftly to incidents, minimizing damage and ensuring a quick recovery.</p>
          <ul>
            <li>Immediate investigation of security incidents.</li>
            <li>Rapid containment and eradication of threats.</li>
            <li>Post-incident analysis and reporting.</li>
          </ul>
        </section>
        <section className="compliance-management-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faBrain} className="section-icon" /> Compliance Management</h2>
          <p>Ensure your organization meets industry standards with our comprehensive compliance services.</p>
          <ul>
            <li>Regular compliance audits and assessments.</li>
            <li>Automated compliance checks and reporting.</li>
            <li>Support for GDPR, HIPAA, PCI-DSS, and more.</li>
          </ul>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default ManagedServicesPage;

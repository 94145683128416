// File: src/posts/Post-CybersecurityShadows.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "Cybersecurity in the Shadows: Protecting Businesses From Hidden Threats",
  description: "An in-depth analysis of evolving cybersecurity threats and strategies to safeguard businesses from hidden dangers.",
  keywords: "cybersecurity, hidden threats, business protection, malware, data breach",
  icon: faBug, // Updated icon
  date: '2024-12-9', // Today's date
  author: 'PathEye Security',
};

const PostCybersecurityShadows = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: postMeta.title,
        url: window.location.href,
      });
    } else {
      // Fallback for browsers that do not support the Web Share API
      const shareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(postMeta.title)}&url=${encodeURIComponent(window.location.href)}`;
      window.open(shareURL, '_blank');
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={postMeta.icon} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Post Image */}
      <img src="/images/cybersecurity-shadows.jpg" alt="Cybersecurity in the Shadows" className="post-content img" />

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      {/* Article Content */}
      <h2>When We Think About Cybersecurity...</h2>
      <p>
        When we think about cybersecurity, it’s easy to picture hackers in hoodies, working from dimly lit rooms to breach networks. But the reality is far more complex—and much closer to home. Whether it’s a weaponized USB cable, a breached hospital system, or malware targeting smartphones, the threats are evolving faster than most businesses can keep up with. If you’ve wondered how to safeguard your business against these challenges, the risks highlighted here are a wake-up call.
      </p>

      <h3>O.MG USB-C Cable: A Trojan Horse in Disguise</h3>
      <p>
        Imagine plugging in what looks like a simple USB-C cable to charge your phone, only to discover later it’s been stealing your data. This isn’t science fiction—it’s the reality of the O.MG USB-C cable, recently found to contain hidden antennas and chips capable of remotely exfiltrating information.
      </p>
      <p>
        These cables are perfect tools for bad actors looking to infiltrate personal or corporate devices. Picture this happening at your workplace—an employee unknowingly plugs in a malicious cable at a shared charging station, compromising your entire network.
      </p>

      <h4>Why this matters:</h4>
      <ul>
        <li><strong>Employees often use personal devices at work, introducing unknown vulnerabilities.</strong></li>
        <li><strong>Malicious hardware is stealthy, bypassing traditional security checks.</strong></li>
      </ul>

      <h4>To stay safe:</h4>
      <ul>
        <li><strong>Use trusted cables from verified manufacturers.</strong></li>
        <li><strong>Limit access to charging ports and establish secure device-use policies.</strong></li>
        <li><strong>Implement endpoint detection systems that monitor for unusual device activity.</strong></li>
      </ul>

      <h3>The Anna Jaques Hospital Breach: A Healthcare Data Disaster</h3>
      <p>
        When Anna Jaques Hospital faced a data breach that exposed personal information for 316,000 people, it wasn’t just the hospital that suffered—patients and employees felt the sting of stolen data, too. Breaches like this remind us that industries handling sensitive information, from healthcare to finance, are particularly vulnerable. Hospitals, often juggling tight budgets, rely on outdated systems that hackers exploit with precision.
      </p>
      <p>
        <strong>What businesses can learn:</strong>
      </p>
      <ul>
        <li><strong>Cybercriminals are opportunistic—they exploit weak points like legacy systems, third-party apps, and unpatched software.</strong></li>
        <li><strong>Data breaches don’t just cost money in fines; they damage trust and reputations.</strong></li>
      </ul>
      <p>
        <strong>Proactive steps include:</strong>
      </p>
      <ul>
        <li><strong>Regular audits, encrypted backups, and clear vendor management protocols to ensure sensitive information remains secure.</strong></li>
      </ul>

      <h3>SpyLoan Malware: The Smartphone Epidemic</h3>
      <p>
        Smartphones are essential to modern business, enabling remote work and on-the-go transactions. But the SpyLoan malware, which recently infected over 8 million devices, shows just how fragile that convenience can be. This malware doesn’t just drain bank accounts; it also steals personal and professional data, which can be used for extortion.
      </p>

      <h4>The risks:</h4>
      <ul>
        <li><strong>Employees often access company resources on smartphones, creating potential vulnerabilities.</strong></li>
        <li><strong>Malware disguised as legitimate apps can infiltrate devices unnoticed, spreading to corporate systems.</strong></li>
      </ul>

      <h4>How to protect your organization:</h4>
      <ul>
        <li><strong>Educate employees about downloading apps only from trusted sources.</strong></li>
        <li><strong>Use mobile device management (MDM) tools to enforce security policies.</strong></li>
        <li><strong>Regularly audit app permissions on company-owned devices.</strong></li>
      </ul>

      <h3>Building a Stronger Defense</h3>
      <p>
        The O.MG cable, Anna Jaques breach, and SpyLoan malware may seem unrelated, but they all highlight the need for comprehensive cybersecurity measures. Protecting your business means addressing hardware, software, and human vulnerabilities in equal measure.
      </p>

      <h4>Here’s where to start:</h4>
      <ul>
        <li><strong>Audit Your Systems:</strong> Understand where your weaknesses lie—whether it’s outdated hardware, unsecured networks, or untrained employees.</li>
        <li><strong>Fortify Your Network:</strong>
          <ul>
            <li>Use firewalls and segmentation to create layered defenses.</li>
            <li>Implement multi-factor authentication across all critical systems.</li>
          </ul>
        </li>
        <li><strong>Educate Your Team:</strong> Employees are often the weakest link. Regular training can turn them into your first line of defense.</li>
        <li><strong>Plan for the Worst:</strong> Even with the best defenses, breaches happen. A strong incident response plan can limit the damage.</li>
      </ul>

      <h3>Why Businesses Need PathEye</h3>
      <p>
        At PathEye, we specialize in keeping businesses safe from both the obvious and the hidden threats. From securing devices to proactive monitoring, our solutions are tailored to help businesses navigate an increasingly risky digital landscape.
      </p>

      <h4>Here’s what we offer:</h4>
      <ul>
        <li><strong>Expertise in hardware and software vulnerabilities.</strong></li>
        <li><strong>Proactive monitoring to catch issues before they escalate.</strong></li>
        <li><strong>Local support tailored to your unique business needs.</strong></li>
      </ul>

      <p>
        Don’t wait until it’s too late. Contact PathEye today to learn how we can protect your business, secure your data, and keep your operations running smoothly. Together, we can turn cybersecurity into your competitive advantage.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostCybersecurityShadows;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "When Resumes Become a Cyber Weapon: What the Latest Recruitment Data Breach Teaches Us",
  description: "Explore how attackers exploit resume information following a data breach, and how companies and jobseekers can protect themselves. PathEye provides essential insights and recommendations.",
  keywords: "cybersecurity, recruitment breach, data security, resume data, PathEye, data protection",
  icon: faUserShield,
  date: '2024-11-18',
  author: 'PathEye Security',
};

const PostResumeCyberWeapon = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faUserShield} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Breach: What Happened?</h2>
      <p>
        Alltech Consulting Services, a recruitment agency working with over 1,000 organizations in IT and engineering, left a database unsecured. No password, no encryption—just a wide-open door for anyone who stumbled upon it. The database contained personal information such as names, addresses, phone numbers, and job histories. For attackers, this wasn’t just a breach of personal data; it was an intelligence jackpot.
      </p>

      <h2>When Resumes Tell a Bigger Story</h2>
      <p>
        Every resume is a story, and for attackers, it’s a goldmine of actionable intelligence. Here’s why:
      </p>
      <p>
        <strong>Tech Stacks and Systems</strong><br />
        Jobseekers often list the tools and technologies they’ve worked with—everything from CRMs and ERPs to proprietary systems. If attackers know a company uses a specific platform, they can exploit vulnerabilities in that software or craft targeted phishing emails around it.
      </p>
      <p>
        <strong>Locations and Hierarchies</strong><br />
        Resumes often include office locations, team structures, and key personnel. This information allows attackers to map out a company’s organizational chart, identify potential targets, and even spoof emails from specific managers or departments.
      </p>
      <p>
        <strong>Insider Knowledge</strong><br />
        Phrases like “managed a migration to AWS” or “led a team during an SAP rollout” provide valuable insight into a company’s operations and projects. This context can help attackers predict which systems are being updated, where vulnerabilities might exist, or which employees are likely to have high-level access.
      </p>

      <h2>Fake Job Postings: A Growing Threat</h2>
      <p>
        It’s not just breaches like this one that expose sensitive resume data. Cybercriminals are increasingly using fake job postings to collect resumes from unsuspecting applicants. By posing as recruiters or HR professionals, attackers can gather a wealth of information without ever needing to breach a database. And for niche industries like IT and engineering, where resumes are rich with technical details, the potential for misuse is staggering.
      </p>
      <p>
        Imagine a fake job posting for a “Cloud Security Architect.” Applicants eagerly submit resumes listing their experience with specific security protocols, software, and cloud providers. In doing so, they hand over a cheat sheet for attacking their current or former employers.
      </p>

      <h2>Why Target Recruitment Agencies?</h2>
      <p>
        Recruitment agencies are a one-stop shop for this kind of data. They store massive databases of resumes, client details, and organizational insights—all conveniently centralized for attackers. Targeting a recruitment firm means gaining access not just to jobseekers’ personal information but also to the operational details of hundreds of client companies.
      </p>
      <p>
        This breach wasn’t just about stealing data—it was about gaining an edge. Attackers can now use the information to:
      </p>
      <ul>
        <li>Tailor phishing campaigns to specific companies.</li>
        <li>Exploit known vulnerabilities in systems mentioned in resumes.</li>
        <li>Identify high-value targets based on job roles and access levels.</li>
      </ul>

      <h2>How to Protect Against These Threats</h2>
      <p>
        This breach is a wake-up call for companies and individuals alike. Here’s what needs to change:
      </p>
      <p>
        <strong>For Recruitment Firms:</strong>
      </p>
      <ul>
        <li><strong>Secure Databases:</strong> Password protection and encryption should be non-negotiable.</li>
        <li><strong>Third-Party Security Audits:</strong> Regular assessments by independent experts can catch vulnerabilities before attackers do.</li>
        <li><strong>Data Minimization:</strong> Only store the information you absolutely need, and delete outdated data regularly.</li>
      </ul>
      <p>
        <strong>For Jobseekers:</strong>
      </p>
      <ul>
        <li><strong>Be Selective About Details:</strong> Avoid listing unnecessary technical specifics that could give attackers an advantage.</li>
        <li><strong>Research Job Postings:</strong> Verify the legitimacy of any job posting before sharing sensitive information.</li>
      </ul>
      <p>
        <strong>For Companies:</strong>
      </p>
      <ul>
        <li><strong>Vendor Security Standards:</strong> Ensure recruitment agencies meet strict security requirements.</li>
        <li><strong>Educate Employees:</strong> Train staff to recognize phishing attempts and be cautious about oversharing in resumes or online profiles.</li>
      </ul>

      <h2>The PathEye Perspective</h2>
      <p>
        At PathEye, we see incidents like this as more than breaches—they’re lessons. Lessons that data isn’t just data; it’s intelligence. Lessons that security must extend to every corner of your operations, including third-party vendors. And lessons that proactive measures, like third-party audits and continuous monitoring, are essential in today’s threat landscape.
      </p>
      <p>
        The Alltech breach reminds us that cybersecurity isn’t just about protecting what you control. It’s about securing every link in the chain—because attackers only need one weak spot to strike.
      </p>
      <p>
        If your organization works with recruitment firms or handles sensitive data, don’t wait for a breach to take action. Contact PathEye to learn how we can help you safeguard your operations, protect your people, and stay one step ahead of evolving threats.
      </p>
      <p>
        Let’s make your data a fortress, not a roadmap for attackers.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostResumeCyberWeapon;
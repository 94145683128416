import React, { useState } from 'react';
import './Footer.css';
import { Link, useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const services = [
  'Strategy Development',
  'GRC & Compliance',
  'Application Assessment',
  'Cloud Security',
  'Infrastructure Audit',
  'Data Loss Prevention (DLP)',
  'Threat Intelligence',
  'Managed Services',
  'Incident Response'
];

const capabilities = [
  'User Training',
  'Consulting and Advisory',
  'Penetration Testing',
  'Application Security',
  'Performance Testing',
  'Monitoring & Detection',
  'Network Security',
  'Cloud Security',
  'Forensics & Response',
  'Secure Development',
  'Data Security',
  'ID and Access Management',
  'Compliance',
  'Business Continuity',
  'Physical Security',
  'Vendor Management'
];

function Footer() {
  const [showAllServices, setShowAllServices] = useState(false);
  const [showAllCapabilities, setShowAllCapabilities] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleCapabilityClick = (category) => {
    navigate('/capabilities', { state: { selectedCategory: category } });
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    // EmailJS integration
    emailjs
      .send('service_txvykq3', 'newsletter_subscription', { email }, 'VelC2Zm_hcQa4Ewy8')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setSubscribed(true);
          setEmail(''); // Clear the email input
        },
        (error) => {
          console.error('Error sending email:', error.text);
        }
      );
  };

  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="footer-columns">
          <div className="footer-column">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/Service-Industries">Industries</Link></li>
              <li><Link to="/capabilities">Capabilities</Link></li>
              <li><Link to="/support">Support</Link></li>
              <li><a href="mailto:careers@patheye.com">Careers</a></li>
              <li><Link to="/partners">Partners</Link></li>
              <li><Link to="/privacy-policy">Privacy</Link></li>
              <li><Link to="/terms">Terms</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Services</h3>
            <ul>
              {services.slice(0, showAllServices ? services.length : 7).map((service, index) => (
                <li key={index}>
                  <Link
                    to={
                      service === 'Penetration Testing' || service === 'Threat Intelligence'
                        ? '/pentesting'
                        : service === 'Managed Services'
                        ? '/managed-services'
                        : service === 'Incident Response'
                        ? '/incident-response'
                        : service === 'Cloud Security'
                        ? '/cloud-security'
                        : service === 'Infrastructure Audit'
                        ? '/infrastructure-audit'
                        : service === 'Strategy Development'
                        ? '/strategy-development'
                        : service === 'GRC & Compliance'
                        ? '/grc-compliance'
                        : service === 'Application Assessment'
                        ? '/application-assessment'
                        : service === 'Data Loss Prevention (DLP)'
                        ? '/dlp'
                        : `/services#${service.toLowerCase().replace(/\s+/g, '-')}`
                    }
                  >
                    {service}
                  </Link>
                </li>
              ))}
              {!showAllServices && (
                <li>
                  <button onClick={() => setShowAllServices(true)} className="transparent-button2">
                    Show More<span>&rarr;</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="footer-column">
            <h3>Capabilities</h3>
            <ul>
              {capabilities.slice(0, showAllCapabilities ? capabilities.length : 7).map((category, index) => (
                <li key={index}>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCapabilityClick(category);
                    }}
                  >
                    {category}
                  </Link>
                </li>
              ))}
              {!showAllCapabilities && (
                <li>
                  <button onClick={() => setShowAllCapabilities(true)} className="transparent-button2">
                    Show More<span>&rarr;</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="footer-column newsletter-column">
            <h3>Subscribe to Our Newsletter</h3>
            <p>
              By providing your Email Address and submitting it via the join button, you agree to our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
            <form className="newsletter-form" onSubmit={handleSubscribe}>
              <input
                type="email"
                placeholder="Email Address*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="transparent-button2">
                Join<span>&rarr;</span>
              </button>
            </form>
            {subscribed && <p className="news-confirmation-message">Thank you for subscribing!</p>}
          </div>
        </div>
        <p className="contact"><a href="mailto:engage@PathEye.com">engage@PathEye.com</a></p>
        <p className="copyright">
  &copy; {new Date().getFullYear()} PathEye.com - All rights reserved.
</p>
      </div>
    </footer>
  );
}

export default Footer;

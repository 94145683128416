import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faLock } from '@fortawesome/free-solid-svg-icons'; // Import the faLock icon
import './Posts.css'; // Import the shared CSS file

export const postMeta = {
  title: 'Cybersecurity Must Return to Reality, Ditch the Hype',
  description: 'Cybersecurity must return to its roots of real engineering and critical thinking rather than flashy sales pitches and automated tools.',
  icon: faLock, // Assign the icon object
  datePosted: 'August 26, 2024',
};

function CybersecurityMustReturn({ handleScrollToArticles }) {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    // Navigate to the home page and scroll to "In the News" section
    navigate("/", { replace: true }); // Navigate to the home page
    setTimeout(() => {
      handleScrollToArticles(); // Scroll to the articles section after navigation
    }, 300); // Delay the scroll slightly to ensure the page loads
  };

  return (
    <div className="post-content">
      <h1>{postMeta.title}</h1>
      <p className="date-posted">Posted on {postMeta.datePosted}</p>

      {/* Display the icon */}
      <div className="icon-container">
        <FontAwesomeIcon icon={postMeta.icon} className="post-icon" />
      </div>
      <p>
        Cybersecurity, once a field dominated by engineering and critical thinking, has become entangled in the sales-driven hype machine. Over time, the industry's focus shifted from protecting organizations to pushing flashy, automated solutions that promise the world but often deliver far less. It's time for businesses to return to reality and seek security from companies that prioritize genuine engineering and human intelligence over superficial automation.
      </p>

      <p>
        At PathEye, we’re committed to breaking away from this spiral of over-monetized solutions and bringing real cybersecurity back to the forefront. Here's why you should care:
      </p>

      <p>
        Today's cybersecurity is filled with shiny tools, scanners, and automated systems that promise to catch every threat. Yet, these solutions fall short because they don’t consider the nuances of human behavior, which is often the key entry point for attackers. PathEye doesn't just rely on automation; we engineer real solutions through reverse engineering and human intelligence. We dig deeper to uncover hidden vulnerabilities, not just the ones a machine has been trained to recognize.
      </p>

      <p>
        Automation may catch known threats, but what about the unknown? At PathEye, our engineers identify, analyze, and counter threats that evade basic tools. It’s not about selling a tool; it’s about selling trust through human expertise.
      </p>

      <p>
        We live in an era where most security vendors boast about their products, but behind closed doors, their decisions are often driven by project managers and salespeople with no direct interaction with customers. At PathEye, our focus is on building real relationships with our clients. We are trusted by some of Cleveland’s largest companies for a reason—we take confidentiality and trust seriously. Our engineers work directly with you, keeping your data secure and your business safe from threats.
      </p>

      <p>
        In today’s market, cybersecurity vendors often treat protection as a one-size-fits-all commodity. But your business is unique, and so are its vulnerabilities. PathEye provides a customized detection strategy specifically tailored to your needs. No more generic solutions; we build our strategy around your specific environment, ensuring that you’re not just buying a product, but getting a solution that truly protects your organization.
      </p>

      <p>
        Unfortunately, many security vendors are more interested in generating revenue than in actually solving security problems. This leads to a vicious cycle of poorly sold solutions, where salespeople receive big commissions while engineers clean up the mess. It’s a flawed system that leaves the customer with an overhyped product that doesn’t deliver on its promises.
      </p>

      <p>
        At PathEye, we break that cycle. Our engineers and analysts are involved from start to finish, ensuring that what we deliver meets your needs—not a sales quota. We take pride in ensuring that our security measures actually protect your business and provide peace of mind.
      </p>

      <p>
        Speaking of peace of mind, we’ve got you covered every second of every day. PathEye offers 24/7/365 monitoring that ensures your organization is always protected. Our experts don’t just set it and forget it; we continuously monitor your environment, adapting to new threats and providing immediate response. When we say we have your back, we mean it.
      </p>

      <p>
        Most companies already have some form of security in place, but are those tools optimized? The answer is often no. At PathEye, we don’t just sell you new tools—we maximize the effectiveness of your current setup. Our approach ensures that you’re getting the most value out of your existing security investments. We fine-tune and enhance your current defenses to optimize performance, without wasting your resources on unnecessary additions.
      </p>

      <p>
        The truth is, security doesn’t need the hype. It needs real solutions driven by expertise, not marketing. It’s time for businesses to shift away from buzzwords and focus on cybersecurity that is grounded in reality. At PathEye, we’re here to provide that reality: personalized, human-driven protection that you can trust.
      </p>

      <p>
        If you’re tired of being sold another tool by someone who doesn’t know the ins and outs of your business, turn to PathEye. We’re not just another vendor—we’re your partner in protection.
      </p>

      {/* Close button styled as transparent-button */}
      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
}

export default CybersecurityMustReturn;

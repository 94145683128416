import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handlePrivacyPolicy = () => {
    window.location.href = '/privacy-policy'; // Update this URL to your privacy policy page
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>
        This site uses cookies. By continuing to browse this site, you agree to this use.
      </p>
      <div className="cookie-buttons">
        <button onClick={handleAccept}>OK</button>
        <button onClick={handlePrivacyPolicy}>Privacy Policy</button>
      </div>
    </div>
  );
}

export default CookieConsent;

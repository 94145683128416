import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Services.css';

function Services() {
  const navigate = useNavigate();

  const handleExploreCapabilities = () => {
    navigate('/capabilities'); // Navigate to the Capabilities page
  };

  return (
    <section id="services" className="services-section">
      <div className="services-container">
        <h2 className="services-title">Our Services</h2>
        <div className="services-grid">
          <Link to="/strategy-development" className="service-item">
            <h3>Strategy Development</h3>
            <p>We partner with you to create a customized cybersecurity strategy that aligns with your business goals, providing a roadmap to mitigate risks and safeguard your assets.</p>
          </Link>
          <Link to="/grc-compliance" className="service-item">
            <h3>GRC & Compliance</h3>
            <p>Our GRC services help your organization navigate the complexities of governance, risk management, and compliance, ensuring adherence to regulatory standards like GDPR, PCI-DSS, and HIPAA.</p>
          </Link>
          <Link to="/application-assessment" className="service-item">
            <h3>Application Assessment</h3>
            <p>We perform in-depth assessments of your applications to uncover vulnerabilities and weaknesses, ensuring they are fortified against potential cyber threats.</p>
          </Link>
          <Link to="/cloud-security" className="service-item">
            <h3>Cloud Security</h3>
            <p>Secure your cloud environments with our comprehensive cloud security services, designed to protect against data breaches, unauthorized access, and other cloud-specific threats.</p>
          </Link>
          <Link to="/infrastructure-audit" className="service-item">
            <h3>Infrastructure Audit</h3>
            <p>Evaluate your entire IT infrastructure to identify security gaps and enhance your organization’s resilience against cyber threats.</p>
          </Link>
          <Link to="/dlp" className="service-item">
            <h3>Data Loss Prevention (DLP)</h3>
            <p>Implement strategies and technologies to prevent unauthorized access, use, or transmission of sensitive data, protecting your organization from data breaches.</p>
          </Link>
          <Link to="/pentesting" className="service-item">
            <h3>Threat Intelligence</h3>
            <p>Stay ahead of cyber threats with our threat intelligence services, providing insights into potential risks and enabling proactive defense strategies.</p>
          </Link>
          <Link to="/managed-services" className="service-item">
            <h3>Managed Services</h3>
            <p>Outsource your cybersecurity management to our experts, who provide continuous monitoring, threat detection, and response to ensure your systems are always protected.</p>
          </Link>
          <Link to="/incident-response" className="service-item">
            <h3>Incident Response</h3>
            <p>Our incident response team is on standby to quickly address any security breaches, minimizing damage, and helping you recover swiftly.</p>
          </Link>
        </div>
      </div>
      <div className="cybersecurity-centered-button">
        <button onClick={handleExploreCapabilities} className="cybersecurity-hero-button">EXPLORE ALL OUR CYBER CAPABILITIES <span>&rarr;</span></button>
      </div>
    </section>
  );
}

export default Services;

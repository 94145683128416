import React, { useEffect, useRef } from 'react';
import './Stats.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faBug, faChartLine, faCertificate, faShieldAlt, faClock } from '@fortawesome/free-solid-svg-icons';

function Stats({ onScrollToConsultation }) {
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const elements = statsRef.current.querySelectorAll('.stat-item');
    elements.forEach((element) => observer.observe(element));

    return () => {
      elements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  return (
    <section id="stats" className="stats" ref={statsRef}>
      <h2 className="section-title-stats">Our Achievements</h2>
      <div className="stats-grid">
        <div className="stat-item">
          <FontAwesomeIcon icon={faBuilding} size="3x" />
          <h3>1500+</h3>
          <p>Companies Helped</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faBug} size="3x" />
          <h3>2,200+</h3>
          <p>Unique Exploits Discovered</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faChartLine} size="3x" />
          <h3>62%</h3>
          <p>Avg YoY Growth</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faCertificate} size="3x" />
          <h3>50+</h3>
          <p>Certifications Attained</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faShieldAlt} size="3x" />
          <h3>20+</h3>
          <p>Years of Cyber Experience</p>
        </div>
        <div className="stat-item">
          <FontAwesomeIcon icon={faClock} size="3x" />
          <h3>24x7x365</h3>
          <p>Security Operations Center</p>
        </div>
      </div>
      <div className="right-partners-stats">
        <h2>The Right Partners are the Best Solution</h2>
        <p>
          Technology alone is not enough to prevent cyberattacks. It takes a team of experts that understands the attackers’ playbooks and has proven processes in place to monitor, detect, and respond to threats every second of every day. By combining the right people, process, and technology, we reduce liability & risk for our clients.
        </p>
        <div className="stats-button-group">
          <button onClick={onScrollToConsultation} className="stats-transparent-button">Free Consultation <span>&rarr;</span></button>
        </div>
      </div>
    </section>
  );
}

export default Stats;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import emailjs-com
import './ConsultationModal.css';

const ConsultationModal = ({ category, onClose }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    notes: '',
  });

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle phone number changes allowing extension after the 10 digits
  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    let formattedPhone = '';
    if (input.length > 0) {
      formattedPhone = `(${input.slice(0, 3)})`;
    }
    if (input.length >= 4) {
      formattedPhone += ` ${input.slice(3, 6)}`;
    }
    if (input.length >= 7) {
      formattedPhone += `-${input.slice(6, 10)}`;
    }

    if (input.length > 10) {
      formattedPhone += ` x${input.slice(10)}`; // Allow extensions after 10 digits
    }

    setPhone(formattedPhone);
    setFormData({
      ...formData,
      phone: formattedPhone,
    });
  };

  // Restrict date picker to weekdays only
  const isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6; // Exclude weekends
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email with EmailJS
    emailjs
    .sendForm('service_txvykq3', 'template_xin3oyw', e.target, 'VelC2Zm_hcQa4Ewy8')
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true); // Show confirmation message
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Generate time slots for 8 AM - 8 PM in 30-minute intervals
  const generateTimeSlots = () => {
    const times = [];
    const start = 8 * 60; // 8 AM in minutes
    const end = 20 * 60; // 8 PM in minutes
    for (let i = start; i <= end; i += 30) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const timeString = `${hours}:${minutes === 0 ? '00' : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
      times.push(timeString);
    }
    return times;
  };

  return (
    <div className="consultation-modal-overlay">
      <div className="consultation-modal-container">
        <button className="consultation-modal-close-button" onClick={onClose}>X</button>
        
        <h3 className="consultation-modal-form-title">Schedule Confidential Consultation</h3>
        {!submitted ? (
          <form className="consultation-modal-form" onSubmit={handleSubmit}>
            <div className="consultation-modal-form-row">
              <div className="consultation-modal-form-group">
                <label>First Name *</label>
                <input
                  type="text"
                  name="firstName"
                  required
                  className="consultation-modal-form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="consultation-modal-form-group">
                <label>Last Name *</label>
                <input
                  type="text"
                  name="lastName"
                  required
                  className="consultation-modal-form-control"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="consultation-modal-form-row">
              <div className="consultation-modal-form-group">
                <label>Work Email *</label>
                <input
                  type="email"
                  name="email"
                  required
                  className="consultation-modal-form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="consultation-modal-form-group">
                <label>Phone *</label>
                <input
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  className="consultation-modal-form-control"
                  required
                />
              </div>
            </div>
            <div className="consultation-modal-form-row">
              <div className="consultation-modal-form-group">
                <label>Select a Date *</label>
                <input
                  type="date"
                  name="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                  className="consultation-modal-form-control"
                  min={new Date().toISOString().split("T")[0]} // Set min date to today
                  disabled={!isWeekday(selectedDate)} // Disable non-weekday dates
                />
              </div>
              <div className="consultation-modal-form-group">
                <label>Select a Time (EST)*</label>
                <select
                  name="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  required
                  className="consultation-modal-form-control"
                >
                  <option value="">Select Time</option>
                  {generateTimeSlots().map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="consultation-modal-form-row">
              <div className="consultation-modal-form-group">
                <label>Notes</label>
                <textarea
                  name="notes"
                  className="consultation-modal-form-control"
                  onChange={handleChange}
                  placeholder="Any additional information..."
                ></textarea>
              </div>
            </div>
            <p className="consultation-modal-privacy-text">
              By clicking submit, you agree to our <Link to="/privacy-policy" className="consultation-modal-no-underline">privacy policy</Link> and consent to be contacted by PathEye. PathEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time.
            </p>
            <button type="submit" className="consultation-modal-transparent-button">
              Submit <span>&rarr;</span>
            </button>
          </form>
        ) : (
          <p className="consultation-modal-confirmation-message">Thank you! Your consultation has been scheduled. You will receive a calendar invite for the time selected (Eastern Time).</p>
        )}
      </div>
    </div>
  );
};

export default ConsultationModal;

import React, { useRef, useEffect } from 'react';
import './HeroSection.css';

const HeroSection = ({ onScrollToConsultation }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.6;
    }
  }, []);

  return (
    <section className="hero">
      <video className="hero-video" autoPlay loop muted ref={videoRef}>
        <source src="/moxy2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>Secure Your Business with PathEye</h1>
        <p>Providing expert cybersecurity services to protect your business from threats.</p>
        <button className="hero-button" onClick={onScrollToConsultation}>
          Free Security Assessment <span>&rarr;</span>
        </button>
      </div>
    </section>
  );
};

export default HeroSection;

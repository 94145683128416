import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Wells Fargo Data Breach Highlights Insider Threats and Customer Risks',
  description: 'Learn about Wells Fargo’s insider data breach and how PathEye’s real-time encryption and insider threat monitoring can protect your business from similar vulnerabilities.',
  keywords: 'Wells Fargo data breach, insider threat protection, real-time data encryption for businesses, custom secure storage for sensitive business data, financial institution security, PathEye cyber solutions',
  icon: faShieldAlt,
  date: '2024-09-29',
  author: 'PathEye Security',
};

const PostWellsFargoDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faShieldAlt} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Wells Fargo Data Breach: Insider Threats</h2>
      <p>
        Wells Fargo is once again in the spotlight due to a significant data breach that affected a large number of its customers. Between May 2022 and March 2023, a former employee accessed and exploited sensitive personal information—including Social Security numbers, bank account details, and more—for illicit purposes. This is the second time in recent months that Wells Fargo has been hit by an insider breach, with the previous incident involving customer mortgage data.
      </p>
      <p>
        This situation serves as a stark reminder of one of the most challenging cybersecurity risks for businesses: insider threats. While many companies focus on protecting their networks from external attacks, the threat from within—those with authorized access to sensitive information—remains one of the most dangerous and difficult-to-prevent vulnerabilities.
      </p>

      <h2>Delayed Response Increases Risk</h2>
      <p>
        One of the major criticisms surrounding this breach is the delayed response. Wells Fargo took several months from the time the breach was identified to notify affected customers. This lag in communication can significantly increase the potential for fraud, as malicious activity could continue undetected during that time. Prompt notification is critical in minimizing the damage and protecting customers from identity theft and other harmful consequences.
      </p>

      <h2>A Broader Industry Issue: Insider Breaches Are Not Rare</h2>
      <p>
        This is far from an isolated incident. Many high-profile breaches across various industries have stemmed from insider access or poor internal security protocols. For example, Disney recently shifted away from third-party platforms like Slack after a breach exposed 1.1TB of sensitive data. Likewise, Electronic Arts (EA) fell victim to hackers who manipulated internal employees through social engineering tactics to gain access to sensitive information. These breaches demonstrate that insider threats aren't limited to the financial sector—they are a widespread issue that can severely impact companies if left unchecked.
      </p>

      <h2>Insider Threats and Data Protection: What Can Be Done?</h2>
      <p>
        To reduce the risk of insider threats, companies must take proactive steps, including:
      </p>
      <ul>
        <li><strong>Employee Monitoring:</strong> Regular reviews of employees with access to sensitive information can help catch unusual activities early.</li>
        <li><strong>Access Segmentation:</strong> Limiting access to sensitive data and following the principle of least privilege can prevent unauthorized access.</li>
        <li><strong><Link to="/dlp">Real-Time Data Encryption</Link>:</strong> Encrypting data ensures that even if it’s accessed, it cannot be misused by attackers. Wells Fargo could have mitigated the damage if this measure had been in place.</li>
        <li><strong>Prompt Communication:</strong> Companies must notify customers immediately following any security breaches to minimize the potential for fraud.</li>
      </ul>

    

      <p>
        Don’t let your business be the next breach headline. Contact PathEye today and safeguard your sensitive data with our cutting-edge solutions.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostWellsFargoDataBreach;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "PathEye Perspective: Why the U.S. Government Wants Developers to Shift to Safer Programming Languages",
  description: "Discover why the U.S. government is urging a shift from memory-unsafe languages like C/C++ to safer options like Rust, and learn how PathEye can help your organization make the transition securely.",
  keywords: "memory-safe languages, Rust, secure programming, PathEye security, CISA, critical infrastructure",
  icon: faCode,
  date: '2024-11-11',
  author: 'PathEye Security',
};

const PostShiftToSaferLanguages = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faCode} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Problem with Memory-Unsafe Languages</h2>
      <p>
        Languages like C and C++ have long been the backbone of software development, but their reliance on manual memory management presents serious risks. Errors such as buffer overflows and use-after-free vulnerabilities occur when memory is not handled correctly, providing potential entry points for attackers.
      </p>

      <h2>Why Memory Safety Matters for Your Business</h2>
      <p>
        For industries relying on critical infrastructure, software security isn’t optional—it’s essential. The U.S. government’s call to action is a reminder that software vulnerabilities are more than just technical issues; they pose a real threat to operational stability, customer trust, and public safety.
      </p>

      <h2>PathEye’s Approach to Secure Software Development</h2>
      <p>
        At PathEye, we understand that transitioning to memory-safe languages can be daunting, especially for organizations managing complex legacy systems. Here’s how we can help:
      </p>
      <ul>
        <li><strong>Security Assessments of Existing Code:</strong> PathEye offers in-depth code reviews to identify vulnerabilities in memory-unsafe languages within your current software stack.</li>
        <li><strong>Training in Memory-Safe Development:</strong> We provide tailored training programs to help development teams transition smoothly to memory-safe languages like Rust and Go.</li>
        <li><strong>Strategic Migration Planning:</strong> PathEye collaborates with your team to develop a phased approach, balancing immediate security needs with long-term goals.</li>
        <li><strong>Hybrid Approaches for Legacy Systems:</strong> We recommend hybrid solutions that combine memory-safe modules with existing codebases.</li>
        <li><strong>Ongoing Security Monitoring and Updates:</strong> PathEye’s real-time monitoring tools help detect potential threats.</li>
      </ul>

      <h2>The Path Forward: Security-First Development for a Resilient Future</h2>
      <p>
        The U.S. government’s push toward memory-safe programming languages is a clear signal that cybersecurity must be prioritized from the earliest stages of software development. Contact PathEye today to learn how we can help you strengthen your software security.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostShiftToSaferLanguages;

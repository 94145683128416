import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use professional services-related icon
import './Posts.css';

export const postMeta = {
  title: 'Wright, Moore, DeHart, Dupuis & Hutchinson: Data Breach Notification and Cybersecurity Insights for Accounting Firms',
  description: 'Learn about the WMDDH data breach and how PathEye’s tailored cybersecurity solutions can protect accounting firms.',
  keywords: 'accounting cybersecurity, data breach, vendor risk management, PathEye services, regulatory compliance',
  icon: faFileInvoice, // Accounting-related icon
  date: '2024-09-22',
  author: 'PathEye Security',
};

const PostWMDDHDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faFileInvoice} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>What Happened</h2>
      <p>
        In July 2023, WMDDH detected unusual network activity and quickly took steps to secure their systems. A comprehensive investigation was launched, revealing that certain personal data may have been accessed without authorization. After months of review, they determined that client information was potentially compromised, prompting a notification process and offering affected individuals access to credit monitoring services.
      </p>

      <h2>The Importance of Cybersecurity for Professional Services Firms</h2>
      <p>
        Handling sensitive financial data makes accounting and legal firms particularly attractive targets for cybercriminals. In today’s digital age, hackers focus on stealing client data, which can be sold on the dark web or held for ransom. Firms need to prioritize <Link to="/grc-compliance">data security</Link> not just to prevent breaches, but to maintain trust and compliance with federal regulations such as those imposed by the IRS and AICPA.
      </p>

      <h2>Cybersecurity Regulations for Accounting Firms</h2>
      <p>
        All accounting firms must comply with cybersecurity regulations from the IRS, which mandates the protection of taxpayer data, and AICPA’s professional conduct rules regarding client confidentiality. Firms should be aware of other relevant regulatory frameworks like the <Link to="/dlp">California Consumer Privacy Act (CCPA)</Link> and the <Link to="/grc-compliance">General Data Protection Regulation (GDPR)</Link>, especially when handling data across borders.
      </p>

      <h2>Best Practices for Data Security</h2>
      <ul>
        <li><Link to="/data-encryption">Data Encryption</Link>: Ensure that sensitive data is encrypted both at rest and in transit.</li>
        <li><Link to="/multi-factor-authentication">Multi-Factor Authentication (MFA)</Link>: Require MFA for accessing systems that handle client data.</li>
        <li><Link to="/secure-file-transfers">Secure File Transfers</Link>: Avoid emailing sensitive documents and use secure, encrypted portals for data exchanges.</li>
      </ul>

      <h2>Client Education: Ask About Data Security</h2>
      <p>
        Clients should be proactive in asking how their data is being protected. Many smaller firms may not have advanced security systems in place, while larger firms should have well-documented security protocols. Clients should avoid sharing sensitive information via email and ask for more secure communication methods such as <Link to="/secure-file-transfers">encrypted file transfer services</Link>.
      </p>

      <h2>The Role of Cybersecurity Audits</h2>
      <p>
        Regular <Link to="/infrastructure-audit">audits and vulnerability assessments</Link> are crucial for identifying weak points in a firm’s security infrastructure. Firms should conduct periodic reviews of their systems, including hardware, software, and third-party vendors, to ensure they meet the latest security standards and can withstand cyber threats.
      </p>

      <h2>Vendor Dependencies and Third-Party Risks</h2>
      <p>
        Like many firms, WMDDH may rely on third-party vendors for certain services. However, vendors often don’t have the same stringent security protocols, creating a backdoor for hackers. Firms must evaluate their vendors’ cybersecurity practices and ensure they comply with the necessary security standards through <Link to="/third-party-risk-management">third-party risk management</Link>.
      </p>

      <h2>Post-Breach Responsibilities</h2>
      <p>
        In the event of a breach, accounting firms must provide immediate notification to affected clients, offer credit monitoring services, and implement additional security measures to prevent further incidents. As seen in WMDDH’s response, transparency and swift action are crucial in mitigating the effects of a breach and maintaining client trust.
      </p>

      <h2>Why Cybersecurity Matters for Accounting Firms</h2>
      <p>
        For accounting firms, cybersecurity is more than just protecting data—it’s about safeguarding client trust. Any breach can result in lost revenue, lawsuits, and long-term reputational damage. Firms must view cybersecurity as an investment rather than an expense, ensuring they comply with both regulatory requirements and client expectations.
      </p>

      <h2>How PathEye Can Help Your Firm Avoid Data Breaches</h2>
      <ul>
        <li><Link to="/vulnerability-scanning">Vulnerability Scanning</Link>: Detect system vulnerabilities before they are exploited.</li>
        <li><Link to="/grc-compliance">Cybersecurity Audits</Link>: We offer comprehensive audits to identify weak points in your security infrastructure.</li>
        <li><Link to="/data-encryption">Data Encryption</Link>: Ensure sensitive client information is fully encrypted at all times, both in transit and at rest.</li>
        <li><Link to="/multi-factor-authentication">Multi-Factor Authentication</Link>: We implement strong MFA solutions to protect your systems from unauthorized access.</li>
        <li><Link to="/third-party-risk-management">Third-Party Risk Management</Link>: We assess your vendors to ensure they meet the highest security standards, reducing your overall risk.</li>
        <li><Link to="/incident-response">Post-Breach Response Plans</Link>: In the event of a breach, PathEye helps you implement swift response measures, including client notification and mitigation services like credit monitoring.</li>
      </ul>

      <p>
        Don’t let your firm be the next to fall victim to a data breach. Contact PathEye today for a consultation and ensure your <Link to="/cybersecurity-services">cybersecurity practices</Link> are up to par with industry standards.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostWMDDHDataBreach;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWater, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'American Water Works Cyberattack: A Critical Infrastructure Alarm',
  description: 'American Water Works experiences a significant cyberattack, exposing customer data. Learn how PathEye can help protect critical infrastructure sectors from cyber risks.',
  keywords: 'American Water Works cyberattack, critical infrastructure cybersecurity, real-time data encryption, manual security audits, PathEye solutions, water utility cyber threats',
  icon: faWater,
  date: '2024-10-10',
  author: 'PathEye Security',
};

const PostAmericanWaterWorksCyberattack = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faWater} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>American Water Works Cyberattack: A Critical Infrastructure Alarm</h2>
      <p>
        American Water Works, one of the largest water utility providers in the U.S., recently experienced a significant cybersecurity breach that exposed sensitive customer data and forced the company to halt its billing operations. The breach impacted hundreds of thousands of customers across 14 states, including those receiving services through the company’s 500 managed water and wastewater systems.
      </p>
      <h2>Details of the Attack</h2>
      <p>
        The breach, discovered last Thursday, led the Camden, New Jersey-based utility to take immediate action by deactivating certain systems to protect customer information. Despite these efforts, the company has been unable to predict the full impact of the attack. Fortunately, water delivery and wastewater services remain unaffected. American Water has assured customers that no late fees will be incurred during this disruption while its systems are down for investigation. Additionally, the company is working with law enforcement to determine the nature and scope of the breach​(<a href="https://www.cbsnews.com/news/security-hack-breach-american-water-works/">CBS News</a>).
      </p>
      <h2>Critical Infrastructure Vulnerabilities</h2>
      <p>
        This incident highlights the growing risks to critical infrastructure sectors like water, energy, and telecommunications. With water utilities becoming increasingly reliant on digital systems, they are prime targets for cybercriminals seeking to exploit outdated technology and security gaps. American Water’s services are vital to over 14 million people, and any disruption to their systems poses significant risks to public health and safety.
      </p>
      <p>
        Concerns over cyberattacks on critical infrastructure have grown, with U.S. officials particularly alarmed by alleged foreign threats, including Chinese intelligence efforts to target infrastructure networks, such as water-treatment facilities. This has amplified the need for stricter cybersecurity defenses across essential services.
      </p>
      <h2>Financial Repercussions</h2>
      <p>
        The breach has not only raised operational concerns but also had immediate financial consequences for American Water. The company’s stock dropped 3.9%, wiping $5.58 off each share, reducing its market capitalization to $26.69 billion. This underscores the broad-reaching impact that cybersecurity failures can have, from operational disruptions to financial losses and damaged reputations.
      </p>
      <h2>PathEye’s Solutions for Critical Infrastructure Protection</h2>
      <p>
        To mitigate the risks of such cyberattacks, PathEye offers comprehensive solutions tailored to critical infrastructure needs:
      </p>
      <ul>
        <li><strong><Link to="/dlp">Real-Time Data Encryption</Link></strong>: PathEye’s encryption services ensure that even if attackers breach a system, the data remains inaccessible and useless to them.</li>
        <li><strong>Manual Engineering Security Audits</strong>: Unlike standard compliance checks, PathEye’s manual audits replicate real-world attack strategies through reverse engineering. This method reveals vulnerabilities missed by automated tools, providing a deeper level of security for critical systems.</li>
        <li><strong><Link to="/infrastructure-audit">Frequent Infrastructure & App Audits</Link></strong>: PathEye offers continuous assessments to ensure that systems stay updated and protected against emerging threats, safeguarding both customer data and operational stability.</li>
      </ul>
      <h2>A Wake-Up Call for Cybersecurity in Critical Sectors</h2>
      <p>
        The American Water Works breach is a stark reminder of the growing cybersecurity challenges faced by critical infrastructure sectors. Companies must adopt advanced, real-world defense strategies to protect against increasingly sophisticated cyberattacks.
      </p>
      <p>
       Contact PathEye today to see how we can help secure your infrastructure against future threats.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostAmericanWaterWorksCyberattack;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Fidelity Data Breach: 77,000 Customers Exposed—Why Weren\'t Critical Data Safeguards in Place?',
  description: 'Learn about the Fidelity data breach that exposed 77,000 customers and why real-time data encryption could have prevented this critical incident.',
  keywords: 'Fidelity data breach, real-time encryption, database security, cybersecurity solutions, PathEye cybersecurity',
  icon: faShieldAlt,
  date: '2024-10-11',
  author: 'PathEye Security',
};

const PostFidelityDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faShieldAlt} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Details of the Breach</h2>
      <p>
        Fidelity Investments, one of the largest asset managers in the world, has confirmed that a data breach in August 2024 exposed sensitive personal information, including Social Security numbers and driver’s license details, of more than 77,000 customers. The breach, which occurred between August 17 and 19, was enabled through the creation of two fraudulent customer accounts that allowed unauthorized access to a vast internal database. However, many are left questioning why this sensitive data wasn't encrypted, a standard practice for protecting such critical information.
      </p>

      <h2>Unanswered Questions: Why Wasn’t the Data Encrypted?</h2>
      <p>
        One of the most pressing questions raised by cybersecurity experts and affected customers is: why wasn’t this sensitive information encrypted? In today’s digital age, encryption is a fundamental safeguard that ensures even if data is accessed by unauthorized parties, it remains unreadable and unusable without the proper decryption keys.
      </p>
      <p>
        Given that Social Security numbers and driver’s licenses are highly sensitive forms of personally identifiable information (PII), it is especially concerning that they were stored in a manner vulnerable to such an attack. Financial institutions, which manage vast amounts of critical data, are typically expected to implement the highest levels of security—encryption being one of the most basic measures. The fact that this data was not encrypted exposes a significant lapse in Fidelity's cybersecurity practices.
      </p>

      <h2>Fidelity’s Response</h2>
      <p>
        Fidelity has assured customers that no funds or account details were accessed in the breach, and it has since taken steps to mitigate the damage. However, the firm has remained tight-lipped about the specifics of how the breach was allowed to affect such a large number of customers from just two accounts. The company is currently working with law enforcement and cybersecurity experts to further investigate the incident.
      </p>

      <h2>PathEye’s Perspective: How to Prevent Such Breaches</h2>
      <p>
        In light of Fidelity’s breach, businesses handling sensitive customer data must adopt more aggressive cybersecurity measures to protect against similar incidents. At PathEye, we advocate for the following strategies:
      </p>
      <ul>
        <li><strong><Link to="/dlp">Real-Time Data Encryption</Link>:</strong> If Fidelity had encrypted its sensitive data, the attackers would have been unable to exploit it even if they had gained access. PathEye’s encryption solutions ensure that all sensitive data remains secure, no matter where or how it is stored.</li>
        <li><strong><Link to="/infrastructure-audit">Database Security Audits</Link>:</strong> Regular manual audits of databases are crucial in detecting weaknesses that automated systems might miss. These audits should identify data vulnerabilities, especially in how customer information is stored and accessed.</li>
        <li><strong><Link to="/grc-compliance">Stronger Account Verification Processes</Link>:</strong> The use of fraudulent accounts to access critical data underscores the need for more robust verification methods. PathEye offers advanced authentication systems to ensure that only legitimate users can access sensitive databases.</li>
      </ul>

      <h2>Final Thoughts</h2>
      <p>
        This breach serves as a stark reminder that even industry giants are not immune to critical security failures, particularly when basic practices like encryption are overlooked. As cyberattacks grow in sophistication, businesses must adopt multi-layered cybersecurity strategies that include data encryption, regular security assessments, and improved access control measures to safeguard their customers' sensitive information.
      </p>
      <p>
        For more details, read the full report on <a href="https://techcrunch.com/2024/10/07/comcast-says-customer-data-stolen-in-ransomware-attack-on-debt-collection-agency/" target="_blank" rel="noopener noreferrer">TechCrunch</a>.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostFidelityDataBreach;

import React, { useState, useRef, useEffect } from 'react';
import './InfrastructureAuditPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faMagnifyingGlass, faNetworkWired, faCogs } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function InfrastructureAuditPage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on load
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="Infrastructure-Audit-page">
      <div className="Infrastructure-Audit-hero">
        <div className="Infrastructure-Audit-hero-content">
          <h1 className="Infrastructure-Audit-title">Secure Your Infrastructure</h1>
          <p className="Infrastructure-Audit-subtitle">PathEye's Infrastructure Audit identifies vulnerabilities and fortifies your IT assets against evolving cyber threats. </p>
          <button className="Infrastructure-Audit-cta-button" onClick={scrollToScheduleSection}>
            Schedule Consultation
          </button>
        </div>
        <div className="Infrastructure-Audit-hero-icon">
          <FontAwesomeIcon icon={faServer} className="Infrastructure-Audit-server-icon" />
          <FontAwesomeIcon icon={faMagnifyingGlass} className="Infrastructure-Audit-magnifying-glass-icon" />
        </div>
        <div className="Infrastructure-Audit-down-arrow">▼</div> {/* Animated down arrow */}
      </div>

      <div className="Infrastructure-Audit-additional-sections">
        <section className="Infrastructure-Audit-section">
          <h2 className="Infrastructure-Audit-section-title">Why Choose PathEye for Your Infrastructure Audit?</h2>
          <div className="Infrastructure-Audit-section-content">
            <p>Choosing the right provider for your infrastructure audit is crucial to ensuring the security and efficiency of your digital assets. With PathEye, you can be confident that your IT environment is under constant surveillance, with vulnerabilities identified and addressed before they can be exploited. Our team of experts utilizes advanced techniques to safeguard your infrastructure, providing you with peace of mind and a secure foundation for your business operations.</p>
          </div>
        </section>
        <section className="Infrastructure-Audit-threat-detection-section">
          <h2 className="Infrastructure-Audit-section-title">
            <FontAwesomeIcon icon={faNetworkWired} className="Infrastructure-Audit-section-icon" /> Comprehensive Vulnerability Assessment
          </h2>
          <div className="Infrastructure-Audit-section-content">
            <p>We scan every layer of your IT infrastructure to identify vulnerabilities before they can be exploited.</p>
            <ul>
              <li>Thorough assessment of networks, servers, databases, and applications.</li>
              <li>Advanced threat detection techniques to stay ahead of potential breaches.</li>
              <li>Actionable recommendations for closing security gaps.</li>
            </ul>
          </div>
        </section>
        <section className="Infrastructure-Audit-forensic-analysis-section">
          <h2 className="Infrastructure-Audit-section-title">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="Infrastructure-Audit-section-icon" /> Detailed Forensic Analysis
          </h2>
          <div className="Infrastructure-Audit-section-content">
            <p>In the event of a breach, our forensic analysis provides insights to prevent future incidents.</p>
            <ul>
              <li>In-depth investigation of security incidents.</li>
              <li>Identification of the root cause and entry points.</li>
              <li>Comprehensive reports with remediation strategies.</li>
            </ul>
          </div>
        </section>
        <section className="Infrastructure-Audit-performance-optimization-section">
          <h2 className="Infrastructure-Audit-section-title">
            <FontAwesomeIcon icon={faCogs} className="Infrastructure-Audit-section-icon" /> Performance Optimization
          </h2>
          <div className="Infrastructure-Audit-section-content">
            <p>Beyond security, our audit services also focus on optimizing your infrastructure's performance.</p>
            <ul>
              <li>Identification and removal of performance bottlenecks.</li>
              <li>Recommendations for enhancing system efficiency.</li>
              <li>Continuous monitoring to maintain optimal performance.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default InfrastructureAuditPage;

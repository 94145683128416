import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';

function LoginPage({ handleScrollToConsultation }) {
  const navigate = useNavigate();
  const [error, setError] = useState(false); // Track login error state

  const handleDemoClick = () => {
    navigate('/');
    setTimeout(() => {
      handleScrollToConsultation();
    }, 500);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    // Simulate server validation
    const fakeEmail = 'user@example.com';
    const fakePassword = 'password123';

    const formData = new FormData(e.target);
    const email = formData.get('email');
    const password = formData.get('password');

    if (email === fakeEmail && password === fakePassword) {
      // Simulate successful login (redirect or action)
      console.log('Login successful');
    } else {
      // Simulate invalid credentials
      setError(true);
    }
  };

  return (
    <div className="login-page">
      <div className="form-container">
        <h2>Login</h2>
        <p>PathEye Login and Training Access</p>
        <p>
          Whether you're an administrator accessing PathEye or a user seeking to log in for your training sessions, you've come to the right place.
        </p>
        <form onSubmit={handleLoginSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="email" required />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" name="password" required />
          </div>
          {error && <p className="error-text">Invalid credentials. Please try again.</p>}
          <button type="submit" className="transparent-buttonlogin">
            Login <span>&rarr;</span>
          </button>
        </form>
        <p className="demo-link">
          Not a customer? Find out how PathEye can secure your business.{' '}
          <span onClick={handleDemoClick} className="demo-link-text">Schedule a consultation &gt;</span>
        </p>
      </div>
    </div>
  );
}



export default LoginPage;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use healthcare-related icon
import './Posts.css';

export const postMeta = {
  title: 'Why Hackers Target Hospitals: The Hidden Risks and High Stakes of Healthcare Cybersecurity',
  description: 'Learn why hospitals are prime targets for hackers and how PathEye’s healthcare cybersecurity solutions can mitigate the risks.',
  keywords: 'healthcare cybersecurity, hospital data breach, ransomware, PathEye services, medical devices, vendor risk management',
  icon: faHeartbeat, // Healthcare-related icon
  date: '2024-09-18',
  author: 'PathEye Security',
};

const PostHospitalsCybersecurity = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faHeartbeat} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>Why Hospitals Are Prime Targets for Cyberattacks</h2>
      <p>
        Healthcare organizations, particularly hospitals, face unique cybersecurity challenges. But why are hospitals specifically targeted, where the stakes often involve lives?
      </p>

      <h2>Outdated Tech and High Stakes</h2>
      <p>
        Many hospitals rely on outdated technology, such as end-of-life operating systems and unpatched medical devices, creating a “perfect storm” for hackers. These outdated systems are a goldmine for cybercriminals seeking easy access to sensitive data. Hackers also understand that with high patient loads and life-saving operations on the line, hospitals are more likely to pay ransoms quickly to restore critical systems.
      </p>

      <h2>Human Error: The Weakest Link</h2>
      <p>
        Human error remains a significant risk in healthcare cybersecurity. Healthcare professionals, under immense pressure, often circumvent security protocols for convenience. Sharing login credentials, failing to log out properly, and using unauthorized tools (shadow IT) are common. These actions, while intended to speed up patient care, often introduce new vulnerabilities into the system.
      </p>

      <h2>Complex and Fragmented IT Systems</h2>
      <p>
        Healthcare facilities tend to have a complex and fragmented IT infrastructure, where multiple systems must interact, yet often do not communicate seamlessly. This disjointed structure makes it difficult to create a cohesive security strategy, leaving multiple potential points of entry for hackers to exploit. Many hospitals struggle with implementing robust cybersecurity measures across their diverse systems, which include both medical devices and administrative platforms.
      </p>

      <h2>Vendor Dependencies and Third-Party Risk</h2>
      <p>
        Hospitals rely on various vendors for medical devices, software, and services. Unfortunately, third-party vendors often have weak cybersecurity practices, which can serve as an entry point for attackers. Hospitals, with their limited resources, may not have the capacity to enforce stricter security requirements on these vendors, creating another layer of vulnerability.
      </p>

      <h2>Emotional Cost: Lives at Risk</h2>
      <p>
        One of the most concerning aspects of a healthcare cyberattack is the potential human cost. In extreme cases, ransomware attacks can force critical systems offline, putting patient care on hold and even risking lives. Some hackers target hospitals unintentionally, while others intentionally leverage this high-stakes environment to extort large sums of money, despite the potential human toll.
      </p>
      <h2>PathEye’s Comprehensive Healthcare Cybersecurity Solutions</h2>
      <p>
        At PathEye, we understand the unique challenges that healthcare organizations face. Our tailored solutions are designed to address the vulnerabilities that hospitals struggle with daily.
      </p>

      <h2>Medical and IoT Device Testing</h2>
      <p>
        One of the most critical areas where hospitals fall short is securing medical and IoT devices. These devices often have outdated software, which is rarely patched, leaving them open to cyberattacks. PathEye offers deep testing of these devices, identifying vulnerabilities missed by other scans and traditional security vendors.
      </p>

      <h2>24/7 Threat Monitoring and Incident Response</h2>
      <p>
        PathEye provides real-time threat detection, ensuring that hospitals can catch potential breaches early. Our team works to neutralize threats before they can escalate into full-blown attacks that could disrupt patient care.
      </p>

      <h2>Data Encryption and Secure Operations</h2>
      <p>
        To protect the highly sensitive data that hospitals store, PathEye ensures encryption of all patient data at rest and in transit. This approach ensures that even in the event of a breach, the compromised data is unusable to attackers.
      </p>

      <h2>Vendor Risk Management</h2>
      <p>
        We offer comprehensive vendor risk management services, evaluating the cybersecurity practices of third-party vendors and ensuring that hospitals can work with their partners securely.
      </p>

      <h2>Conclusion: Why Hospitals Need Comprehensive Cybersecurity</h2>
      <p>
        Healthcare organizations, particularly hospitals, operate in a high-risk environment with outdated technology, fragmented systems, and a significant reliance on third-party vendors. PathEye's comprehensive cybersecurity solutions help healthcare providers secure their systems, safeguard patient data, and ensure that critical operations remain uninterrupted. With the growing frequency of cyberattacks in healthcare, it’s essential that hospitals invest in robust cybersecurity measures before they become the next target.
      </p>
      <p>
        Protect your healthcare organization with PathEye today.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostHospitalsCybersecurity;

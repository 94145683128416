import React, { useEffect, useRef } from 'react';
import './DLPPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt, faExclamationTriangle, faDatabase } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function DLPPage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="dlp-page">
      <div className="dlp-hero">
        <div className="dlp-hero-content">
          <h1 className="dlp-title">Protect Your Data with DLP Solutions</h1>
          <p className="dlp-subtitle">Ensure the safety of your sensitive data with PathEye’s advanced Data Loss Prevention (DLP) services.</p>
          <button className="dlp-cta-button" onClick={scrollToScheduleSection}>
            Get Started
          </button>
        </div>
        <div className="dlp-down-arrow">▼</div>
      </div>

      <div className="dlp-additional-sections">
        <section className="dlp-section">
          <h2 className="dlp-section-title">Why Choose PathEye for Data Loss Prevention?</h2>
          <div className="dlp-section-content">
            <p>Data is the lifeblood of your business. PathEye offers robust Data Loss Prevention (DLP) solutions that safeguard your most sensitive information from internal and external threats. Our solutions are designed to protect data across all endpoints, networks, and cloud environments.</p>
          </div>
        </section>
        <section className="dlp-detection-section">
          <h2 className="dlp-section-title">
            <FontAwesomeIcon icon={faExclamationTriangle} className="dlp-section-icon" /> Comprehensive Data Protection
          </h2>
          <div className="dlp-section-content">
            <p>We provide end-to-end data protection, ensuring that your sensitive information is safe from unauthorized access and data breaches.</p>
            <ul>
              <li>Real-time monitoring and threat detection.</li>
              <li>Advanced encryption and access control mechanisms.</li>
              <li>Protection across endpoints, networks, and cloud environments.</li>
            </ul>
          </div>
        </section>
        <section className="dlp-prevention-section">
          <h2 className="dlp-section-title">
            <FontAwesomeIcon icon={faShieldAlt} className="dlp-section-icon" /> Advanced Threat Prevention
          </h2>
          <div className="dlp-section-content">
            <p>Our DLP solutions not only detect threats but also prevent data exfiltration through advanced behavioral analytics and machine learning algorithms.</p>
            <ul>
              <li>Behavioral analytics to identify potential insider threats.</li>
              <li>Machine learning-driven data classification and protection.</li>
              <li>Automated response to prevent data breaches in real-time.</li>
            </ul>
          </div>
        </section>
        <section className="dlp-compliance-section">
          <h2 className="dlp-section-title">
            <FontAwesomeIcon icon={faDatabase} className="dlp-section-icon" /> Compliance and Reporting
          </h2>
          <div className="dlp-section-content">
            <p>PathEye’s DLP solutions are designed to help you meet stringent regulatory requirements and maintain compliance with data protection laws.</p>
            <ul>
              <li>Comprehensive reporting for audit and compliance purposes.</li>
              <li>Integration with existing compliance frameworks.</li>
              <li>Automated compliance checks and enforcement.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default DLPPage;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Great Expressions Faces Class Action Lawsuit Settlement Over Data Breach – Why Companies Can’t Afford to Overlook Cybersecurity',
  description: 'Learn how the Great Expressions data breach led to a class action lawsuit and why companies must invest in proactive cybersecurity solutions.',
  keywords: 'Great Expressions data breach, class action lawsuit, cybersecurity solutions, data encryption, PathEye cybersecurity',
  icon: faTooth,
  date: '2024-10-21', // Use the current date
  author: 'PathEye Security',
};

const PostGreatExpressionsBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faTooth} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Real Cost of Data Breaches</h2>
      <p>
        Great Expressions Dental Centers has agreed to a class action lawsuit settlement after a data breach exposed the sensitive personal information of thousands of patients. This breach resulted in a flood of lawsuits, and while the settlement amount remains undisclosed, it’s a sobering example of how costly cybersecurity failures can be. The affected data included Social Security numbers, healthcare information, and other personal details, leaving individuals vulnerable to identity theft and fraud.
      </p>

      <h2>PathEye: The Cheaper, Smarter Alternative</h2>
      <p>
        At PathEye, we push companies to see the bigger picture. With tailored cybersecurity solutions, we help businesses protect their sensitive data without the risk of losing everything to hackers and costly lawsuits. Here’s how we help:
      </p>
      <ul>
        <li><strong><Link to="/managed-services">Cost-Effective Security Solutions</Link>:</strong> Our services cost a fraction of the financial blow companies suffer from a breach. Instead of paying millions in settlements and legal fees, businesses can invest in PathEye’s advanced security, ensuring their data remains secure.</li>
        <li><strong><Link to="/strategy-development">Proactive Defense</Link>:</strong> Instead of reacting to a breach after it’s happened, we offer proactive solutions. From real-time threat monitoring to penetration testing, our human-engineered methods identify vulnerabilities before attackers exploit them.</li>
        <li><strong><Link to="/dlp">End-to-End Data Encryption</Link>:</strong> The simplest way to avoid data loss? Encrypt everything. PathEye ensures your sensitive data is inaccessible to hackers, even if they breach your systems.</li>
      </ul>

      <h2>The Bottom Line</h2>
      <p>
        Companies like Great Expressions learned the hard way—neglecting cybersecurity can cost you your business. With PathEye, we offer a much cheaper alternative: investing in security upfront to avoid costly lawsuits and settlements down the road. Don’t wait until it’s too late.
      </p>
      <p>
        For more details on the lawsuit, check out the original article on <a href="https://www.the-sun.com/money/12712936/class-action-law-suit-settlement-great-expressions/" target="_blank" rel="nofollow noopener noreferrer">The Sun</a>.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostGreatExpressionsBreach;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons'; // Updated car icon
import './Posts.css'; // Import the shared CSS file

export const postMeta = {
  title: 'Avis Car Rental Data Breach: What Happened and How It Affects You',
  description: 'Avis Rent A Car recently disclosed a significant data breach that compromised sensitive customer information. Find out what happened and what you need to do.',
  icon: faCar, // Updated to use the car icon
  date: '2024-09-06', // Date of publication
  author: 'Susan Kirsch', // Author name
};

const PostAvisDataBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleClose = () => {
    // Navigate to the home page and scroll to the articles section
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles(); // Trigger scroll to articles after navigation
    }, 300); // Delay the scroll slightly to ensure the page loads
  };

  return (
    <div className="post-content">
      <h1>{postMeta.title}</h1>
      <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>

      <div className="icon-container">
        <FontAwesomeIcon icon={postMeta.icon} className="post-icon" />
      </div>

      <p>
        Avis Rent A Car, one of the largest car rental services in the world, recently disclosed a significant data breach that compromised the sensitive information of its customers. Between August 3 and August 6, 2024, unauthorized individuals accessed parts of Avis's business systems, stealing personal data that included names, addresses, driver's license numbers, financial account details, and dates of birth. On September 4, 2024, Avis officially notified impacted customers and filed reports with relevant authorities, including the California Attorney General’s Office.
      </p>

      <h2>What Led to the Breach?</h2>
      <p>
        The breach began when attackers gained access to Avis's network on August 3, 2024, exploiting vulnerabilities in the company's IT systems. The unauthorized access was not discovered until August 5, when Avis detected the intrusion and quickly terminated the attacker’s access on August 6. Avis immediately launched an internal investigation, supported by external cybersecurity experts, to assess the scope of the damage and determine how much data had been exposed.
      </p>
      <p>
        Through this investigation, it was confirmed that attackers had access to several key business applications, and sensitive customer data was compromised. By August 14, the investigation revealed that significant personal details were stolen, prompting Avis to notify customers.
      </p>

      <h2>Why Is This Significant?</h2>
      <p>
        The breach at Avis is part of a larger trend of cyberattacks targeting organizations with vast amounts of customer data. Given that Avis operates over 5,500 locations globally and generates billions in annual revenue, its data systems store a wealth of customer information, making it an attractive target for cybercriminals. The stolen data, such as driver's license numbers and financial details, presents a serious risk of identity theft and fraud for the affected individuals.
      </p>

      <h2>Avis's Response and Customer Support</h2>
      <p>
        After identifying the breach, Avis implemented enhanced security measures across its systems and is actively working with cybersecurity experts to prevent future incidents. In addition, Avis is offering affected customers a free one-year subscription to Equifax's credit monitoring service to help them track any fraudulent activity linked to the stolen data.
      </p>
      <p>
        Avis has also warned its customers to stay vigilant by regularly checking their financial statements, monitoring credit reports, and reporting any suspicious activity. These proactive measures aim to help customers protect themselves from identity theft following the breach.
      </p>

      <h2>The Growing Threat of Cyberattacks in Major Industries</h2>
      <p>
        This breach underscores the growing threat of cyberattacks in industries that manage sensitive customer data. From retail to healthcare to car rentals, no sector is immune to attacks. Companies like Avis, which serve millions of customers and rely heavily on interconnected IT systems, face an increased risk as cybercriminals develop more sophisticated attack methods.
      </p>

      <h2>How PathEye Can Help</h2>
      <p>
        In light of the Avis breach, it's more important than ever for businesses to evaluate their cybersecurity practices. Many companies rely on automated tools to detect breaches, but these systems often miss more sophisticated human-engineered threats. PathEye offers a different approach, focusing on real engineering and human intelligence rather than automation alone. Our services include:
      </p>
      <ul>
        <li><strong>24/7 Monitoring:</strong> Continuous real-time detection to identify and mitigate threats before they cause damage.</li>
        <li><strong>Customized Security Solutions:</strong> Every company has unique vulnerabilities, and we tailor our strategies to fit your specific security needs.</li>
        <li><strong>Data Breach Prevention and Response:</strong> We work with organizations to proactively secure their systems and provide immediate incident response to minimize the impact of breaches.</li>
      </ul>
      <p>
        The Avis breach is a clear reminder that all organizations need to stay ahead of cybercriminals. Protecting customer data requires a strategic, human-driven approach, and PathEye is here to help businesses navigate the complex landscape of modern cybersecurity.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostAvisDataBreach;

// File: src/posts/Post-RealCostOverlookedSecurity.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "The Real Cost of Overlooked Security: Lessons from a Breach",
  description: "An analysis of the repercussions of neglected cybersecurity measures, highlighting lessons from recent breaches.",
  keywords: "cybersecurity, data breach, security lessons, PathEye, IT security",
  icon: faExclamationTriangle,
  date: '2024-12-02', // Static posting date
  author: 'PathEye Security',
};

const PostRealCostOverlookedSecurity = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: postMeta.title,
        url: window.location.href,
      });
    } else {
      // Fallback for browsers that do not support the Web Share API
      const shareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(postMeta.title)}&url=${encodeURIComponent(window.location.href)}`;
      window.open(shareURL, '_blank');
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={postMeta.icon} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Post Image */}
      <img src="/images/real-cost-overlooked-security.jpg" alt="Real Cost of Overlooked Security" className="post-conent img" />

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      {/* Article Content */}
      <h2>Let’s Be Honest—Cybersecurity Isn’t Just a Tech Problem Anymore</h2>
      <p>
        Cybersecurity isn’t just a tech problem anymore. It’s a reputation problem, a trust problem, and, most importantly, a business survival problem. If you need proof, look no further than the recent penalty slapped on the HMI Institute of Health Sciences for breaching Singapore’s Personal Data Protection Act. The takeaway? One crack in your security armor can lead to consequences that ripple far beyond the IT department.
      </p>

      <h2>What Went Wrong at HMI?</h2>
      <p>
        HMI, a healthcare training institute, recently came under fire for failing to meet its "protection obligation." Simply put, they didn’t do enough to secure the personal data of their trainees. What kind of data? The stuff that makes identity thieves salivate—names, contact details, and even national ID numbers.
      </p>
      <p>
        So, what happened? A misconfigured server exposed this sensitive information to the public. For months, no one noticed. No alarms, no proactive checks. And when the breach finally came to light, the damage was done—not just to the victims but to HMI’s reputation. Regulators stepped in, fined them, and sent a clear message: ignorance is no excuse in cybersecurity.
      </p>

      <h2>Why This Matters to Every Business</h2>
      <p>
        You might think this is a healthcare problem, but it’s not. Any business that collects, processes, or stores personal data is walking the same tightrope. It’s easy to think, “We’ve got firewalls and backups; we’re fine.” But are you really?
      </p>
      <p>
        The reality is that vulnerabilities don’t just live in complex code—they creep in through forgotten settings, outdated systems, and assumptions that "it won't happen to us."
      </p>
      <p>
        And the stakes? Let’s spell it out:
      </p>
      <ul>
        <li><strong>Reputation Damage:</strong> Customers don’t just leave; they tell everyone why.</li>
        <li><strong>Regulatory Penalties:</strong> Think fines are bad? Try losing the licenses you need to operate.</li>
        <li><strong>Operational Chaos:</strong> Breaches don’t just cost money—they cost time, focus, and momentum.</li>
      </ul>

      <h2>The Holiday Trap: When Breaches Love to Strike</h2>
      <p>
        Think about the holidays—staff are out, IT teams are stretched thin, and systems are running on autopilot. Sound familiar? Hackers think so too. They know when you’re most vulnerable, and they’re ready to strike when your guard is down.
      </p>
      <p>
        How many companies run critical updates over a holiday weekend? Not enough. How many have airtight incident response plans ready to roll? Even fewer. This isn’t about fear-mongering; it’s about being realistic. Cybercriminals don’t take vacations, and neither should your defenses.
      </p>

      <h2>The Path Forward: Don’t Patch—Plan</h2>
      <p>
        Here’s what businesses need to understand: cybersecurity isn’t about fixing problems after they happen; it’s about not giving those problems room to exist in the first place.
      </p>
      <p>
        At PathEye, we focus on just that. Think of us as your trusted co-pilot—whether you’re building a secure infrastructure from scratch or reinforcing the one you’ve got.
      </p>
      <ul>
        <li><strong>Proactive Security Audits:</strong> We find the cracks in your armor before the bad guys do.</li>
        <li><strong>Vendor Management:</strong> Third parties can be a weak link—we make sure they’re not.</li>
        <li><strong>Comprehensive Managed Services:</strong> From IT support to advanced cybersecurity, we’ve got you covered.</li>
        <li><strong>On-Demand Expertise:</strong> No CIO? No problem. We can act as your outsourced tech leadership, saving you the overhead without sacrificing the expertise.</li>
      </ul>

      <h2>Lessons from HMI: Don’t Let It Be You</h2>
      <p>
        HMI’s story is a cautionary tale, but it doesn’t have to be yours. Breaches don’t just hurt your bottom line—they erode trust, something that’s nearly impossible to rebuild.
      </p>
      <p>
        So, ask yourself:
      </p>
      <ul>
        <li>Are your systems secure, or do you just think they are?</li>
        <li>Do you know what your third-party vendors are doing with your data?</li>
        <li>If a breach happened tomorrow, would you know what to do?</li>
      </ul>
      <p>
        If any of these questions make you hesitate, we’re here to help. Protecting your business, your data, and your reputation isn’t just our job—it’s our mission.
      </p>
      <p>
        Let’s connect. Because the best defense against cybersecurity threats? A partner who understands what’s at stake.
      </p>
      <p>
        Reach out to PathEye today—we’ll show you how to turn vulnerabilities into victories.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostRealCostOverlookedSecurity;

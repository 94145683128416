import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Use security-related icon
import './Posts.css';

export const postMeta = {
  title: 'Why Relying on Third-Party Platforms for Sensitive Data Storage is Risky: Secure Alternatives with PathEye',
  description: 'Learn how businesses can avoid the pitfalls of third-party tools like Slack, and why real-time encryption and custom secure storage are key to protecting sensitive data.',
  keywords: 'real-time data encryption for businesses, custom secure storage for sensitive business data, business data security, encryption solutions, PathEye data protection, prevent data breach, Disney Slack breach, EA breach, secure business communication',
  icon: faLock, // Security-related icon
  date: '2024-09-24',
  author: 'PathEye Security',
};

const PostThirdPartyPlatformsRisk = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faLock} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Real Problem: Data Vulnerabilities in Third-Party Platforms</h2>
      <p>
        Many businesses use third-party tools to store and share sensitive business data—everything from financial information and project details to client records and proprietary research. While these platforms may offer convenience, they also become prime targets for attackers due to the wealth of information stored.
      </p>
      <p>
        In the case of Disney, attackers stole 1.1 TB of data from Slack, gaining access to sensitive project information, financial records, and other critical data. Similarly, EA was breached through Slack when hackers obtained login credentials through social engineering, gaining access to critical game development data, including the source code for major titles.
      </p>

      <h2>Real-Time Encryption: The Ultimate Defense</h2>
      <p>
        No system is completely immune from being breached, but PathEye offers a solution that ensures even if attackers get inside, they leave empty-handed. PathEye’s real-time data encryption for businesses ensures that sensitive information is encrypted both at rest and in transit. This makes any stolen data useless to hackers, even if they manage to breach a system.
      </p>

      <h2>A Tailored Solution: Secure Storage for Business Data</h2>
      <p>
        Unlike generic third-party tools, PathEye’s custom solutions are built with businesses' unique needs in mind. We offer a comprehensive approach that goes beyond just encryption:
      </p>

      <ul>
        <li>Custom Secure Storage: Tailored to your industry and operational needs, ensuring that sensitive information remains encrypted and secured within your internal infrastructure.</li>
        <li>Real-Time Encryption: We employ encryption methods that constantly protect your data in real time, from file storage to communication channels. This mitigates the risk of data leaks in case of a breach.</li>
        <li>Proactive Monitoring: We don’t just encrypt your data; we continuously monitor for suspicious activity across your data stores, identifying potential breaches before they escalate.</li>
        <li>Third-Party Integration: While we believe in moving away from over-reliance on third-party platforms, our solutions easily integrate with existing tools, ensuring a seamless transition to a more secure infrastructure.</li>
      </ul>

      <h2>Why Every Business is Vulnerable</h2>
      <p>
        Many organizations, regardless of their size or budget, mistakenly believe that implementing a third-party tool means they’re fully secure. The truth is, no platform is impervious to attack. Both Disney and EA invested heavily in security, yet attackers still found ways in—whether through a vulnerability scan or exploiting human error.
      </p>
      <p>
        This is why every business, regardless of spending, must prioritize real-time encryption and custom secure storage to safeguard critical information from the get-go. Attackers don't differentiate between small startups and industry giants when it comes to potential data heists.
      </p>

      <h2>Conclusion: Don’t Wait for the Next Breach—Secure Your Data Now</h2>
      <p>
        Recent breaches, like those affecting Disney and EA, serve as a stark reminder that relying solely on third-party tools without additional security measures can lead to significant data loss and reputational damage. PathEye provides the solutions your business needs to avoid falling victim to the next big breach.
      </p>
      <p>
        Whether your business is large or small, protecting sensitive business data should be a top priority. Don’t wait until it’s too late. Contact PathEye today to secure your infrastructure with custom storage solutions, real-time encryption, and industry-leading data protection measures.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostThirdPartyPlatformsRisk;

import React, { useEffect } from 'react';
import './ServiceIndustriesPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faIndustry, faUniversity, faBolt, faLaptopCode, faMobileAlt, faShoppingCart, faCar, faShieldAlt, faGavel, faTrain, faHotel, faBalanceScale, faFileContract, faCreditCard, faGlobe, faBriefcase, faChartLine, faBuilding, faCity, faFlask, faHospital, faHardHat, faFilm, faHandsHelping, faSeedling, faTruck, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function ServiceIndustriesPage() {
    const navigate = useNavigate();

    // Scroll to top when the page loads
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const industries = [
        { title: 'Accounting', icon: faBriefcase, description: 'Protect client financial data and maintain compliance with our specialized cybersecurity services for CPA firms.' },
        { title: 'Agriculture', icon: faSeedling, description: 'Secure agricultural technologies and protect sensitive farming data with specialized cybersecurity services.' },
        { title: 'Automotive', icon: faCar, description: 'Secure connected vehicles and protect against automotive cyber threats with our specialized services.' },
        { title: 'Construction', icon: faHardHat, description: 'Secure project management systems, designs, and supply chains with tailored cybersecurity solutions for construction and engineering.' },
        { title: 'Ecommerce', icon: faGlobe, description: 'Protect online transactions and customer data with our specialized cybersecurity services for the ecommerce industry.' },
        { title: 'Education', icon: faUniversity, description: 'Protect student and staff data while ensuring a safe digital learning environment.' },
        { title: 'Entertainment', icon: faFilm, description: 'Safeguard intellectual property, production environments, and customer data with tailored cybersecurity solutions for the entertainment industry.' }, // New industry added
        { title: 'Financial', icon: faShieldAlt, description: 'Defend against financial fraud and data breaches with our cutting-edge cybersecurity solutions for financial services.' },
        { title: 'Government', icon: faCity, description: 'Safeguard critical infrastructure and sensitive data with our government-grade cybersecurity services.' },
        { title: 'Healthcare', icon: faHeartbeat, description: 'Protect patient data and ensure HIPAA compliance with our advanced cybersecurity solutions.' },
        { title: 'Medical Payments', icon: faFileInvoiceDollar, description: 'Ensure secure processing of claims, customer data protection, and compliance for healthcare payers.' },
        { title: 'Hospitality', icon: faHotel, description: 'Safeguard guest information and ensure a secure environment with our tailored hospitality cybersecurity solutions.' },
        { title: 'Infrastructure', icon: faBolt, description: 'Ensure the safety and reliability of critical infrastructure, including utilities, transportation, and telecom, with comprehensive cybersecurity solutions.' },
        { title: 'Insurance', icon: faFileContract, description: 'Secure customer data and ensure regulatory compliance with our specialized insurance cybersecurity services.' },
        { title: 'Legal', icon: faBalanceScale, description: 'Protect sensitive legal documents and communications with our advanced cybersecurity measures.' },
        { title: 'Supply Chain', icon: faTruck, description: 'Secure your logistics operations and protect your supply chain with tailored cybersecurity solutions.' },
        { title: 'Manufacturing', icon: faIndustry, description: 'Secure your supply chain and production facilities against modern cyber threats.' },
        { title: 'Media', icon: faFilm, description: 'Safeguard digital assets and protect intellectual property with specialized solutions for the media and entertainment industry.' },
        { title: 'Medical', icon: faHospital, description: 'Ensure the safety of patient data and compliance with regulations for hospitals and clinics.' },
        { title: 'Nonprofits', icon: faHandsHelping, description: 'Protect donor data and ensure secure operations for nonprofit organizations.' },
        { title: 'Pharmaceuticals', icon: faFlask, description: 'Protect intellectual property and sensitive research data with cybersecurity solutions tailored for pharmaceuticals and biotechnology.' },
        { title: 'Private Equity', icon: faChartLine, description: 'Secure sensitive investment data with our cybersecurity solutions tailored for private equity firms.' },
        { title: 'Real Estate', icon: faBuilding, description: 'Protect client information, transactions, and property management systems with cybersecurity solutions tailored for the real estate industry.' },
        { title: 'Retail', icon: faShoppingCart, description: 'Protect customer data and maintain PCI-DSS compliance with our specialized retail cybersecurity services.' },
        { title: 'SaaS', icon: faLaptopCode, description: 'Protect cloud-based applications and customer data with our advanced security solutions for SaaS companies.' },
        { title: 'Telecom', icon: faMobileAlt, description: 'Defend against network threats and protect customer communications with our telecom security solutions.' },
        { title: 'Transportation', icon: faTrain, description: 'Enhance the security of transportation networks and protect against emerging cyber threats.' },
        { title: 'Venture Capital', icon: faBuilding, description: 'Ensure the safety of investment and startup data with our comprehensive cybersecurity services for venture capital firms.' }
    ];

    const handleTileClick = () => {
        // Navigate to consultation when any tile is clicked
        navigate('/consultationRef');
    };

    return (
        <div className="service-industries-page">
            <div className="service-industries-hero">
                <h1>Service Industries</h1>
                <p>We offer specialized cybersecurity solutions tailored to meet the unique needs of the following industries.</p>
            </div>
            <div className="service-industries-grid">
                {industries.map((industry, index) => (
                    <div key={index} className="service-industries-card" onClick={handleTileClick}>
                        <FontAwesomeIcon icon={industry.icon} className="service-industries-icon" />
                        <h2 className="service-industries-title">{industry.title}</h2>
                        <p className="service-industries-description">{industry.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServiceIndustriesPage;

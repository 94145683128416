import React, { useEffect, useRef } from 'react';
import './GRCCompliancePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserShield, faBrain, faChartLine } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function GRCCompliancePage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="grc-compliance-page">
      <div className="grc-compliance-hero">
        <div className="grc-compliance-hero-content">
          <h1 className="grc-compliance-title">Achieve GRC Compliance</h1>
          <p className="grc-compliance-subtitle">Ensure your business meets governance, risk, and compliance requirements with PathEye's comprehensive solutions.</p>
          <button className="grc-compliance-cta-button" onClick={scrollToScheduleSection}>
            Get Started
          </button>
        </div>
        <div className="grc-compliance-down-arrow">▼</div> {/* Animated down arrow */}
      </div>

      <div className="grc-compliance-additional-sections">
        <section className="grc-compliance-section">
          <h2 className="grc-compliance-section-title">Why Choose PathEye for GRC Compliance?</h2>
          <div className="grc-compliance-section-content">
            <p>Choosing PathEye for your GRC compliance ensures your business adheres to all regulatory standards while minimizing risk.</p>
          </div>
        </section>
        <section className="grc-compliance-assessment-section">
          <h2 className="grc-compliance-section-title">
            <FontAwesomeIcon icon={faShieldAlt} className="grc-compliance-section-icon" /> Comprehensive Risk Assessment
          </h2>
          <div className="grc-compliance-section-content">
            <p>Identify and assess your organization’s unique governance, risk, and compliance challenges.</p>
            <ul>
              <li>Thorough assessments tailored to your industry.</li>
              <li>Identification of key compliance areas and potential impacts.</li>
              <li>Recommendations to ensure full compliance.</li>
            </ul>
          </div>
        </section>
        <section className="grc-compliance-planning-section">
          <h2 className="grc-compliance-section-title">
            <FontAwesomeIcon icon={faUserShield} className="grc-compliance-section-icon" /> Strategic Compliance Planning
          </h2>
          <div className="grc-compliance-section-content">
            <p>Develop a robust GRC strategy aligned with your business objectives and regulatory requirements.</p>
            <ul>
              <li>Customized compliance strategies that grow with your business.</li>
              <li>Alignment with industry-specific regulations.</li>
              <li>Integration with existing governance frameworks and future planning.</li>
            </ul>
          </div>
        </section>
        <section className="grc-compliance-implementation-section">
          <h2 className="grc-compliance-section-title">
            <FontAwesomeIcon icon={faBrain} className="grc-compliance-section-icon" /> Implementation & Execution
          </h2>
          <div className="grc-compliance-section-content">
            <p>Implement your GRC strategy effectively with expert guidance from PathEye.</p>
            <ul>
              <li>Hands-on support throughout the implementation process.</li>
              <li>Training and knowledge transfer to your internal teams.</li>
              <li>Continuous monitoring and adjustments as needed.</li>
            </ul>
          </div>
        </section>
        <section className="grc-compliance-optimization-section">
          <h2 className="grc-compliance-section-title">
            <FontAwesomeIcon icon={faChartLine} className="grc-compliance-section-icon" /> Continuous Optimization
          </h2>
          <div className="grc-compliance-section-content">
            <p>Ensure your GRC strategy evolves with changing regulations and business needs.</p>
            <ul>
              <li>Regular reviews and updates to your compliance strategy.</li>
              <li>Adaptation to new regulations and standards.</li>
              <li>Ongoing optimization to maintain full compliance.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default GRCCompliancePage;

import React, { useEffect } from 'react';
import './PrivacyPolicyPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';

function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-page-container">
      <div className="privacy-policy-page-content">
        <h2 className="privacy-policy-page-title">
          PathEye Privacy Policy <FontAwesomeIcon icon={faGlasses} className="privacy-policy-page-icon" />
        </h2>
        <p>
          PathEye is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website, PathEye.com, or use our services.
        </p>
        <h3 className="privacy-policy-page-subtitle">No Cookies or Tracking</h3>
        <p>
          We do not use cookies or any other tracking mechanisms on our website. Your browsing experience on PathEye.com is completely anonymous.
        </p>
        <h3 className="privacy-policy-page-subtitle">Contact Forms</h3>
        <p>
          If you choose to fill out any contact forms on our website, you agree to be contacted by PathEye. We will use the personal information you provide to respond to your inquiries and provide the services you have requested.
        </p>
        <h3 className="privacy-policy-page-subtitle">Use of Personal Information</h3>
        <p>
          By submitting your personal information, you agree to our privacy policy and consent to be contacted by PathEye. We will only use your personal information to:
        </p>
        <ul className="privacy-policy-page-list">
          <li>Administer your account.</li>
          <li>Provide the products and services you requested from us.</li>
        </ul>
        <h3 className="privacy-policy-page-subtitle">Disclosure of Personal Information</h3>
        <p>
          We do not disclose any personal information about you to our affiliates, subsidiaries, or to nonaffiliated third parties, except as permitted by law. There are circumstances where we may need to share your information, such as:
        </p>
        <ul className="privacy-policy-page-list">
          <li>Complying with a validly issued and enforceable subpoena or summons.</li>
          <li>Participating in actual or threatened legal proceedings or alternative dispute resolution proceedings initiated by or against us.</li>
        </ul>
        <p>
          In such cases, we will only disclose the information necessary to file, pursue, or defend against the legal action and will take reasonable precautions to ensure that the information does not become a matter of public record.
        </p>
        <h3 className="privacy-policy-page-subtitle">Marketing Communications</h3>
        <p>
          From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time by following the unsubscribe instructions provided in our emails or by contacting us directly at contact@patheye.com.
        </p>
        <h3 className="privacy-policy-page-subtitle">Contact Information</h3>
        <p>
          If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
        </p>
        <p>Email: contact@patheye.com</p>
        <h3 className="privacy-policy-page-subtitle">Changes to Our Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically.
        </p>
        <h3 className="privacy-policy-page-subtitle">Consent</h3>
        <p>
          By using our website and services, you agree to the terms of this Privacy Policy.
        </p>
        <h3 className="privacy-policy-page-subtitle">Effective Date</h3>
        <p>
          This Privacy Policy is effective as of 10/18/23.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;

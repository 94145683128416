import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Protecting Your Business from Phishing Schemes: Lessons from the $1M DoorDash Scam',
  description: 'A recent phishing scheme defrauded DoorDash drivers, revealing critical cybersecurity lessons for businesses.',
  keywords: 'phishing, DoorDash scam, cybersecurity, PathEye security solutions, multi-factor authentication, security awareness training',
  icon: faShieldAlt,
  date: '2024-11-07',
  author: 'PathEye Security',
};

const PostDoorDashPhishingScam = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => handleScrollToArticles(), 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => window.print();

  return (
    <div className="post-content" ref={contentRef}>
      <h1><FontAwesomeIcon icon={faShieldAlt} className="icon-left" /> {postMeta.title}</h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>What Happened: The Anatomy of the DoorDash Phishing Scam</h2>
      <p>
        A recent high-profile phishing scheme defrauded DoorDash drivers of nearly $1 million. David Smith, a Stamford resident, allegedly carried out this scheme, posing as a DoorDash representative and tricking drivers into giving him control of their accounts. This case not only shows how targeted phishing can wreak havoc on individuals but also highlights the risks faced by businesses reliant on secure, trusted systems.
      </p>
      <h2>Lessons Learned: Key Takeaways from the DoorDash Phishing Scam</h2>
      <p>Phishing tactics continue to grow more sophisticated. Smith’s scheme relied on trust, posing as a DoorDash support representative. Companies must educate employees and customers to question seemingly legitimate communications.</p>

      <h2>How PathEye Can Help Protect Your Business from Similar Attacks</h2>
      <ul>
        <li><strong><Link to="/dlp">Phishing Awareness and Training Programs</Link>:</strong> Our security awareness programs go beyond the basics to educate employees and users about advanced phishing tactics like spoofed phone numbers and cloned websites.</li>
        <li><strong><Link to="/grc-compliance">Multi-Factor Authentication (MFA) Solutions</Link>:</strong> To protect against credential-based attacks, PathEye can help implement robust MFA options, adding an extra layer of security.</li>
        <li><strong>Phishing Simulation and Testing:</strong> Our phishing simulations test employee responses to various tactics, helping to identify training needs and reinforcing a proactive approach to handling suspicious messages.</li>
        <li><strong>Threat Monitoring and Real-Time Alerts:</strong> PathEye’s monitoring system offers real-time alerts for unusual account activity, enabling your security team to act quickly.</li>
      </ul>

      <h2>Final Thoughts: Proactive Security Is the Best Defense</h2>
      <p>The DoorDash phishing scam shows how a well-crafted attack can lead to financial and reputational damage. By fostering an environment of awareness and vigilance, you can help prevent your organization from falling victim to similar schemes.</p>

      <p>For more details on the DoorDash scheme and how PathEye’s security solutions can protect your business, contact us today.</p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostDoorDashPhishingScam;

import React, { useEffect, useRef } from 'react';
import './StrategyDevelopmentPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserShield, faBrain, faChartLine } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function StrategyDevelopmentPage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="cyber-strategy-page">
      <div className="cyber-strategy-hero">
        <div className="cyber-strategy-hero-content">
          <h1 className="cyber-strategy-title">Build Your Cybersecurity Strategy</h1>
          <p className="cyber-strategy-subtitle">Piece together a resilient cybersecurity strategy with PathEye. Ensure your business is protected against evolving threats.</p>
          <button className="cyber-strategy-cta-button" onClick={scrollToScheduleSection}>
            Get Started
          </button>
        </div>
        <div className="cyber-strategy-down-arrow">▼</div> {/* Animated down arrow */}
      </div>

      <div className="cyber-strategy-additional-sections">
        <section className="cyber-strategy-section">
          <h2 className="cyber-strategy-section-title">Why Choose PathEye for Strategy Development?</h2>
          <div className="cyber-strategy-section-content">
            <p>Choosing the right partner to develop your cybersecurity strategy is critical. PathEye ensures your business is protected with a comprehensive and forward-thinking approach.</p>
          </div>
        </section>
        <section className="cyber-strategy-assessment-section">
          <h2 className="cyber-strategy-section-title">
            <FontAwesomeIcon icon={faShieldAlt} className="cyber-strategy-section-icon" /> Comprehensive Risk Assessment
          </h2>
          <div className="cyber-strategy-section-content">
            <p>Identify and assess your business’s unique vulnerabilities and potential threats.</p>
            <ul>
              <li>Thorough risk assessments tailored to your industry.</li>
              <li>Identification of critical assets and potential impacts.</li>
              <li>Recommendations to mitigate identified risks.</li>
            </ul>
          </div>
        </section>
        <section className="cyber-strategy-planning-section">
          <h2 className="cyber-strategy-section-title">
            <FontAwesomeIcon icon={faUserShield} className="cyber-strategy-section-icon" /> Strategic Planning
          </h2>
          <div className="cyber-strategy-section-content">
            <p>Develop a robust cybersecurity strategy aligned with your business objectives.</p>
            <ul>
              <li>Customized security strategies that grow with your business.</li>
              <li>Alignment with regulatory and compliance requirements.</li>
              <li>Integration with existing security measures and future planning.</li>
            </ul>
          </div>
        </section>
        <section className="cyber-strategy-implementation-section">
          <h2 className="cyber-strategy-section-title">
            <FontAwesomeIcon icon={faBrain} className="cyber-strategy-section-icon" /> Implementation & Execution
          </h2>
          <div className="cyber-strategy-section-content">
            <p>Execute your strategy effectively with our expert guidance.</p>
            <ul>
              <li>Hands-on support throughout the implementation process.</li>
              <li>Training and knowledge transfer to your internal teams.</li>
              <li>Continuous monitoring and adjustments as needed.</li>
            </ul>
          </div>
        </section>
        <section className="cyber-strategy-optimization-section">
          <h2 className="cyber-strategy-section-title">
            <FontAwesomeIcon icon={faChartLine} className="cyber-strategy-section-icon" /> Continuous Optimization
          </h2>
          <div className="cyber-strategy-section-content">
            <p>Ensure your cybersecurity strategy evolves with the changing threat landscape.</p>
            <ul>
              <li>Regular reviews and updates to your security strategy.</li>
              <li>Adaptation to new threats and technologies.</li>
              <li>Ongoing optimization to maintain peak performance.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default StrategyDevelopmentPage;

import React from 'react';
import './flyer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCloud, faCode, faDatabase, faQuestionCircle, faPhone, faEnvelope, faGlobe, faScissors, faHandHoldingUsd, faTools } from '@fortawesome/free-solid-svg-icons'; 

function Flyer() {
  return (
    <div className="flyer_flyer"> 
      {/* Page 1 */}
      <div className="flyer_flyer-page flyer_flyer-page-1">
        <div className="flyer_flyer-header">
          <h1 className="flyer_flyer-logo">PathEye</h1> 
          <h2 className="flyer_flyer-subtitle">Commercial Cybersecurity & Digital Consultancy</h2>
        </div>

        {/* Offer Section */}
        <div className="flyer_flyer-offer">
          <h2>Security Analysis and Risk Reduction starting @ $1000</h2>
          
        </div>

        {/* First Cutout Coupon Section */}
        <div className="flyer_flyer-coupon">
          <FontAwesomeIcon icon={faScissors} className="flyer_flyer-coupon-scissors" />
          <p className="flyer_flyer-coupon-text">
            Share your current IT/Security Vendor pricing and we will beat it by <span className="flyer_highlight"><strong>25%</strong></span>
          </p>
        </div>

        {/* Second Coupon Section - Insurance Cyber Security Attestation */}
        <div className="flyer_flyer-coupon">
          <FontAwesomeIcon icon={faScissors} className="flyer_flyer-coupon-scissors" />
          <p className="flyer_flyer-coupon-text">
            <strong>Insurance Cyber Security Attestation:</strong> Save on your cyber premiums, reduce risk and decrease your liability in case of a breach. <span className="flyer_highlight"><strong>50% off</strong></span> with this flyer.
          </p>
        </div>

        {/* Satisfaction Guarantee Section */}
        <div className="flyer_flyer-guarantee">
          <FontAwesomeIcon icon={faHandHoldingUsd} className="flyer_flyer-icon_large" />
          <span className="flyer_flyer-guarantee-text">100% Satisfaction Guarantee</span>
        </div>

        {/* Contact Info Section on Page 1 */}
        <div className="flyer_flyer-contact-info">
          <p>
            <FontAwesomeIcon icon={faPhone} className="flyer_flyer-icon" /> 216-220-9150 &nbsp;|&nbsp;
            <FontAwesomeIcon icon={faEnvelope} className="flyer_flyer-icon" /> engage@PathEye.com &nbsp;|&nbsp;
            <FontAwesomeIcon icon={faGlobe} className="flyer_flyer-icon" /> PathEye.com
          </p>
        </div>

        {/* QR Code Section */}
        <div className="flyer_flyer-qrcode">
          <img src={`${process.env.PUBLIC_URL}/QRCode.png`} alt="QR Code" className="flyer_flyer-qrcode-image" />
          <p>Scan for more information</p>
        </div>
      </div>

      {/* Page Break */}
      <div className="flyer_flyer-page-break"></div>

      {/* Page 2 */}
      <div className="flyer_flyer-page flyer_flyer-page-2">
        <div className="flyer_flyer-why-choose-us">
          <h2><FontAwesomeIcon icon={faQuestionCircle} className="flyer_flyer-icon" /> Why Choose PathEye?</h2>
          <p>At PathEye, we provide top-tier cybersecurity solutions tailored to your business needs...</p>

          <ul>
            <li><strong>Real Engineering:</strong> We use real engineering and reverse engineering, not just automation and scanners.</li>
            <li><strong>Trusted Confidentiality:</strong> Trusted by some of Cleveland's largest companies, we ensure strict confidentiality.</li>
            <li><strong>Personalized Protection:</strong> Customized detection strategy built to meet your unique needs.</li>
            <li><strong>Peace of Mind:</strong> 24/7/365 monitoring ensures you're covered every second of every day.</li>
            <li><strong>Maximize Investments:</strong> Optimize the performance of your existing security tools.</li>
          </ul>
        </div>

        <div className="flyer_flyer-service-list">
          <h2>Our Services</h2>
          <ul>
            <li><FontAwesomeIcon icon={faShieldAlt} /> Security Analysis & Risk Attestation</li>
            <li><FontAwesomeIcon icon={faCloud} /> Infrastructure & Cloud Security</li>
            <li><FontAwesomeIcon icon={faCode} /> Secure Development / App Assessment</li>
            <li><FontAwesomeIcon icon={faDatabase} /> Business Continuity & Data Loss Prevention (DLP)</li>
            <li><FontAwesomeIcon icon={faTools} /> Managed Services & Team Extension</li>
          </ul>
        </div>

        {/* Contact Section in Footer */}
        <div className="flyer_flyer-footer">
          <p>
            <FontAwesomeIcon icon={faPhone} className="flyer_flyer-icon" /> 216-220-9150 &nbsp;|&nbsp;
            <FontAwesomeIcon icon={faEnvelope} className="flyer_flyer-icon" /> engage@PathEye.com &nbsp;|&nbsp;
            <FontAwesomeIcon icon={faGlobe} className="flyer_flyer-icon" /> PathEye.com
          </p>
        </div>

        {/* Cleveland Skyline Translucent Footer */}
        <div className="flyer_flyer-skyline"></div>
      </div>
    </div>
  );
}

export default Flyer;

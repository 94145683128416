// File: src/posts/Post-TrueCostOfDataBreaches.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: "The True Cost of Data Breaches: Lessons from Geico and Travelers' $113 Million Fines",
  description: "An in-depth analysis of the financial and reputational impacts of data breaches, drawing lessons from the recent fines imposed on Geico and Travelers.",
  keywords: "data breaches, cybersecurity, Geico, Travelers, financial impact, PathEye",
  icon: faShieldAlt,
  date: '2024-12-01', // Set this to the current date when creating the post
  author: 'PathEye Security',
};

const PostTrueCostOfDataBreaches = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => setIsListening(false);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: postMeta.title,
        url: window.location.href,
      });
    } else {
      // Fallback for browsers that do not support the Web Share API
      const shareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(postMeta.title)}&url=${encodeURIComponent(window.location.href)}`;
      window.open(shareURL, '_blank');
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={postMeta.icon} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Post Image */}
      <img src="/images/true-cost-of-data-breaches.jpg" alt="Data Breach Illustration" className="post-image" />

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      {/* Article Content */}
      <h2>What Happened?</h2>
      <p>
        In 2020, both companies experienced data breaches that exposed the driver’s license numbers of approximately 120,000 New Yorkers. Regulators cited these breaches as preventable, attributing them to inadequate safeguards that left sensitive customer data vulnerable to exploitation.
      </p>
      
      <h2>Why Does This Matter?</h2>
      <p>
        Driver’s license numbers are more than just another piece of personal information—they serve as gateways for identity theft and fraud. Cybercriminals can leverage this data to commit tax fraud, open unauthorized accounts, and bypass identity verification processes. For companies entrusted with such sensitive information, the stakes are extraordinarily high.
      </p>
      <p>
        These breaches also reveal a critical issue: many organizations prioritize meeting regulatory standards over actively securing their systems. Compliance frameworks set minimum requirements but do not guarantee comprehensive protection against sophisticated threats.
      </p>
      
      <h2>Lessons for Businesses</h2>
      <ul>
        <li><strong>Compliance Isn’t Enough:</strong> Meeting regulatory requirements alone won’t necessarily prevent breaches—or the penalties that follow.</li>
        <li><strong>Proactive Risk Management:</strong> Organizations must adopt a forward-looking cybersecurity strategy, identifying vulnerabilities before attackers do. This includes robust encryption, endpoint protection, and continuous monitoring.</li>
        <li><strong>Vendor and Third-Party Risks:</strong> Third-party relationships often introduce vulnerabilities. Companies need strict security requirements for vendors and regular assessments to ensure compliance.</li>
        <li><strong>Incident Response Planning:</strong> While breaches cannot always be avoided, a well-prepared incident response plan can mitigate damage and reduce recovery times.</li>
        <li><strong>Public Trust is Fragile:</strong> Beyond financial penalties, breaches erode customer trust. Consumers expect their personal information to be secure; a breach can damage reputation and retention rates.</li>
      </ul>
      
      <h2>The PathEye Solution</h2>
      <p>
        At PathEye, we believe protecting sensitive data goes beyond compliance—it’s about building trust. Our proactive, human-engineered cybersecurity solutions address gaps that automated tools often miss, ensuring vulnerabilities are mitigated before they can be exploited.
      </p>
      <p>
        We specialize in:
      </p>
      <ul>
        <li><strong>Advanced Threat Detection and Response:</strong> Rapidly identify and neutralize threats to sensitive data.</li>
        <li><strong>Third-Party Risk Assessments:</strong> Enforce high-security standards across your vendor ecosystem.</li>
        <li><strong>Proactive Vulnerability Scanning:</strong> Uncover hidden vulnerabilities through penetration testing and deep analysis.</li>
        <li><strong>Incident Response Planning:</strong> Develop tailored playbooks and provide hands-on support during security incidents.</li>
      </ul>
      
      <h2>A Wake-Up Call for All Organizations</h2>
      <p>
        The fines levied against Geico and Travelers serve as a stark reminder that cybersecurity isn’t optional—it’s an essential component of modern business. Protecting sensitive information is about more than avoiding fines; it’s about maintaining the trust of the customers who rely on you.
      </p>
      <p>
        Let PathEye help you build a resilient security posture. Contact us today to learn how we can reduce your risk and secure your most valuable assets.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostTrueCostOfDataBreaches;

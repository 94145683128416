import React, { useEffect, useState, useRef } from 'react';
import './PenTestingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faSearch, faUserShield, faBrain, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function PenTestingPage() {
  const [logs, setLogs] = useState([]);
  const [accessGranted, setAccessGranted] = useState(false);
  const terminalRef = useRef(null);
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  useEffect(() => {
    const commands = [
      'Initializing terminal...',
      'Loading modules...',
      'User: root',
      'Password: ********',
      'Login successful.',
      'Starting Nmap scan...',
      'nmap -sS -A -T4 target_ip',
      'Nmap scan report for target_ip',
      'Host is up (0.032s latency).',
      'PORT    STATE SERVICE VERSION',
      '22/tcp  open  ssh     OpenSSH 7.6p1 Ubuntu 4ubuntu0.3 (Ubuntu Linux; protocol 2.0)',
      '80/tcp  open  http    Apache httpd 2.4.29 ((Ubuntu))',
      '443/tcp open  ssl/http Apache httpd 2.4.29',
      'Starting vulnerability assessment...',
      'nmap --script vuln target_ip',
      'Running script [vuln]...',
      'Completed script [vuln].',
      'Identifying services...',
      'Running Brute force attack...',
      'hydra -l admin -P passwords.txt target_ip http-get /admin',
      'Starting SQL Injection...',
      'sqlmap -u "http://target_ip/login" --dbs',
      'Bypassing firewall...',
      'Decrypting data...',
      'Accessing mainframe...',
      'ACCESS GRANTED'
    ];

    let commandIndex = 0;
    const intervalId = setInterval(() => {
      if (commandIndex < commands.length) {
        setLogs((prevLogs) => [...prevLogs, commands[commandIndex]]);
        commandIndex++;
      } else {
        clearInterval(intervalId);
        setAccessGranted(true);
      }
    }, 233);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [logs]);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="pentesting-terminal-page">
      <div className="terminal-setup">
        <div className={`terminal-monitor ${accessGranted ? 'dimmed' : ''}`}>
          <div className="terminal-screen" ref={terminalRef}>
            {logs.map((log, index) => (
              <p key={index} className="terminal-log">{log}</p>
            ))}
            {accessGranted && (
              <div className="reveal-content">
                <h2 className="secure-content-title">PathEye</h2>
                <p className="secure-content-text">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" />
                  Are your systems insecure?!?
                </p>
                <button className="pentesthero-button" onClick={scrollToScheduleSection}>
                  Let's check it out? 
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="terminal-base">
          <div className="floppy-drive"></div>
          <div className="lights">
            <div className="light red"></div>
            <div className="light green"></div>
            <div className="light yellow"></div>
          </div>
        </div>
      </div>
      <div className="additional-sections">
        <section className="why-pick-patheye-section">
          <h2 className="section-title">Why Choose PathEye Threat Services?</h2>
        </section>
        <section className="threat-hunting-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faSearch} className="section-icon" /> Threat Hunting</h2>
          <p>Reduce blind spots and counter evasive threats with hypothesis-based Threat Hunting.</p>
          <ul>
            <li>Find stealthy vulnerabilities that can’t be found with automated tools or scans.</li>
            <li>Identify misconfigurations and potential risks across your environments.</li>
            <li>Support ongoing detection based on threat hunting results and your unique configurations.</li>
            <li>Reduce risk and liability - Improve your security posture by implementing recommendations on your security infrastructure.</li>
          </ul>
        </section>
        <section className="our-team-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faUserShield} className="section-icon" /> Our Team</h2>
          <p>Expert analysts with advanced malware analysis and security investigation skills.</p>
          <ul>
            <li>Use threat intelligence, intuition, and experience to discover anomalies and develop patterns of threat activity over time to identify hidden threats.</li>
            <li>Provide detailed and actionable guidance on next steps to respond to a threat for seamless integration with incident response.</li>
          </ul>
        </section>
        <section className="comprehensive-reports-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faBrain} className="section-icon" /> Comprehensive Reports</h2>
          <p>Detailed insights and actionable recommendations to secure your infrastructure.</p>
          <ul>
            <li>In-depth vulnerability assessments and analysis.</li>
            <li>Actionable steps for remediation and mitigation.</li>
            <li>Continuous improvement through follow-up assessments.</li>
          </ul>
        </section>
        <section className="certification-section">
          <h2 className="section-title"><FontAwesomeIcon icon={faShieldAlt} className="section-icon" /> Certification & Attestation</h2>
          <p>Demonstrate your commitment to security with our certification.</p>
          <ul>
            <li>Receive a letter of attestation from our legal department upon passing.</li>
            <li>Use the certification to show compliance with best practices.</li>
            <li>Lower your risk and reduce liability.</li>
          </ul>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default PenTestingPage;

import React, { useEffect, useRef } from 'react';
import './ApplicationAssessmentPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShieldAlt, faCode, faBug } from '@fortawesome/free-solid-svg-icons';
import ScheduleConsultationSection from './ScheduleConsultationSection';

function ApplicationAssessmentPage() {
  const scheduleSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToScheduleSection = () => {
    scheduleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="application-assessment-page">
      <div className="application-assessment-hero">
        <div className="application-assessment-hero-content">
          <h1 className="application-assessment-title">Enhance Your Application Security</h1>
          <p className="application-assessment-subtitle">Identify and mitigate vulnerabilities in your applications before they become entry points for cyberattacks.</p>
          <button className="application-assessment-cta-button" onClick={scrollToScheduleSection}>
            Get Started
          </button>
        </div>
        <div className="application-assessment-down-arrow">▼</div>
      </div>

      <div className="application-assessment-additional-sections">
        <section className="application-assessment-section">
          <h2 className="application-assessment-section-title">Why Choose PathEye for Application Assessment?</h2>
          <div className="application-assessment-section-content">
            <p>At PathEye, we offer comprehensive application security assessments designed to uncover hidden vulnerabilities within your software. Our approach combines static and dynamic analysis, secure coding practices, and real-time threat detection to ensure your applications are resilient against emerging cyber threats.</p>
          </div>
        </section>
        <section className="application-assessment-assessment-section">
          <h2 className="application-assessment-section-title">
            <FontAwesomeIcon icon={faSearch} className="application-assessment-section-icon" /> Thorough Vulnerability Scanning
          </h2>
          <div className="application-assessment-section-content">
            <p>We perform in-depth assessments of your applications to identify vulnerabilities across all layers, from code to runtime behavior.</p>
            <ul>
              <li>Comprehensive static and dynamic analysis.</li>
              <li>Automated code scanning for secure coding practices.</li>
              <li>Real-time monitoring for live threats.</li>
            </ul>
          </div>
        </section>
        <section className="application-assessment-remediation-section">
          <h2 className="application-assessment-section-title">
            <FontAwesomeIcon icon={faShieldAlt} className="application-assessment-section-icon" /> Effective Remediation Strategies
          </h2>
          <div className="application-assessment-section-content">
            <p>Beyond identifying vulnerabilities, we provide actionable recommendations to fortify your applications against future threats.</p>
            <ul>
              <li>Detailed remediation guidance tailored to your development environment.</li>
              <li>Best practices for secure coding and software deployment.</li>
              <li>Continuous support to ensure successful implementation.</li>
            </ul>
          </div>
        </section>
        <section className="application-assessment-optimization-section">
          <h2 className="application-assessment-section-title">
            <FontAwesomeIcon icon={faCode} className="application-assessment-section-icon" /> Ongoing Optimization
          </h2>
          <div className="application-assessment-section-content">
            <p>Our services don't stop at the initial assessment. We provide continuous optimization to keep your applications secure as they evolve.</p>
            <ul>
              <li>Regular vulnerability assessments to stay ahead of threats.</li>
              <li>Continuous integration and testing support.</li>
              <li>Adaptation to new development practices and technologies.</li>
            </ul>
          </div>
        </section>
        <div ref={scheduleSectionRef}>
          <ScheduleConsultationSection />
        </div>
      </div>
    </div>
  );
}

export default ApplicationAssessmentPage;

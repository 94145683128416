// articlesData.js
import { faBug, faShieldAlt, faEnvelopeOpenText, faSatellite, faUserShield, faLock, faChartLine, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const newsArticles = [
  {
    title: 'Authentication Bypass Discovered in Microsoft Entra ID',
    description: 'A critical authentication bypass vulnerability has been discovered in Microsoft Entra ID, which allows unauthorized users to access systems without proper verification.',
    icon: faExclamationTriangle,
    link: 'https://www.securitymagazine.com/articles/100950-authentication-bypass-discovered-in-microsoft-entra-id'
  },
  {
    title: '3 Billion People\'s Personal Data Leaked to the Dark Web',
    description: 'Personal data of 3 billion people, including Social Security numbers, has been leaked to the dark web, posing severe risks to individuals and organizations alike.',
    icon: faExclamationTriangle,
    link: 'https://www.techradar.com/pro/3-billion-people-s-personal-data-leaked-to-the-dark-web-including-social-security-numbers'
  },
  {
    title: 'Attackers Have Been Leveraging Microsoft Zero-Day for 18 Months',
    description: 'Security researchers warn that attackers have been exploiting a critical vulnerability in Microsoft Office products for over a year and a half. This zero-day vulnerability allowed attackers to bypass security measures and gain access to systems.',
    icon: faBug,
    link: 'https://www.darkreading.com/application-security/attackers-have-been-leveraging-microsoft-zero-day-for-18-months'
  },
  {
    title: 'Malicious NuGet Campaign Uses Homoglyphs and IL Weaving to Fool Devs',
    description: 'A recent attack campaign targeted software developers by using malicious NuGet packages. These packages contained code that appeared legitimate but actually stole developer credentials.',
    icon: faShieldAlt,
    link: 'https://arstechnica.com/information-technology/2023/01/more-malicious-packages-posted-to-online-repository-this-time-its-pypi/'
  },
  {
    title: 'China\'s APT41 Crew Adds Stealthy Malware to its Toolbox',
    description: 'A sophisticated hacking group linked to China has been deploying a new type of malware that uses Google Drive to hide its communications and evade detection.',
    icon: faEnvelopeOpenText,
    link: 'https://cyware.com/resources/research-and-analysis'
  },
  {
    title: 'Exim Vulnerability Affecting 1.5M Servers Lets Attackers Attach Malicious Files',
    description: 'A critical vulnerability has been discovered in Exim, a widely used email server software. This vulnerability allows attackers to send emails containing malicious attachments that can compromise systems.',
    icon: faSatellite,
    link: 'https://www.bleepingcomputer.com/news/security/millions-of-exim-mail-servers-exposed-to-zero-day-rce-attacks/'
  },
  {
    title: 'Japanese Space Agency Spots Unspecified Zero-Day Attacks',
    description: 'The Japan Aerospace Exploration Agency (JAXA) recently discovered that its systems were targeted with zero-day attacks. These attacks exploited previously unknown vulnerabilities to gain access to JAXA\'s network.',
    icon: faUserShield,
    link: 'https://www.reuters.com/technology/cybersecurity/japan-space-agency-hit-with-cyberattack-this-summer-media-2023-11-29/'
  },
  {
    title: 'CISA Takedown of Ivanti Systems Is a Wake-up Call',
    description: 'The US Cybersecurity and Infrastructure Security Agency (CISA) recently ordered the takedown of a popular IT management software product due to a critical vulnerability. This incident highlights the importance of applying security patches promptly.',
    icon: faLock,
    link: 'https://www.darkreading.com/vulnerabilities-threats/ivanti-security-teams-scrambling-2-vulns'
  },
  {
    title: 'Lightning Storm: Akira Ransomware Encrypts Data in Just 2 Hours',
    description: 'A new ransomware variant called Akira has emerged and is particularly destructive. This ransomware can encrypt a victim\'s data in as little as two hours, making it difficult to recover files without backups.',
    icon: faChartLine,
    link: 'https://www.darkreading.com/threat-intelligence/ransomware-reaches-new-heights'
  },
  {
    title: 'Settlement Class Action Lawsuit: Dental Great Expressions Data Breach',
    description: 'A class action lawsuit settlement has been reached after a data breach impacted millions of patients of Great Expressions Dental Centers, exposing personal health information.',
    icon: faExclamationTriangle,
    link: 'https://www.the-sun.com/money/12245523/settlement-class-action-lawsuit-dental-great-expressions-data-breach/'
  },
  {
    title: 'Best Practices for Event Logging in Threat Detection',
    description: 'Learn the best practices for event logging to enhance threat detection, providing a proactive approach to cybersecurity through robust data monitoring.',
    icon: faShieldAlt,
    link: 'https://cybersecuritynews.com/best-practices-for-event-logging-threat-detection/'
  },
  {
    title: 'Chinese Hackers Exploit Zero-Day in Cisco Products',
    description: 'Chinese hackers have been exploiting a zero-day vulnerability in Cisco products, allowing them to launch sophisticated attacks against targeted networks.',
    icon: faBug,
    link: 'https://thehackernews.com/2024/08/chinese-hackers-exploit-zero-day-cisco.html'
  }
];

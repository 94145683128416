import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = ({ onScrollToConsultation, onScrollToServices, onScrollToArticles }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.nav-links') && !event.target.closest('.hamburger-menu')) {
        closeMenu();
      }
    };

    if (menuOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuOpen]);

  const handleNavigation = (path, scrollCallback) => {
    navigate(path);
    closeMenu();
    if (scrollCallback) {
      setTimeout(() => {
        scrollCallback();
      }, 100);
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <Link to="/" className="logo" onClick={closeMenu}>
          PathEye
        </Link>
        <div className="contact-info">
          <div className="info-item">
            <i className="phone-icon"></i>
            <span>(216) 220-9150</span>
          </div>
          <div className="info-item">
            <i className="mail-icon"></i>
            <span>engage@PathEye.com</span>
          </div>
        </div>
        <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <Link to="/about" onClick={closeMenu}>About</Link>
          <Link to="/" onClick={() => handleNavigation('/', onScrollToArticles)}>News</Link> 
          <Link to="/Service-Industries" onClick={closeMenu}>Industries</Link>
          <Link to="/" onClick={() => handleNavigation('/', onScrollToServices)}>Services</Link>
    
          <Link to="/capabilities" onClick={closeMenu}>Capabilities</Link>
          <Link to="/login" className="client-login" onClick={closeMenu}>
            Login <span>&rarr;</span>
          </Link>
        </nav>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingShield, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'; // Private equity-related icon
import './Posts.css';

export const postMeta = {
  title: 'Centralizing Cybersecurity for Private Equity: PathEye’s Approach',
  description: 'Learn how PathEye helps private equity firms standardize and strengthen cybersecurity across their portfolio companies, from due diligence to post-acquisition operations.',
  keywords: 'private equity cybersecurity, portfolio company cyber risks, centralized cybersecurity, PathEye solutions, cyber risk management',
  icon: faBuildingShield, // Private equity-related icon
  date: '2024-09-16',
  author: 'PathEye Security',
};

const PostPrivateEquityCybersecurity = ({ handleScrollToArticles, handleScrollToConsultation }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125); // Default text size
  const [isListening, setIsListening] = useState(false); // Listen button state
  const [isMobile, setIsMobile] = useState(false); // Track if the user is on a mobile device
  const contentRef = useRef(null); // Reference for all content starting from the introduction

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    // Detect if the screen width is less than or equal to 768px (mobile devices)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel(); // Stop speech
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent; // Get all the text from the introduction onwards
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);

      speech.onend = () => {
        setIsListening(false); // Reset button state when speech ends
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>{postMeta.title}</h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      {/* Features below the author */}
      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Growing Threat Landscape for Private Equity Firms</h2>
      <p>
        Private equity groups face unique cybersecurity challenges. With multiple portfolio companies operating in different sectors, each acquisition brings new vulnerabilities, from outdated IT infrastructure to inadequate cyber policies. These vulnerabilities not only put individual companies at risk but could also expose the entire portfolio to systemic threats, compromising the reputation and financial health of the PE firm.
      </p>

      <h2>PathEye’s Tailored Cybersecurity Solutions</h2>
      <p>
        PathEye offers private equity firms a strategic approach to managing cybersecurity, integrating protection from the due diligence phase through post-acquisition operations. Here’s how we help:
      </p>
      <ul>
        <li><strong>Portfolio-Wide Cybersecurity Standardization:</strong> We implement standardized cybersecurity frameworks across all portfolio companies, ensuring consistent policies that protect against the most common threats while allowing for industry-specific adjustments.</li>
        <li><strong>Risk Assessments During Due Diligence:</strong> PathEye conducts in-depth cybersecurity assessments of potential acquisitions, identifying vulnerabilities that could impact valuation and introducing remediation plans to address them.</li>
        <li><strong>Unified Cybersecurity Policies and Procedures:</strong> PathEye helps private equity firms create centralized <span className="link" onClick={handleScrollToConsultation}>cybersecurity policies</span> that govern all portfolio companies.</li>
        <li><strong>Vendor Risk Management Across Properties:</strong> With multiple vendors servicing various portfolio companies, third-party risk becomes a significant concern. PathEye helps you monitor and manage the cybersecurity posture of all vendors.</li>
        <li><strong>Centralized Threat Detection and Response:</strong> Our <span className="link" onClick={handleScrollToConsultation}>24/7 monitoring</span> and threat detection across all portfolio companies allow for quick response and mitigation.</li>
        <li><strong>Training and Employee Awareness Programs:</strong> PathEye offers tailored security awareness programs for each business, helping employees recognize threats like phishing attacks and insider risks.</li>
      </ul>

      <h2>How PathEye Can Help You Save on Cyber Insurance</h2>
      <p>
        By implementing standardized cybersecurity policies across your portfolio, PathEye helps private equity firms reduce insurance premiums and lower liability in case of a breach. Our comprehensive coverage also helps mitigate risks that could lead to costly penalties and settlements, preserving the financial health of the entire portfolio.
      </p>

      <p>
        With PathEye, private equity groups can proactively manage cybersecurity risks across their properties, ensuring the protection of their investments and their reputation. Don’t let cybersecurity gaps threaten your portfolio’s future. <span className="link" onClick={handleScrollToConsultation}>Contact PathEye today</span> to safeguard your investments and stay ahead of the ever-evolving threat landscape.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostPrivateEquityCybersecurity;

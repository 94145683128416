import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, faShareAlt, faPrint, faFont, faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons';
import './Posts.css';

export const postMeta = {
  title: 'Massive Git Config Breach Exposes 15,000 Credentials: A Wake-Up Call for Developers',
  description: 'Learn about the recent Git config breach exposing 15,000 credentials and how to secure your development environment against similar threats.',
  keywords: 'Git config breach, EMERALDWHALE, developer security, PathEye cybersecurity, secure development practices',
  icon: faCodeBranch,
  date: '2024-11-01',
  author: 'PathEye Security',
};

const PostGitConfigBreach = ({ handleScrollToArticles }) => {
  const navigate = useNavigate();
  const [textSize, setTextSize] = useState(1.125);
  const [isListening, setIsListening] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      handleScrollToArticles();
    }, 300);
  };

  const handleTextSizeChange = (size) => {
    document.documentElement.style.setProperty('--article-font-size', `${size}rem`);
    setTextSize(size);
  };

  const handleListenToggle = () => {
    if (isListening) {
      window.speechSynthesis.cancel();
      setIsListening(false);
    } else {
      const fullText = contentRef.current.textContent;
      const speech = new SpeechSynthesisUtterance(fullText);
      window.speechSynthesis.speak(speech);
      setIsListening(true);
      speech.onend = () => {
        setIsListening(false);
      };
    }
  };

  const handleShare = () => {
    navigator.share({
      title: postMeta.title,
      url: window.location.href,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="post-content" ref={contentRef}>
      <h1>
        <FontAwesomeIcon icon={faCodeBranch} className="icon-left" /> {postMeta.title}
      </h1>
      <div className="date-posted-container">
        <p className="date-posted">Posted on {postMeta.date} by {postMeta.author}</p>
      </div>

      <div className="post-features">
        {!isMobile && (
          <button className="transparent-button" onClick={handleListenToggle}>
            <FontAwesomeIcon icon={isListening ? faStop : faVolumeUp} /> {isListening ? 'Stop' : 'Listen'}
          </button>
        )}
        <button className="transparent-button" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareAlt} /> Share
        </button>
        <button className="transparent-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </button>
        <div className="text-size">
          <FontAwesomeIcon icon={faFont} />
          <button className="transparent-button" onClick={() => handleTextSizeChange(1)}>A-</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.125)}>A</button>
          <button className="transparent-button" onClick={() => handleTextSizeChange(1.5)}>A+</button>
        </div>
      </div>

      <h2>The Breach Details</h2>
      <p>
        In a significant cybersecurity incident, researchers have uncovered a large-scale campaign targeting exposed Git configuration files, leading to the compromise of over 15,000 credentials and the unauthorized cloning of more than 10,000 private repositories. This breach, attributed to the threat actor group dubbed EMERALDWHALE, highlights critical vulnerabilities in software development practices and underscores the urgent need for robust security measures.
      </p>

      <h2>Implications for Developers and Organizations</h2>
      <p>
        This breach serves as a stark reminder of the critical importance of securing configuration files and other sensitive data within software development environments. Exposed Git configuration files can provide attackers with direct access to proprietary codebases, intellectual property, and confidential information, posing significant risks to both individual developers and organizations.
      </p>

      <h2>Preventative Measures</h2>
      <p>To mitigate the risk of such breaches, developers and organizations should implement the following best practices:</p>
      <ul>
        <li><strong>Secure Configuration Files:</strong> Ensure that Git configuration files and other sensitive documents are not publicly accessible. Use access controls and permissions to restrict unauthorized access.</li>
        <li><strong>Regular Audits:</strong> Conduct periodic security audits to identify and remediate exposed files and credentials. Automated tools can assist in scanning repositories for sensitive information.</li>
        <li><strong>Credential Management:</strong> Avoid hardcoding credentials into configuration files. Instead, use environment variables or dedicated secret management tools to handle sensitive information securely.</li>
        <li><strong>Monitoring and Alerts:</strong> Implement monitoring solutions to detect unauthorized access or unusual activities within repositories and development environments.</li>
      </ul>

      <h2>PathEye's Commitment to Secure Development Practices</h2>
      <p>
        At PathEye, we recognize the evolving threats facing the software development community. Our tailored cybersecurity solutions are designed to safeguard your development environments and protect your valuable assets. We offer:
      </p>
      <ul>
        <li><strong><Link to="/dlp">Real-Time Data Encryption</Link>:</strong> Protect sensitive data in transit and at rest, ensuring that even if accessed, the information remains secure.</li>
        <li><strong><Link to="/grc-compliance">Insider Threat Monitoring</Link>:</strong> Detect and respond to unauthorized activities within your organization, preventing potential breaches from within.</li>
        <li><strong><Link to="/infrastructure-audit">Comprehensive Security Assessments</Link>:</strong> Conduct thorough evaluations of your development practices to identify vulnerabilities and implement effective security measures.</li>
      </ul>

      <h2>A Necessary Call to Action</h2>
      <p>
        The EMERALDWHALE breach underscores the necessity for vigilant security practices in software development. By proactively securing your development environments, you can protect your organization from similar threats.
      </p>
      <p>
        For more detailed information on this breach, refer to the <a href="https://thehackernews.com/2024/11/massive-git-config-breach-exposes-15000.html" target="_blank" rel="noopener noreferrer">original report by The Hacker News</a>.
      </p>

      <div className="button-group">
        <button className="transparent-button" onClick={handleClose}>
          Close and Return to Articles <span>&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default PostGitConfigBreach;

import React from 'react';
import './CyberSecuritySection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faUsers, faBuilding } from '@fortawesome/free-solid-svg-icons'; // Updated icons

function CyberSecuritySection({ onScrollToServices }) {
  return (
    <section className="cybersecurity-section">
      <div className="cybersecurity-container">
        <div className="cybersecurity-left">
          <h1>Cybersecurity: Building a Fortress for Your Digital World</h1>
          <p>
            In an era where digital threats are omnipresent, true security demands more than just a reactive approach. 
          </p>
          <p>
            By combining the latest in security applications, 24/7 active monitoring, and continuous threat hunting, PathEye ensures that your defenses are not just strong—they are resilient. We believe in proactive defense, where every layer of security works in unison to protect your digital assets from sophisticated cyber threats.
          </p>
          <p>
            At PathEye, cybersecurity isn’t just a service; it’s a partnership. We work alongside your team to build a secure environment that not only protects but empowers your business to thrive in the digital age.
          </p>
        </div>
        <div className="cybersecurity-right">
          <div className="cybersecurity-section-item">
          <FontAwesomeIcon icon={faBan} size="3x" className="cybersecurity-icon" /> {/* Updated to faBan */}
            <h3>ZERO TRUST</h3>
            <p>
              Trust no one, verify everything. PathEye’s Zero Trust architecture enforces strict identity verification, data segmentation, and access controls at every level, ensuring that no one—internal or external—accesses your systems without proper authentication.
            </p>
            
          </div>
          <div className="cybersecurity-section-item">
          <FontAwesomeIcon icon={faUsers} size="3x" className="cybersecurity-icon" /> {/* Updated to faUsers */}
            <h3>TOP TALENT</h3>
            <p>
              Our cybersecurity experts are not just engineers; they are innovators. With deep expertise in modern security applications and a commitment to continuous learning, our team is equipped to handle the most advanced cyber threats, keeping your organization secure.
            </p>
            
          </div>
          <div className="cybersecurity-section-item">
          <FontAwesomeIcon icon={faBuilding} size="3x" className="cybersecurity-icon" /> {/* Updated to faBuilding */}
            <h3>CYBER LAB R&D</h3>
            <p>
              Staying ahead of cyber threats requires constant innovation. PathEye’s Cyber Lab is dedicated to advanced R&D, focusing on threat hunting, vulnerability assessments, and developing embedded security solutions that fortify your defenses against even the most sophisticated attacks.
            </p>
           
          </div>
        </div>
      </div>
    </section>
  );
}

export default CyberSecuritySection;
